import React, { SyntheticEvent } from 'react';
import { FieldErrors } from 'react-hook-form';
/** COMPONENTS */
import {
  CardContainer,
  CardContent,
  CardHeader,
  CardFooter,
  CardHeaderColors,
  Input,
  Button,
  ButtonColors,
  ButtonSizes,
} from 'components/shared';
import { Email, Lock } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';
/** UI */
import LoginFormUI from './login-form-ui';

const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

type IProps = {
  register: any;
  isFormValid: boolean;
  onSubmit: (event: SyntheticEvent) => void;
  errors: FieldErrors;
};

const LoginForm = ({ register, isFormValid, onSubmit, errors }: IProps): JSX.Element => {
  const classes = LoginFormUI();

  return (
    <CardContainer>
      <form className={classes.form} onSubmit={onSubmit}>
        <CardHeader color={CardHeaderColors.primary} className={classes.cardHeader}>
          <h4 className={classes.cardHeaderText}>Log In</h4>
        </CardHeader>
        <CardContent>
          <Input
            labelText='Email'
            id='email'
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors.email}
            errorText='Please enter a valid email!'
            inputProps={{
              autoComplete: 'off',
              ...register('email', {
                required: true,
                pattern: emailRegex,
              }),
              name: 'email',
              type: 'email',
              endAdornment: (
                <InputAdornment position='end'>
                  <Email className={classes.inputIconsColor} />
                </InputAdornment>
              ),
            }}
          />
          <Input
            labelText='Password'
            id='pass'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              ...register('password', {
                required: true,
                minLength: 4,
              }),
              type: 'password',
              name: 'password',
              endAdornment: (
                <InputAdornment position='end'>
                  <Lock className={classes.inputIconsColor} />
                </InputAdornment>
              ),
              autoComplete: 'off',
            }}
          />
        </CardContent>
        <CardFooter className={classes.cardFooter}>
          <Button disabled={!isFormValid} color={ButtonColors.primary} size={ButtonSizes.large}>
            Login
          </Button>
        </CardFooter>
      </form>
    </CardContainer>
  );
};

export default LoginForm;
