import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  cardFooter: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    padding: '0.9375rem 1.875rem',
  },
});
