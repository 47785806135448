import { makeStyles, Theme } from '@material-ui/core/styles';

export interface StyleProps {
  styles?: string;
  isFocused?: boolean;
  isDisabled?: boolean;
  dropdownWidth?: string;
  isLarge?: boolean;
  dropdownPosition?: 'left' | 'right';
}

type makeStylesProps = StyleProps | Record<any, any>;

export default makeStyles<Theme, makeStylesProps>(() => ({
  tableSearchWrapper: {
    display: 'flex',
  },
  selectWrapper: {
    width: '65px',
    flex: '0 0 auto',
    position: 'relative',
  },
  selectDropdownIcon: {
    position: 'absolute',
    top: '50%',
    right: '6px',
    fontSize: '18px',
    transform: 'translateY(-50%)',
    color: ({ isDisabled }) => (isDisabled ? 'var(--shade-300)' : 'var(--white)'),
  },
  selectDropdownTriggerValue: {
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '1',
    letterSpacing: '0.01em',
    color: ({ isDisabled }) => (isDisabled ? 'var(--shade-300)' : 'var(--white)'),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  selectDropdownTrigger: {
    background: 'var(--shade-500)',
    border: ({ isFocused }) => (isFocused ? '1px solid var(--primary) !important' : '1px solid transparent'),
    borderRadius: '0 6px 6px 0',
    padding: '7px 24px 7px 12px',
    cursor: 'pointer',
    transition: '0.12s ease',
    '&:hover': { border: '1px solid var(--shade-300-85)' },
    '&:focus': { border: '1px solid var(--primary)', outline: 'none' },
    pointerEvents: ({ isDisabled }) => (isDisabled ? 'none' : 'auto'),
  },
  selectDropdownTriggerActive: {
    border: '1px solid var(--primary) !important',
  },
  isMono: {
    width: 'auto',
    '& $selectDropdownTrigger:hover': {
      borderColor: 'transparent',
    },
    '& $selectDropdownTrigger': {
      padding: '7px 12px',
    },
  },
  selectDropdownWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 'calc(100% + 8px)',
    overflow: 'hidden',
    background: 'var(--shade-700)',
    border: '1px solid var(--shade-500-85)',
    boxShadow: '24px 32px 72px var(--black-18)',
    borderRadius: '6px',
    zIndex: 1,
    width: ({ dropdownWidth }) => (dropdownWidth ? dropdownWidth : '100%'),
    maxHeight: ({ isLarge }) => (isLarge ? '360px' : '300px'),
    left: ({ dropdownPosition }) => (dropdownPosition === 'left' ? '0' : 'auto'),
    right: ({ dropdownPosition }) => (dropdownPosition === 'right' ? '0' : 'auto'),
  },
  selectDropdownContentWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    flex: 1,
    maxWidth: '100%',
    overflow: 'auto',
    marginTop: '12px',
    padding: '0 12px 10px',
    '&::-webkit-scrollbar': { width: '3px', borderRadius: '12px' },
    '&::-webkit-scrollbar-track': { background: 'transparent' },
    '&::-webkit-scrollbar-thumb': {
      background: 'transparent',
      borderRadius: '20px',
      transition: 'background 0.12s ease',
    },
    '&:hover': { scrollbarWidth: 'thin', scrollbarColor: 'var(--shade-300-85) transparent' },
    '&:hover::-webkit-scrollbar': { width: '3px', borderRadius: '2px' },
    '&:hover::-webkit-scrollbar-thumb': { background: 'var(--shade-300-85)' },
  },
  selectOption: {
    width: '100%',
    flexBasis: '100%',
    fontFamily: 'Heebo',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    color: '#ababba',
    padding: '3px 8px',
    borderRadius: '6px',
    transition: '0.12s ease',
    cursor: 'pointer',
    marginBottom: '4px',
    '&:hover': {
      background: 'var(--primary)',
      color: 'var(--shade-900)',
    },
  },
  selectOptionSelected: {
    background: 'var(--primary)',
    color: 'var(--shade-900)',
  },
  tableSearchInput: {
    display: 'block',
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    padding: '5.5px 12px',
    letterSpacing: '0.01em',
    maxWidth: '100%',
    background: 'var(--shade-700-85)',
    borderRadius: '6px 0 0 6px ',
    border: '1px solid transparent',
    color: 'var(--white)',
    minWidth: '180px',
    flex: '0 0 auto',
    transition: '0.12s ease',
    '&:hover': {
      border: '1px solid var(--shade-300)',
    },
    '&:focus': {
      border: '1px solid var(--primary)',
      outline: 'none',
    },
  },
}));
