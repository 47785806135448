import React, { ReactNode } from 'react';
/** UI */
import TableUI from './../../table-ui';

interface IActionsWrapper {
  children: ReactNode;
}

const ActionsWrapper = ({ children }: IActionsWrapper): JSX.Element => {
  const classes = TableUI({
    actionsAlignment: React.Children.toArray(children).length > 1 ? 'flex-end' : 'center',
  });

  return <div className={classes.actionButtonsWrapper}>{children}</div>;
};

export default ActionsWrapper;
