import React, { useState, useEffect, useCallback, ChangeEvent, FormEvent } from 'react';
/** Router */
import { useHistory } from 'react-router-dom';
/** Redux */
import {
  getUser,
  updateUser,
  setUser,
  setUserStories,
  getUserRoles,
  deleteUser,
  deleteUserStory,
  getUserMeta,
} from 'redux/features/users/usersSlice';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
/** Interfaces */
import { IRole, IUser } from 'interfaces/user';
import { IUserFormErrors } from 'interfaces/userForm';
/** Components */
import { DetailsNavWrapper, GridContainer, GridItem } from 'components/shared';
import UserInfo from './user-info/user-info';
import UserDetailsForm from './user-details-form/user-details-form';
import { Box, Tab } from '@material-ui/core';
import { QuaternaryLinkButton } from '../buttons';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import UserMenu from './user-menu/user-menu';
import MessageModal from 'components/message-modal/message-modal';
import UserStories from './user-stories/user-stories';
/** Constants */
import { USER_SETTINGS, USER_DETAILS_TABS, USER_STORIES, USER_WORKSPACES } from './constants/tabs';
import { EMAIL_REGEX, USERNAME_REGEX } from '../../constants/regex';
/** UI */
import UserDetailsUI from './user-details-ui';
import UserWorkspaces from './user-workspaces/user-workspaces';
import AnalyticsCard from '../shared/analytics-card/analytics-card';

const UserDetails = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.users.user);
  const { userStoriesCount, workspacesCount } = useSelector((state: RootState) => state.users.userMeta);
  const userRoles = useSelector((state: RootState) => state.users.roles);
  const [tabValue, setTabValue] = useState(USER_SETTINGS);
  const classes = UserDetailsUI();
  const [userForm, setUserForm] = useState<IUser | null>(null);
  const [userFormErrors, setUserFormErrors] = useState<IUserFormErrors>({ username: '', email: '' });
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [areStoriesFetched, setAreStoriesFetched] = useState(false);
  const [areWorkspacesFetched, setAreWorkspacesFetched] = useState(false);

  const onStoriesFetch = useCallback(() => {
    setAreStoriesFetched(true);
  }, []);
  const onWorkspacesFetch = useCallback(() => {
    setAreWorkspacesFetched(true);
  }, []);

  const handleTabChange = useCallback((event: any, newValue: string) => {
    setTabValue(newValue);
  }, []);

  const onUserFormInit = useCallback((user: IUser) => {
    setUserForm(user);
  }, []);

  const onUpdateUser = useCallback(
    (manuallyMutatedFields?: any) => {
      if (user && userForm) {
        const newUserData = {
          _id: userForm._id,
          username: userForm.username,
          email: userForm.email,
          role: userForm.role._id,
          paid: userForm.paid,
          privacyPolicy: userForm.privacyPolicy,
          instagramExportAllowed: userForm.instagramExportAllowed,
          blocked: userForm.blocked,
          ...manuallyMutatedFields,
        };
        dispatch(updateUser(user._id, newUserData));
      }
    },
    [dispatch, user, userForm],
  );

  const onUpdateUserSettings = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onUpdateUser();
    },
    [onUpdateUser],
  );

  const validateForm = useCallback(() => {
    if (userForm) {
      const isUsernamePassedTest = USERNAME_REGEX.test(userForm.username);
      const isEmailPassedTest = EMAIL_REGEX.test(userForm.email);
      setUserFormErrors({
        username:
          userForm.username.length === 0
            ? 'Field cannot be empty!'
            : isUsernamePassedTest
            ? ''
            : `Username can contain only letters, numbers and '.' '-' '_'`,
        email: isEmailPassedTest ? '' : 'Please enter a valid email!',
      });
    }
  }, [userForm]);

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (userForm) {
        setUserForm({
          ...userForm,
          [event.target.name as string]: event.target.value as string,
        });
      }
    },
    [userForm],
  );

  const onCheckBoxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (userForm) {
        setUserForm({
          ...userForm,
          [event.target.name as string]: !userForm[event.target.name],
        });
      }
    },
    [userForm],
  );

  const onRoleSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (userForm) {
        const role = userRoles.find((role) => role.name === event.target.value) as IRole;

        setUserForm({
          ...userForm,
          role: role,
        });
      }
    },
    [userRoles, userForm],
  );

  const onBlockModalToggle = useCallback(() => {
    setIsBlockModalOpen(!isBlockModalOpen);
  }, [isBlockModalOpen]);

  const onDeleteModalToggle = useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const onBlockUser = useCallback(() => {
    onUpdateUser({ blocked: !user?.blocked });
    onBlockModalToggle();
  }, [onUpdateUser, onBlockModalToggle, user]);

  const onDeleteUser = useCallback(() => {
    if (user) {
      dispatch(
        deleteUser(user?._id, () => {
          history.push('/users');
        }),
      );
    }
  }, [dispatch, history, user]);

  const onDeleteStory = useCallback(
    (id: string, callback?: () => void) => () => {
      dispatch(deleteUserStory(id));
      callback && callback();
    },
    [dispatch],
  );

  useEffect(() => {
    const startPointOfUserId = history.location.pathname.lastIndexOf('/') + 1;
    const userId = history.location.pathname.substr(startPointOfUserId);
    batch(() => {
      dispatch(getUser(userId, onUserFormInit));
      dispatch(getUserMeta(userId));
    });

    return () => {
      batch(() => {
        dispatch(setUser(null));
        dispatch(setUserStories([]));
      });
    };
  }, [dispatch, history, onUserFormInit]);

  useEffect(() => {
    dispatch(getUserRoles());
  }, [dispatch]);

  useEffect(() => {
    if (userForm) {
      validateForm();
    }
  }, [userForm, validateForm]);

  return (
    <GridContainer>
      <GridItem gridProps={{ xs: 12 }}>
        <DetailsNavWrapper>
          <QuaternaryLinkButton to={'/users'} text={'See all'} />

          <UserMenu isBlocked={user?.blocked || false} onBlock={onBlockModalToggle} onDelete={onDeleteModalToggle} />
          <MessageModal
            isOpen={isBlockModalOpen}
            message={`Are you sure you want to ${user?.blocked ? 'unblock' : 'block'} ${user?.username}?`}
            onAccept={onBlockUser}
            onCancel={onBlockModalToggle}
          />
          <MessageModal
            isOpen={isDeleteModalOpen}
            message={`Are you sure you want to delete ${user?.username}?`}
            onAccept={onDeleteUser}
            onCancel={onDeleteModalToggle}
          />
        </DetailsNavWrapper>
      </GridItem>

      <GridItem gridProps={{ xs: 12 }}>
        <Box mb={4}>
          <UserInfo user={user} />
        </Box>
      </GridItem>

      <GridItem
        gridProps={{ xs: 12, style: { display: 'flex', columnGap: '12px', flexWrap: 'wrap', margin: '0 0 16px' } }}
      >
        <Box marginBottom={'16px'}>
          <AnalyticsCard label="Total Stories" value={userStoriesCount ?? 0} />
        </Box>

        <Box marginBottom={'16px'}>
          <AnalyticsCard label="Total Workspaces" value={workspacesCount ?? 0} />
        </Box>
      </GridItem>

      <GridItem gridProps={{ xs: 12 }}>
        <TabContext value={tabValue}>
          <TabList TabIndicatorProps={{ className: classes.tabBorder }} onChange={handleTabChange}>
            {USER_DETAILS_TABS.map((tab: string) => (
              <Tab key={tab} className={classes.tab} label={tab} value={tab} />
            ))}
          </TabList>

          <TabPanel className={classes.tabPanel} value={USER_SETTINGS}>
            <UserDetailsForm
              formInfo={userForm}
              errors={userFormErrors}
              userRoles={userRoles}
              onInputChange={onInputChange}
              onRoleSelect={onRoleSelect}
              onCheckBoxChange={onCheckBoxChange}
              onSubmit={onUpdateUserSettings}
            />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={USER_STORIES}>
            <UserStories
              userId={user?._id}
              areFetched={areStoriesFetched}
              onFetchCallback={onStoriesFetch}
              onDeleteStory={onDeleteStory}
            />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={USER_WORKSPACES}>
            <UserWorkspaces userId={user?._id} areFetched={areWorkspacesFetched} onFetchCallback={onWorkspacesFetch} />
          </TabPanel>
        </TabContext>
      </GridItem>
    </GridContainer>
  );
};

export default UserDetails;
