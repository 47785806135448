import React, { ReactElement } from 'react';
/** Components */
import { FormControlLabel } from '@material-ui/core';
/** UI */
import FormControlLabelUI from './form-control-label-ui';

interface IFormControlLabelComponent {
  control: ReactElement<any, any>;
  label: string;
}

const FormControlLabelComponent = ({ control, label }: IFormControlLabelComponent): JSX.Element => {
  const classes = FormControlLabelUI();

  return (
    <FormControlLabel
      classes={{
        label: classes.label,
      }}
      control={control}
      label={label}
    />
  );
};

export default FormControlLabelComponent;
