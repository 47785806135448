import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  checkbox: {
    fontFamily: 'Heebo',
    color: 'var(--white)',
  },
  root: {
    color: 'var(--white)',
    '&$checked': {
      color: 'var(--primary)',
    },
  },
  label: {
    color: 'var(--white)',
  },
  checked: {},
});

export default makeStyles(styles);
