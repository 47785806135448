import React, { useCallback } from 'react';
/** Components */
import { FormControl, FormControlProps, InputLabel, InputLabelProps, TextareaAutosize } from '@material-ui/core';
/** UI */
import clsx from 'clsx';
import AutosizeTextareaUI from './autosize-textarea-ui';
import classNames from 'classnames';

interface IAutosizeTextarea {
  id?: string;
  formControlProps: FormControlProps;
  labelProps?: InputLabelProps;
  labelText?: string;
  className?: string;
  name?: string;
  rowsMin?: number;
  rowsMax?: number;
  placeholder?: string;
  value: string | number;
  readOnly?: boolean;
  errorText?: string;
  success?: boolean;
  onChange?: (params?: any) => void;
}

const AutosizeTextarea = ({
  id,
  labelProps,
  labelText,
  className,
  name,
  formControlProps,
  rowsMin,
  rowsMax,
  placeholder,
  value,
  readOnly,
  errorText,
  success,
  onChange,
}: IAutosizeTextarea): JSX.Element => {
  const classes = AutosizeTextareaUI();
  const [isFocused, setIsFocused] = React.useState(false);
  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => setIsFocused(false), []);

  const labelClasses = clsx({
    [classes.labelRootError]: errorText,
    [classes.labelRootSuccess]: success && !errorText,
    ['MuiInputLabel-shrink']: isFocused || value.toString().length > 0,
  });

  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined && (
        <InputLabel className={classes.labelRoot + ' ' + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      )}

      <TextareaAutosize
        className={clsx(classes.textArea, {
          [classes.readOnly]: readOnly,
          [className as string]: className,
        })}
        name={name}
        rowsMin={rowsMin}
        rowsMax={rowsMax}
        value={value}
        readOnly={readOnly}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default AutosizeTextarea;
