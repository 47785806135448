import { makeStyles } from '@material-ui/core/styles';
import { dashboardSidebarWidth, dashboardHeader } from 'constants/material-ui';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    width: '100%',
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -dashboardSidebarWidth,
  },
  contentShift: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    width: 'calc(100% - 180px)',
  },
  drawerHeader: dashboardHeader(theme),
}));
