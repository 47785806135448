enum WorkspaceRoleName {
  owner = 'owner',
  admin = 'admin',
  user = 'user',
}
interface IDomain {
  _id?: string;
  author: string;
  workspaces: string[];
  groupName: string;
  domainName: string;
}

interface WorkspaceRole {
  _id: string;
  name: WorkspaceRoleName;
}

interface CustomCDN {
  isEnabled: boolean;
  cdns?: string[];
  mask?: string;
}

export enum UserStatus {
  accepted = 'ACCEPTED',
  pending = 'PENDING',
}

export interface WorkspaceUser {
  _id: string;
  status: UserStatus;
  token?: string;
  role: WorkspaceRole;
  email: string;
  addedAt: string;
}

export enum WorkspaceTypes {
  PAID = 'PAID',
  TRIAL = 'TRIAL',
}

export enum LoadStrategy {
  LOAD_ON_THE_FLY = 'LOAD_ON_THE_FLY',
  PRELOAD = 'PRELOAD',
}

export interface IWorkspace {
  _id: string;
  type: WorkspaceTypes;
  trialEndsAt: string | null;
  domainSettings: {
    activeDomain?: IDomain;
    domainList: IDomain[];
  };
  title: string;
  owner: string;
  users: WorkspaceUser[];
  createdAt: string;
  logoUrl?: string;
  numberOfStories?: number;
  numberOfPublishedStories?: number;
  cutsCount?: number;
  isStoryConversionEnabled: boolean;
  customCDN: CustomCDN;
  sitemapInitialized: boolean;
  newssitemap: { isInitialized: boolean };
  rssFeed: { isInitialized: boolean };
  loadStrategy: LoadStrategy;
}
