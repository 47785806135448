import React from 'react';
import UserDetails from '../components/user-details/user-details';

const UserDetailsPage = (): JSX.Element => {
  return (
    <div>
      <UserDetails />
    </div>
  );
};

export default UserDetailsPage;
