import React from 'react';
/** Router */
import { Link } from 'react-router-dom';
/** UI */
import clsx from 'clsx';
import QuaternaryButtonUI from './quaternary-button-ui';

type ISizeType = 'small' | 'medium';

interface IQuaternaryButton {
  className?: string;
  to: string | { pathname: string };
  text: string;
  isDisabled?: boolean;
  sizeType?: ISizeType;
  onClick?: () => void;
  target?: string;
}

const QuaternaryLinkButton = ({
  className,
  to,
  text,
  isDisabled,
  sizeType = 'medium',
  onClick,
  target,
}: IQuaternaryButton): JSX.Element => {
  const classes = QuaternaryButtonUI();
  const classNames = clsx(classes.button, classes[sizeType], {
    [classes.disabled]: isDisabled,
    [className as string]: className,
  });

  return (
    <Link to={to} className={classNames} onClick={onClick} target={target}>
      {text}
    </Link>
  );
};

export default QuaternaryLinkButton;
