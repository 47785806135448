import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  infoBox: {
    paddingBottom: '5px',
  },
  cover: {
    width: '65px',
    height: 'auto',
  },
});
