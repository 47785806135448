import React, { useState, useCallback } from 'react';
/** UI */
import TableUI from './table-ui';
/** COMPONENTS */
import { TableContainer, Table as Container } from '@material-ui/core';
import MessageModal from 'components/message-modal/message-modal';
import TableHead from './table-header/table-header';
import TableBody from './table-body/table-body';
import TableFooter from './table-footer/table-footer';
/** INTERFACES */
import { ITableHeaderColumn } from 'interfaces/table/table-column';
import { ISortOptions, ITableRow } from 'interfaces/table/table-row';
import { ITableData } from 'interfaces/table/table-data';

interface IProps {
  maxWidth?: string;
  isLoading?: boolean;
  columns: ITableHeaderColumn[];
  rowsPerPage: number;
  withActions?: boolean;
  data: ITableData;
  pagesCount: number | undefined;
  sortOptions: ISortOptions;
  tableRowsData: ITableRow[];
  deleteMessageModal?: string;
  handleDelete?: (id: string) => void;
  handleSortOptionClick: (column: ITableHeaderColumn) => void;
}

const Table = ({
  maxWidth = '1024px',
  isLoading,
  data,
  columns,
  pagesCount,
  rowsPerPage,
  sortOptions,
  withActions = false,
  tableRowsData,
  deleteMessageModal,
  handleDelete,
  handleSortOptionClick,
}: IProps): JSX.Element => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [entityId, setEntityId] = useState<string | null>(null);
  const classes = TableUI({ maxWidth });

  const toggleDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const onAcceptDelete = useCallback(() => {
    if (entityId && handleDelete) {
      handleDelete(entityId);
      setEntityId(null);
      setIsDeleteModalOpen(false);
    }
  }, [handleDelete, entityId]);

  return (
    <>
      <MessageModal
        isOpen={isDeleteModalOpen}
        message={deleteMessageModal || `Are you sure you want to continue ?`}
        onAccept={onAcceptDelete}
        onCancel={toggleDeleteModal}
      />
      <TableContainer classes={{ root: classes.container }}>
        <Container className={classes.table} aria-label="custom pagination table">
          <TableHead
            withActions={withActions}
            columns={columns}
            sortOptions={sortOptions}
            handleSortOptionClick={handleSortOptionClick}
          />
          <TableBody
            colSpan={withActions ? columns.length + 1 : columns.length}
            setEntityId={setEntityId}
            toggleDeleteModal={toggleDeleteModal}
            withActions={withActions}
            rowsPerPage={rowsPerPage}
            data={data}
            tableRowsData={tableRowsData}
            isLoading={isLoading}
          />
        </Container>
      </TableContainer>
      <TableFooter maxWidth={maxWidth} rowsPerPage={rowsPerPage} pagesCount={pagesCount || 0} />
    </>
  );
};

export default Table;
