import axios from 'axios';
import { environment } from 'config/environment';
import { setupCache } from 'axios-cache-adapter';
import { AUTH_URLS } from 'config/urls';
import { Mutex } from 'async-mutex';

// create a new mutex
const mutex = new Mutex();

// Create a cache adapter using axios-cache-adapter
const cache = setupCache({
  maxAge: 1 * 60 * 1000, // Cache responses for 15 minutes
  exclude: {
    query: false,
  },
});

// Create an instance of Axios with the cache adapter
const api = axios.create({
  baseURL: environment.host,
  adapter: cache.adapter,
});

const attachInterceptors = (): void => {
  // Request interceptor for API calls
  api.interceptors.request.use(
    async (config) => {
      // Get token from local storage
      const value = localStorage.getItem('accessToken');
      config.headers = {
        Authorization: `Bearer ${value}`,
        ...config.headers,
      };
      await mutex.waitForUnlock();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();

          originalRequest._retry = true;
          try {
            const { data } = await axios.post(environment.host + AUTH_URLS.REFRESH, {
              token: localStorage.getItem('refreshToken'),
            });
            if (data) {
              // Set tokens
              localStorage.setItem('accessToken', data?.accessToken?.token);
              localStorage.setItem('refreshToken', data?.refreshToken?.token);

              axios.defaults.headers.common['Authorization'] = `Bearer ${data?.accessToken?.token}`;
              originalRequest.headers = {
                ...originalRequest?.headers,
                Authorization: `Bearer ${data?.accessToken?.token}`,
              };
              return api(originalRequest);
            }
          } catch (err) {
            console.error(err);
          } finally {
            release();
          }

          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
        } else {
          await mutex.waitForUnlock();
          return api(originalRequest);
        }
      }
      return Promise.reject(error);
    },
  );
};

export { attachInterceptors };
export default api;
