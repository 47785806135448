import React from 'react';
/** COMPONENTS */
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
/** UI */
import ListItemUI from './sidebar-list-item-ui';
/** INTERFACES */
import { ISidebarListItem } from 'interfaces/sidebar-list-item';

const SidebarListItem = ({ Icon, title, link }: ISidebarListItem): JSX.Element => {
  const classes = ListItemUI();

  return (
    <NavLink to={link} className={classes.link}>
      <ListItem disableRipple button key={title}>
        <ListItemIcon className={classes.iconWrapper}>
          <Icon className={classes.sidebarIcon} />
        </ListItemIcon>
        <ListItemText primary={title} className={classes.sidebarIconText} />
      </ListItem>
    </NavLink>
  );
};

export default SidebarListItem;
