import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  maxWidth?: string;
}

export default makeStyles<Theme, StyleProps>(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px',
      color: 'var(--white)',
      maxWidth: ({ maxWidth }) => (maxWidth ? maxWidth : '100%'),
      margin: '10px 0 0',
      '& $list ': {
        '& *': {
          color: 'var(--white)',
        },
        '& .Mui-selected': {
          background: 'var(--primary)',
          '&:hover': {
            background: 'var(--primary-50)',
          },
        },
        '& .MuiPaginationItem-root': {
          color: 'var(--white)',
          '&:hover': {
            background: 'var(--primary-50)',
          },
        },
      },
    },
    list: {},
  }),
);
