import React from 'react';
/** Router */
import { Link } from 'react-router-dom';
/** UI */
import SecondaryButtonUI from './primary-button-ui';
import clsx from 'clsx';

type ISizeType = 'small' | 'medium';

interface IPrimaryButton {
  className?: string;
  to: string;
  text: string;
  isDisabled?: boolean;
  sizeType?: ISizeType;
}

const PrimaryLinkButton = ({ className, to, text, isDisabled, sizeType = 'medium' }: IPrimaryButton): JSX.Element => {
  const classes = SecondaryButtonUI();
  const classNames = clsx(classes.button, classes[sizeType], {
    [classes.disabled]: isDisabled,
    [className as string]: className,
  });

  return (
    <Link className={classNames} to={to}>
      {text}
    </Link>
  );
};

export default PrimaryLinkButton;
