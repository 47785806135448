/** COMPONENTS */
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
/** UI */
import { withStyles, createStyles } from '@material-ui/core/styles';
import TableHeaderUI from './table-header-ui';
/** INTERFACES */
import { ITableHeaderColumn } from 'interfaces/table/table-column';
import { ISortOptions } from 'interfaces/table/table-row';

interface IProps {
  columns: ITableHeaderColumn[];
  withActions: boolean;
  sortOptions: ISortOptions;
  handleSortOptionClick: (column: ITableHeaderColumn) => void;
}

const StyledTableSortLabel = withStyles(() =>
  createStyles({
    root: {
      '&&': {
        color: 'var(--white)',
      },
    },
    icon: {
      color: 'inherit !important',
    },
  }),
)(TableSortLabel);

const TableHeader = ({ columns, sortOptions, withActions, handleSortOptionClick }: IProps): JSX.Element => {
  const classes = TableHeaderUI();
  return (
    <TableHead>
      <TableRow>
        {columns.map((column: ITableHeaderColumn) => (
          <TableCell
            className={classes.cell}
            key={column.label}
            align={column.align || 'center'}
            style={{ minWidth: column.minWidth }}
          >
            {column.sortOption ? (
              <StyledTableSortLabel
                onClick={() => handleSortOptionClick(column)}
                active={!!sortOptions[column.id as keyof ISortOptions]}
                direction={sortOptions[column.id as keyof ISortOptions]}
              >
                {column.label}
              </StyledTableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
        {/* ACTIONS */}
        {withActions && (
          <TableCell className={classes.cell} key={'Actions'} align={'center'} style={{ minWidth: '50' }}>
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
