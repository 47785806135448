import { useCallback, useMemo, useState } from 'react';
/** Router */
import { useHistory } from 'react-router-dom';
/** UI */
import PaginationActionsUI from './table-footer-ui';
/** COMPONENTS */
import { Pagination } from '@material-ui/lab';

interface ITableFooterProps {
  maxWidth?: string;
  rowsPerPage: any;
  pagesCount: number;
}

const TableFooter = ({ maxWidth = '1024px', rowsPerPage, pagesCount }: ITableFooterProps): JSX.Element => {
  const classes = PaginationActionsUI({ maxWidth });
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(history.location.search), [history?.location?.search]);
  const [currentPage, setCurrentPage] = useState<number>(parseInt('' + params.get('page')));

  const paginate = useCallback(
    (event: any, number: number) => {
      setCurrentPage(number);
      history.push({
        pathname: `${history.location.pathname}`,
        search: '?page=' + number,
      });
    },
    [history],
  );

  return (
    <Pagination
      classes={{ root: classes.root, ul: classes.list }}
      page={currentPage || 1}
      defaultPage={1}
      count={Math.ceil(pagesCount / rowsPerPage)}
      showFirstButton
      showLastButton
      onChange={paginate}
    />
  );
};

export default TableFooter;
