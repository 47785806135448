import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  icon: {
    color: 'var(--shade-100)',
  },
  menuContainer: {
    color: 'var(--white)',
    backgroundColor: 'var(--shade-500)',
  },
});
