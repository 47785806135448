import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  infoBox: {
    position: 'relative',
    paddingBottom: '5px',
    zIndex: 10,
  },
  avatar: {
    width: '80px',
    height: '80px',
    background: 'var(--white)',
  },
});
