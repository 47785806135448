/** Redux */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadingFinished, loadingStarted } from '../loader/loaderSlice';
import { AppThunk } from '../../store';
/** constants */
import { STORY_URLS } from '../../../config/urls';
import { STORIES_REDUCER } from '../../constants';
/** Interfaces */
import { IStory } from 'interfaces/stories';
import { IRequestListOptions } from 'interfaces/request-options';
/* Utils */
import qs from 'qs';
import { AxiosResponse } from 'axios';
import api from 'utils/api';
import { ITableHeaderColumn } from '../../../interfaces/table/table-column';
import { batch } from 'react-redux';
import { IAddSortOptionPayload } from '../../../interfaces/table-search';

interface IStoryList {
  stories: IStory[];
  totalCount: number;
  hasNext: boolean;
  limit: number;
  offset: number;
  options: IRequestListOptions;
}

interface IStoryListMeta {
  storiesCount: 0;
  publishedStoriesCount: 0;
  cutsCount: 0;
}

interface IStoryState {
  story: null | IStory;
  storyList: IStoryList;
  isStoryListLoading: boolean;
  storyListMeta: IStoryListMeta;
}

const initialState: IStoryState = {
  story: null,
  storyList: {
    stories: [],
    totalCount: 0,
    hasNext: false,
    limit: 30,
    offset: 0,
    options: {
      sort: {},
      search: {},
    },
  },
  storyListMeta: {
    storiesCount: 0,
    publishedStoriesCount: 0,
    cutsCount: 0,
  },
  isStoryListLoading: false,
};

const storiesSlice = createSlice({
  name: STORIES_REDUCER,
  initialState,
  reducers: {
    setStory: (state, action: PayloadAction<IStory | null>) => {
      state.story = action.payload;
    },
    setStoryListMeta: (state, action: PayloadAction<IStoryListMeta | null>) => {
      if (action.payload) {
        state.storyListMeta = action.payload;
      } else {
        state.storyListMeta = {
          storiesCount: 0,
          publishedStoriesCount: 0,
          cutsCount: 0,
        };
      }
    },
    setStories: (state, action: PayloadAction<IStory[] | []>) => {
      state.storyList.stories = action.payload;
    },
    setStoryListData: (state, action: PayloadAction<IStoryList>) => {
      state.storyList = { ...state.storyList, ...action.payload };
    },
    toggleIsStoryListLoading: (state, action: PayloadAction<boolean>) => {
      state.isStoryListLoading = action.payload;
    },
    setStoryListSortOption: (state, action: PayloadAction<IAddSortOptionPayload>) => {
      const { option, value } = action.payload;
      state.storyList.options.sort = { [option]: value };
    },
    setStoryListSearchOption: (state, action: PayloadAction<any>) => {
      state.storyList.options.search = action.payload;
    },
    clearStoryListSortOption: (state) => {
      state.storyList.options.sort = initialState.storyList.options.sort;
    },
    clearStoryListOptions: (state) => {
      state.storyList.options = initialState.storyList.options;
    },
  },
});

export const {
  setStory,
  setStoryListData,
  toggleIsStoryListLoading,
  setStoryListSearchOption,
  clearStoryListSortOption,
  clearStoryListOptions,
  setStories,
  setStoryListSortOption,
  setStoryListMeta,
} = storiesSlice.actions;

export default storiesSlice.reducer;

export const getStory =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const storyResponse: AxiosResponse<IStory> = await api.get(STORY_URLS.GET_STORY(id));
      dispatch(setStory(storyResponse.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const getStoryList =
  ({
    limit,
    offset,
    options,
    workspaceId,
  }: {
    limit: number;
    offset: number;
    options?: IRequestListOptions;
    workspaceId?: string;
  }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleIsStoryListLoading(true));
      let queryParams = '';
      if (options) {
        queryParams = qs.stringify(options);
      }
      if (workspaceId) {
        queryParams = `${queryParams}&workspaceId=${workspaceId}`;
      }

      const storyListResponse: AxiosResponse<IStoryList> = await api.get(
        STORY_URLS.STORY_LIST(offset, limit, queryParams),
      );
      dispatch(setStoryListData(storyListResponse.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleIsStoryListLoading(false));
    }
  };

export const getStoryListMeta = (): AppThunk => async (dispatch) => {
  try {
    const storyListMetaResponse: AxiosResponse<IStoryListMeta> = await api.get(STORY_URLS.GET_STORY_LIST_META);
    dispatch(setStoryListMeta(storyListMetaResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export const deleteStory =
  (id: string, successCallback?: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const stories = state.stories.storyList.stories;
      dispatch(loadingStarted());
      await api.delete(STORY_URLS.DELETE_STORY(id));
      dispatch(setStories(stories.filter((story: IStory) => story._id !== id)));
      successCallback && successCallback();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const setSortOption =
  (column: ITableHeaderColumn): AppThunk =>
  (dispatch, getState) => {
    const {
      stories: {
        storyList: {
          options: { sort },
        },
      },
    } = getState();
    const option = column.id;

    if (sort[column.id]) {
      /** Toggle ASC or DESC value */
      const value = sort[column.id] === 'asc' ? 'desc' : 'asc';
      return dispatch(setStoryListSortOption({ option, value }));
    }

    return batch(() => {
      dispatch(clearStoryListSortOption());
      dispatch(setStoryListSortOption({ option, value: 'asc' }));
    });
  };

export const publishStory =
  (storyId: string, rebuildCover = false, initialPublish = false): AppThunk =>
  async (dispatch) => {
    dispatch(loadingStarted());
    try {
      const { data: story } = await api.post<IStory>(STORY_URLS.PUBLISH_STORY, {
        storyId,
        rebuildCover,
        initialPublish,
      });
      dispatch(setStory(story));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(loadingFinished());
    }
  };
