import React from 'react';
/** UI */
import { Typography } from '@material-ui/core';
import InfoBoxLabelUI from './info-box-label-ui';

interface IInfoBoxLabel {
  text: string;
}

const InfoBoxLabel = ({ text }: IInfoBoxLabel): JSX.Element => {
  const classes = InfoBoxLabelUI();

  return (
    <Typography className={classes.label} component={'p'} variant={'subtitle2'}>
      {text}
    </Typography>
  );
};

export default InfoBoxLabel;
