import React from 'react';
/** Components */
import { Typography } from '@material-ui/core';
/** UI */
import PageTitleUI from './page-title-ui';

interface IPageTitle {
  text: string;
}

const PageTitle = ({ text }: IPageTitle): JSX.Element => {
  const classes = PageTitleUI();

  return (
    <Typography className={classes.title} component={'h1'} variant={'h5'}>
      {text}
    </Typography>
  );
};

export default PageTitle;
