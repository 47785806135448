/** Interfaces */
import { IWorkspace } from 'interfaces/workspaces';
/** Components */
import defaultAvatar from 'assets/images/default-avatar.svg';
import { Avatar, Box } from '@material-ui/core';
import { InfoBoxLabel, InfoBox, InfoBoxValue } from 'components/shared';
/** Utils */
import formatDate from 'utils/formatDate';
/** UI */
import WorkspaceInfoUI from './workspace-info-ui';

interface IWorkspaceInfo {
  workspace: IWorkspace | null;
}

const WorkspaceInfo = ({ workspace }: IWorkspaceInfo): JSX.Element => {
  const classes = WorkspaceInfoUI();
  const owner = workspace?.users?.find((u) => u?._id === workspace?.owner);
  return (
    <InfoBox className={classes.infoBox}>
      <Box display={'flex'} flexWrap={'wrap'} mr={12}>
        <Box pr={3} mb={2}>
          <Avatar className={classes.avatar} alt="Avatar" src={workspace?.logoUrl ?? defaultAvatar} />
        </Box>

        <Box pr={3} mb={2}>
          <Box mb={2}>
            <InfoBoxLabel text="Workspace" />

            <InfoBoxValue component={'h1'} text={`${workspace?.title}`} />
          </Box>
        </Box>
      </Box>

      <Box display={'flex'} flexWrap={'wrap'}>
        <Box pr={3} mb={2} mr={3}>
          <InfoBoxLabel text="ID" />

          <InfoBoxValue text={workspace?._id} />
        </Box>

        <Box pr={3} mb={2} mr={3}>
          <InfoBoxLabel text="Owner" />

          <InfoBoxValue text={owner?.email} />
        </Box>

        <Box pr={3} mb={2}>
          <InfoBoxLabel text="Created" />

          <InfoBoxValue text={formatDate(workspace?.createdAt || '')} />
        </Box>
      </Box>
    </InfoBox>
  );
};

export default WorkspaceInfo;
