import { makeStyles } from '@material-ui/core/styles';
import { dashboardSidebarWidth, dashboardHeader } from 'constants/material-ui';

export default makeStyles((theme) => ({
  drawer: {
    width: dashboardSidebarWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: dashboardSidebarWidth,
    backgroundColor: 'var(--shade-900)',
  },
  drawerHeader: dashboardHeader(theme),
}));
