/** COMPONENTS */
import { CircularProgress } from '@material-ui/core';
/** UI */
import SpinnerUI from './spinner-ui';

const Spinner = (): JSX.Element => {
  const classes = SpinnerUI();
  return (
    <div className={classes.spinnerOvarlay}>
      <div className={classes.spinnerContainer}>
        <CircularProgress size={120} className={classes.spinner} />
      </div>
    </div>
  );
};

export default Spinner;
