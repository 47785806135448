import React, { ReactNode } from 'react';
/** Components */
import { Box } from '@material-ui/core';
/** UI */
import InfoBoxUI from './info-box-ui';
import clsx from 'clsx';

interface IInfoBox {
  className?: string;
  display?: 'flex' | 'block' | 'inline-block' | 'inline' | 'inline-flex';
  flexFlow?: string | 'row wrap' | 'column wrap';
  children: ReactNode;
}

const InfoBox = ({ className, display = 'flex', flexFlow = 'row wrap', children }: IInfoBox): JSX.Element => {
  const classes = InfoBoxUI({ display, flexFlow });

  return (
    <Box
      className={clsx(classes.box, {
        [className as string]: className,
      })}
    >
      {children}
    </Box>
  );
};

export default InfoBox;
