import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  spinnerContainer: {
    position: 'absolute',
    top: '45%',
    left: '45%',
    zIndex: 9999,
  },
  spinnerOvarlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: '#666',
    opacity: 0.8,
    zIndex: 9998,
    height: '100%',
    width: '100%',
  },
  spinner: {
    color: 'var(--primary)',
  },
});
