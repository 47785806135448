import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  infoBox: {
    paddingBottom: '5px',
  },
  avatar: {
    width: '80px',
    height: '80px',
    background: 'var(--white)',
  },
});
