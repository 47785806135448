import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  button: {
    borderRadius: '6px',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.2s ease',
    outline: 'none',
    border: 'none',
    background: 'transparent',
    '&:hover': {
      background: 'var(--shade-500-85)',
    },
  },
  icon: {
    color: 'var(--shade-100)',
    fontSize: '14px',
  },
});
