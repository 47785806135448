import { ReactNode } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

interface IDatePickerWrapper {
  isDisabled?: boolean;
  children: ReactNode;
  onClick?: () => void;
}

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  isDisabled?: boolean;
}

const styles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    wrapper: {
      position: 'relative',
      '& .react-datepicker__triangle': {
        display: 'none',
      },
      '& .react-datepicker__input-container input': {
        background: 'transparent',
        border: 'none',
        borderBottom: '1px solid var(--white)',
        fontFamily: 'Heebo',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        color: ({ isDisabled }) => (isDisabled ? 'var(--shade-300)' : 'var(--white)'),
        '-webkit-text-fill-color': ({ isDisabled }) => (isDisabled ? 'var(--shade-300)' : 'var(--white)'),
        padding: '8px 0',
        width: '100%',
        '&:focus': {
          outline: 'none',
        },
        '&.react-datepicker-ignore-onclickoutside': {
          borderBottom: '1px solid var(--primary)',
        },
      },
      '& .react-datepicker': {
        border: '1px solid transparent',
        background: 'var(--shade-500)',
        boxShadow:
          '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      },
      '& .react-datepicker__header': {
        borderBottom: 'none',
        background: 'transparent',
        padding: '0',
      },
      '& .react-datepicker__current-month': {
        padding: '16px 0',
      },
      '& .react-datepicker__day-name, & .react-datepicker__day, & .react-datepicker__time-name': {
        color: 'var(--white)',
        lineHeight: '1.969',
        borderRadius: '50%',
        border: '1px solid transparent',
        margin: '0.104rem',
        transition: '0.12s ease',
        background: 'transparent',
      },
      '& .react-datepicker__current-month, & .react-datepicker-time__header, & .react-datepicker-year-header': {
        color: 'var(--white)',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
      },
      '& .react-datepicker__day--outside-month, & .react-datepicker__day--disabled': {
        color: 'var(--white)',
        opacity: '0.4',
      },
      '& .react-datepicker__navigation': {
        top: '12px',
        '&.react-datepicker__navigation--next': {
          right: '2px',
        },
      },
      '& .react-datepicker__navigation-icon::before': {
        width: '6px',
        height: '6px',
        borderColor: 'var(--white)',
        borderWidth: '2px 2px 0 0',
      },
      '& .react-datepicker__day-names': {
        marginBottom: '-5px',
      },
      '& .react-datepicker__day--today': {
        background: 'none',
        border: '1px solid var(--white)',
      },
      '& .react-datepicker__day:hover, .react-datepicker__day--selected': {
        background: 'var(--primary)',
        border: '1px solid var(--primary)',
        fontWeight: 'normal',
        color: 'var(--shade-700)',
      },
      '& .react-datepicker__input-time-container': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '15px 0 10px',
        padding: '0 15px',
        float: 'none',
      },
      '& .react-datepicker-time__caption': {
        color: 'var(--white)',
        fontFamily: 'Heebo',
        fontSize: '12px',
        lineHeight: '18px',
      },
      '& .react-datepicker__navigation-icon': {
        top: '1px',
      },
      "& .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before": {
        borderBottomColor: 'var(--white-20)',
      },
      "& .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after": {
        borderBottomColor: 'var(--shade-700)',
      },
      '& .react-datepicker__input-time-container, .react-datepicker__month-container': {
        float: 'none',
      },
      '& .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input': {
        marginLeft: '0',
        "&input[type='time']": {
          backgroundColor: 'transparent',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center right 12px',
          color: 'var(--white)',
          border: '1px solid var(--white-20)',
          borderRadius: '6px',
          padding: '3px 12px 3px 12px',
          fontFamily: 'Heebo',
          fontSize: '12px',
          lineHeight: '18px',
          marginLeft: '0',
          '&::-webkit-calendar-picker-indicator': {
            opacity: '0',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
      '& .react-datepicker__time-container': {
        position: 'absolute',
        top: '-1px',
        left: 'calc(100% + 3px)',
        borderRadius: '6px',
        background: 'var(--shade-500)',
        overflow: 'hidden',
        border: '1px solid transparent',
        width: '107px',
        height: 'calc(100% + 3px)',
        display: 'flex',
        flexDirection: 'column',
        boxShadow:
          '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        '& .react-datepicker-time__header': {
          background: 'transparent',
          fontSize: '12px',
          padding: '10px 0 9px',
          borderBottom: '1px solid var(--white-20)',
        },
        '& .react-datepicker__time': {
          height: 'calc(100% - 44px) !important',
          background: 'transparent',
          '& .react-datepicker__time-box': {
            width: 'auto',
            height: '100%',
          },
        },
        '& .react-datepicker__time-list': {
          height: '100% !important',
          ' &::-webkit-scrollbar': {
            width: '3px',
            borderRadius: '12px',
          },
          ' &::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'transparent',
            borderRadius: '20px',
            transition: 'background 0.12s ease',
          },
          '&:hover': {
            scrollbarWidth: 'thin',
            scrollbarColor: 'var(--shade-300-85) transparent',
          },
          '&:hover::-webkit-scrollbar': {
            width: '3px',
            borderRadius: '2px',
          },
          '&:hover::-webkit-scrollbar-thumb': {
            background: 'var(--shade-300-85)',
          },
        },
        '& .react-datepicker__time-list-item': {
          fontFamily: 'Heebo',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '12px',
          lineHeight: '18px',
          height: 'auto',
          padding: '3px 5px',
          margin: '2px 17px',
          borderRadius: '6px',
          letterSpacing: '0.01em',
          color: 'var(--shade-100)',
          '&.react-datepicker__time-list-item--selected.react-datepicker__time-list-item--selected.react-datepicker__time-list-item--selected': {
            background: 'var(--primary)',
            color: 'var(--shade-900)',
            fontWeight: 'normal',
          },
          '&.react-datepicker__time-list-item.react-datepicker__time-list-item.react-datepicker__time-list-item': {
            height: 'auto',
            padding: '3px 5px',
            '&:hover': {
              background: 'var(--primary)',
              color: ' var(--shade-900)',
            },
          },
          '&.react-datepicker__time-list-item--disabled.react-datepicker__time-list-item--disabled.react-datepicker__time-list-item--disabled': {
            color: 'var(--white)',
            opacity: '0.3',
          },
        },
      },
    },
  }),
);

const DatePickerWrapper = ({ isDisabled, children, onClick }: IDatePickerWrapper): JSX.Element => {
  const classes = styles({ isDisabled });
  return (
    <div className={classes.wrapper} onClick={onClick}>
      {children}
    </div>
  );
};

export default DatePickerWrapper;
