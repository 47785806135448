import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
    transition: '0.12s ease',
    borderRadius: '8px',
    margin: '0 0 8px',
    '&:hover': {
      background: 'var(--shade-500-85)',
    },
    '&.active': {
      background: 'var(--primary-20)',
      '& $sidebarIcon': {
        color: 'var(--primary)',
      },
      '& $sidebarIconText': {
        color: 'var(--primary)',
      },
    },
  },
  iconWrapper: {
    minWidth: '41px',
  },
  sidebarIcon: {
    color: 'var(--shade-100)',
  },
  sidebarIconText: {
    color: 'var(--shade-100)',
    margin: '0',
  },
});
