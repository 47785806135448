import { makeStyles } from '@material-ui/core/styles';
import { dashboardSidebarWidth } from 'constants/material-ui';

export default makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${dashboardSidebarWidth}px)`,
    marginLeft: dashboardSidebarWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'var(--shade-900)',
  },
  toolBarSingleElement: {
    justifyContent: 'flex-end',
  },
  menuContainer: {
    color: 'var(--white)',
    backgroundColor: 'var(--shade-500)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'var(--primary)',
  },
  hide: {
    display: 'none',
  },
}));
