import React, { useEffect } from 'react';
import { GridItem, GridContainer } from '../shared';
import { Box } from '@material-ui/core';
import AnalyticsCard from '../shared/analytics-card/analytics-card';
import { getAverageCuts } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getStoryListMeta, setStoryListMeta } from '../../redux/features/stories/storiesSlice';

const StoryListMeta = (): JSX.Element => {
  const dispatch = useDispatch();
  const { storiesCount, publishedStoriesCount, cutsCount } = useSelector(
    (state: RootState) => state.stories.storyListMeta,
  );

  useEffect(() => {
    dispatch(getStoryListMeta());

    return () => {
      dispatch(setStoryListMeta(null));
    };
  }, [dispatch]);

  return (
    <GridContainer>
      <GridItem
        gridProps={{ xs: 12, style: { display: 'flex', columnGap: '12px', flexWrap: 'wrap', margin: '0 0 16px' } }}
      >
        <Box marginBottom={'16px'}>
          <AnalyticsCard label={'Total Stories'} value={storiesCount} />
        </Box>

        <Box marginBottom={'16px'}>
          <AnalyticsCard label={'Published Stories'} value={publishedStoriesCount} />
        </Box>

        <Box marginBottom={'16px'}>
          <AnalyticsCard label={'Artboards per Story '} value={getAverageCuts(cutsCount, storiesCount)} />
        </Box>
      </GridItem>
    </GridContainer>
  );
};

export default StoryListMeta;
