import { ReactNode } from 'react';
import classNames from 'classnames';
/** UI */
import CardContentUI from './card-content-ui';

type IProps = {
  children: ReactNode;
  className?: string;
};

const CardContent = ({ className, children, ...rest }: IProps): JSX.Element => {
  const classes = CardContentUI();
  const options = {
    [classes.cardBody]: true,
  };
  if (className) {
    options[className] = className !== undefined;
  }

  const cardBodyClasses = classNames(options);
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
};

export default CardContent;
