import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import formatDate from 'utils/formatDate';
import Modal from '@material-ui/core/Modal';
import ModalUI from './users-modal-ui';
import PageTitle from 'components/shared/page-title/page-title';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TableToolBar from 'components/shared/table/table-toolbar/table-toolbar';
import useList from 'hooks/useList';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import {
  clearUserListSortOption,
  deleteUser,
  getUserList,
  setSortOption,
  setUserListSearchOption,
} from 'redux/features/users/usersSlice';
import { Email, Person } from '@material-ui/icons';
import { GridContainer, GridItem, InfoBox } from 'components/shared';
import { ITableActionCell, ITableCell, ITableRow } from 'interfaces/table/table-row';
import { ITableHeaderColumn, ITableHeaderUserColumnIds } from 'interfaces/table/table-column';
import { IUser } from 'interfaces/user';
import { PrimaryButton, PrimaryLinkButton, SecondaryButton } from '../buttons';
import { RootState } from 'redux/store';
import { Table } from 'components/shared/table/';
import { UserList } from 'components/user-list';
import { addWorkspaceMembers } from 'redux/features/workspaces/workspacesSlice';
/* Components */
/** UI */

const columns: ITableHeaderColumn[] = [
  { id: ITableHeaderUserColumnIds._id, label: 'ID', minWidth: 50, align: 'left' },
  { id: ITableHeaderUserColumnIds.email, label: 'E-Mail', minWidth: 50, sortOption: true, align: 'left' },
  { id: ITableHeaderUserColumnIds.username, label: 'Username', minWidth: 50, sortOption: true, align: 'left' },
  { id: ITableHeaderUserColumnIds.createdAt, label: 'Creation date', minWidth: 144, sortOption: true, align: 'left' },
];

const searchByOptions = [
  { name: 'E-mail', value: 'email', icon: <Email /> },
  { name: 'Username', value: 'username', icon: <Person /> },
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const UsersModal = ({ isOpen, onClose }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const classes = ModalUI();

  const workspaceUsers = useSelector((state: RootState) => state.workspaces.workspace?.users);
  const workspaceUsersIds = useMemo(() => workspaceUsers?.map((ws) => ws._id), [workspaceUsers]);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { users, limit, totalCount, options } = useSelector((state: RootState) => state.users.userList);
  const isUserListLoading = useSelector((state: RootState) => state.users.isUserListLoading);

  const getList = useCallback(
    (paramLimit: number, page: number) => {
      dispatch(getUserList(paramLimit, page === 1 ? 0 : (page - 1) * paramLimit, options));
    },
    [dispatch, options],
  );

  const searchParamChangeCallback = useCallback(
    (searchParam: string, searchValue: string | number) => {
      dispatch(setUserListSearchOption({ [searchParam]: searchValue }));
    },
    [dispatch],
  );

  const { searchValue, searchParam, handleSearchParamChange, handleSearchValueChange } = useList({
    getList,
    limit,
    defaultSearchParam: searchByOptions[0].value,
    debounceSearchCallback: searchParamChangeCallback,
    searchParamChangeCallback,
  });

  const handleDeleteUser = useCallback(
    (userId: string) => {
      batch(() => {
        dispatch(deleteUser(userId));
      });
    },
    [dispatch],
  );

  const clearOptions = useCallback(() => {
    dispatch(clearUserListSortOption());
  }, [dispatch]);

  const handleSortOptionClick = useCallback(
    (column: ITableHeaderColumn) => {
      dispatch(setSortOption(column));
    },
    [dispatch],
  );

  const addMembers = useCallback(() => {
    dispatch(addWorkspaceMembers(selectedIds));
    onClose();
  }, [dispatch, onClose, selectedIds]);

  const tableRowsData: ITableRow[] = useMemo(() => {
    return users
      .filter((user) => !workspaceUsersIds?.includes(user._id))
      .map((user: IUser) => {
        const cells: ITableCell[] = [
          { children: user?._id, align: 'left' },
          { children: user?.email, align: 'left' },
          { children: user?.username, align: 'left' },
          { children: formatDate(user?.createdAt), align: 'left' },
        ];

        const row: ITableRow = {
          cells,
          actionCellProps: null,
          isSelected: selectedIds.includes(user._id),
          callback: () =>
            setSelectedIds((prev) =>
              prev.includes(user._id) ? prev.filter((id) => id !== user._id) : [...prev, user._id],
            ),
        };

        return row;
      });
  }, [selectedIds, users, workspaceUsersIds]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <GridContainer>
            <GridItem gridProps={{ xs: 12 }}>
              <InfoBox flexFlow={'column wrap'}>
                <PageTitle text={'Users'} />
                <TableToolBar
                  searchValue={searchValue}
                  searchByValue={searchParam}
                  searchByOptions={searchByOptions}
                  sortOptions={options.sort}
                  clearOptions={clearOptions}
                  handleSearchValueChange={handleSearchValueChange}
                  handleSearchParamChange={handleSearchParamChange}
                >
                  <PrimaryButton
                    isDisabled={selectedIds.length === 0}
                    onClick={addMembers}
                    text={`Add ${selectedIds.length > 1 ? 'members' : 'member'}`}
                  />
                </TableToolBar>
                <Table
                  isLoading={isUserListLoading}
                  data={users}
                  columns={columns}
                  rowsPerPage={limit}
                  pagesCount={totalCount}
                  sortOptions={options.sort}
                  tableRowsData={tableRowsData}
                  deleteMessageModal={'Are you sure you want to delete user ?'}
                  handleDelete={handleDeleteUser}
                  handleSortOptionClick={handleSortOptionClick}
                />
              </InfoBox>
            </GridItem>
          </GridContainer>
        </div>
      </Fade>
    </Modal>
  );
};

export default UsersModal;
