import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
/** Components */
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { IStory } from 'interfaces/stories';
import { ArrowUpward, ArrowDownward, Search } from '@material-ui/icons';
import { Input } from 'components/shared';
import { DeleteButton, ViewLinkButton } from 'components/buttons';
import MessageModal from 'components/message-modal/message-modal';
/* Utils */
import sortCompareValues from 'utils/sortCompareValues';
import formatDate from 'utils/formatDate';
/* UI */
import UserStoriesUI from './user-workspaces-ui';
import clsx from 'clsx';
import { IWorkspace } from 'interfaces/workspaces';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { getUserWorkspaces } from '../../../redux/features/users/usersSlice';

const defaultLogoSrc = 'https://zazuapp.co/wp-content/uploads/2021/09/logo_orange-1.png';

const sort = (by: keyof IWorkspace | '', isAscending: boolean, array: any) => {
  switch (by) {
    case 'title':
      return array.sort(sortCompareValues(by, isAscending));
    default:
      return array;
  }
};

interface IHeaderCell {
  label: string;
  alignment: 'left' | 'center' | 'right';
  hasSort: boolean;
  sortValue: string | null | undefined;
  width?: string;
}

const getHeaderCells = (): IHeaderCell[] => {
  return [
    {
      label: 'Logo',
      alignment: 'left',
      hasSort: false,
      sortValue: '',
    },
    {
      label: 'Title',
      alignment: 'left',
      hasSort: true,
      sortValue: 'title',
    },
    {
      label: 'Owner',
      alignment: 'left',
      hasSort: false,
      sortValue: 'r',
    },
    {
      label: 'Role',
      alignment: 'left',
      hasSort: false,
      sortValue: '',
    },
    {
      label: 'AddedAt',
      alignment: 'right',
      hasSort: false,
      sortValue: '',
    },
    // {
    //   label: 'Updated',
    //   alignment: 'right',
    //   hasSort: true,
    //   sortValue: 'updatedAt',
    // },
    {
      label: 'Actions',
      alignment: 'right',
      hasSort: false,
      sortValue: '',
      width: '90px',
    },
  ];
};

interface ITableHeader {
  cells: IHeaderCell[];
  activeSort: string;
  isAscending?: boolean;
  onSort?: (params?: any) => any;
}

const TableHeader = ({ activeSort, cells, isAscending, onSort }: ITableHeader): JSX.Element => {
  const classes = UserStoriesUI();
  const getSortingIcon = () => {
    if (typeof isAscending === 'boolean' && onSort) {
      return isAscending ? (
        <ArrowDownward className={classes.sortingIcon} />
      ) : (
        <ArrowUpward className={classes.sortingIcon} />
      );
    }

    return '';
  };

  return (
    <TableHead>
      <TableRow>
        {cells.map((cell) => {
          return (
            <TableCell
              classes={{
                root: clsx(classes.tableCell, {
                  [classes.clickableCell]: cell.hasSort,
                }),
              }}
              key={cell.label}
              align={cell?.alignment}
              width={cell?.width}
              {...(cell.hasSort && { onClick: onSort && onSort(cell.sortValue) })}
            >
              {cell.hasSort && activeSort === cell.sortValue && getSortingIcon()}
              {cell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

interface IUserWorkspaces {
  userId?: string;
  areFetched: boolean;
  onFetchCallback: () => void;
  // onDeleteStory: (id: string, callback?: () => void) => void;
}

export default function UserWorkspaces({ userId, areFetched, onFetchCallback }: IUserWorkspaces): JSX.Element {
  const classes = UserStoriesUI();
  const dispatch = useDispatch();
  const userWorkspaces = useSelector((state: RootState) => state.users.userWorkspaces);
  const [activeSort, setActiveSort] = useState<keyof IWorkspace | ''>('');
  const [isAscending, setIsAscending] = useState(true);
  const [searchedWorkspace, setSearchedWorkspace] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState('');
  const [isDeleteWorkspaceModalOpen, setIsDeleteWorkspaceModalOpen] = useState(false);

  useEffect(() => {
    if (
      (userId && userWorkspaces?.length === 0 && !areFetched) ||
      (userId && userWorkspaces?.length && userWorkspaces?.[0]?.owner !== userId && !areFetched)
    ) {
      dispatch(getUserWorkspaces(userId));
      onFetchCallback();
    }
  }, [userId, dispatch, userWorkspaces, areFetched, onFetchCallback]);

  const onDeleteWorkspaceModalToggle = useCallback(
    (id?: string) => () => {
      if (id) {
        setSelectedWorkspaceId(id);
      }
      setIsDeleteWorkspaceModalOpen(!isDeleteWorkspaceModalOpen);
    },
    [isDeleteWorkspaceModalOpen],
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const onSearchWorkspace = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchedWorkspace(event.target.value);
    setPage(0);
  }, []);

  const sortedWorkspaces = useMemo(() => {
    if (userWorkspaces) {
      return sort(
        activeSort,
        isAscending,
        [...userWorkspaces].filter((ws) => ws.title.toLowerCase().includes(searchedWorkspace.toLowerCase())),
      );
    }
    return [];
  }, [activeSort, isAscending, userWorkspaces, searchedWorkspace]);

  const onTitleSort = useCallback(
    (title: keyof IWorkspace) => () => {
      if (activeSort === title) {
        setIsAscending(!isAscending);
        return;
      }
      setIsAscending(true);
      setActiveSort(title);
    },
    [isAscending, activeSort],
  );

  return (
    <Box className={classes.box}>
      <Box mb={2} maxWidth={'200px'}>
        <Input
          labelText="Workspace title"
          id="workspace-title"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            autoComplete: 'off',
            value: searchedWorkspace,
            name: 'workspace-title',
            type: 'text',
            onChange: onSearchWorkspace,
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TableContainer classes={{ root: classes.container }}>
        <Table className={classes.table} size="small">
          <TableHeader
            activeSort={activeSort}
            isAscending={isAscending}
            cells={getHeaderCells()}
            onSort={onTitleSort}
          />
          <TableBody>
            {sortedWorkspaces.length === 0 && (
              <TableRow>
                <TableCell classes={{ root: classes.tableCell }} align={'center'} colSpan={7}>
                  No workspaces
                </TableCell>
              </TableRow>
            )}
            {sortedWorkspaces.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: IWorkspace) => {
              const currentUserInWorkspace = row.users.find((u) => u?._id === userId);
              const workspaceOwner = row.users.find((u) => u?._id === row.owner);
              return (
                <TableRow key={row.title}>
                  <TableCell classes={{ root: classes.tableCell }}>
                    <img className={classes.image} src={row?.logoUrl ?? defaultLogoSrc} alt={row.title} />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    {row.title}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    {workspaceOwner?.email}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    {currentUserInWorkspace?.role?.name}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }} align="right">
                    {formatDate(currentUserInWorkspace?.addedAt ?? '', true)}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }} align="right">
                    <ViewLinkButton className={classes.action} to={`/workspaces/${row._id}`} />
                    {/* <DeleteButton className={classes.action} onClick={onDeleteWorkspaceModalToggle(row._id)} /> */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          classes={{ root: classes.pagination }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedWorkspaces.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <MessageModal
          isOpen={isDeleteWorkspaceModalOpen}
          message={`Are you sure you want to delete the selected story?`}
          // onAccept={onDeleteStory(selectedStoryId, onDeleteStoryModalToggle())}
          onCancel={onDeleteWorkspaceModalToggle()}
        />
      </TableContainer>
    </Box>
  );
}
