/** COMPONENTS */
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon, AccountCircle } from '@material-ui/icons';
/** UI */
import AppBarUI from './dashboard-header-ui';
import clsx from 'clsx';

type IProps = {
  ToggleSideBar: () => void;
  handleProfileMenuOpen: (event: any) => void;
  handleProfileMenuClose: () => void;
  onLogout: () => void;
  isProfileMenuOpen: boolean;
  isSidebarOpen: boolean;
  anchorEl: any;
};

const DashboardHeader = ({
  ToggleSideBar,
  handleProfileMenuOpen,
  handleProfileMenuClose,
  isProfileMenuOpen,
  isSidebarOpen,
  onLogout,
  anchorEl,
}: IProps): JSX.Element => {
  const classes = AppBarUI();
  return (
    <AppBar
      position='fixed'
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isSidebarOpen,
      })}
    >
      <Toolbar
        className={clsx(classes.toolBar, {
          [classes.toolBarSingleElement]: isSidebarOpen,
        })}
      >
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={ToggleSideBar}
          edge='start'
          className={clsx(classes.menuButton, isSidebarOpen && classes.hide)}
        >
          <MenuIcon />
        </IconButton>

        <div>
          <IconButton
            size='medium'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleProfileMenuOpen}
            className={clsx(classes.menuButton)}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              className: classes.menuContainer,
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isProfileMenuOpen}
            onClose={handleProfileMenuClose}
          >
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
