import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
/** Components */
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { IStory } from 'interfaces/stories';
import { ArrowUpward, ArrowDownward, Search } from '@material-ui/icons';
import { Input } from 'components/shared';
import { DeleteButton, ViewLinkButton } from 'components/buttons';
import MessageModal from 'components/message-modal/message-modal';
/* Utils */
import sortCompareValues from 'utils/sortCompareValues';
import formatDate from 'utils/formatDate';
/* UI */
import UserStoriesUI from './user-stories-ui';
import clsx from 'clsx';
import { bytesToMB } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { getUserStories } from '../../../redux/features/users/usersSlice';

const sort = (by: keyof IStory | '', isAscending: boolean, array: any) => {
  switch (by) {
    case 'title':
    case 'createdAt':
    case 'updatedAt':
    case 'cuts':
      return array.sort(sortCompareValues(by, isAscending));
    default:
      return array;
  }
};

interface IHeaderCell {
  label: string;
  alignment: 'left' | 'center' | 'right';
  hasSort: boolean;
  sortValue: string | null | undefined;
  width?: string;
}

const getHeaderCells = (): IHeaderCell[] => {
  return [
    {
      label: 'Cover',
      alignment: 'left',
      hasSort: false,
      sortValue: '',
    },
    {
      label: 'Title',
      alignment: 'left',
      hasSort: true,
      sortValue: 'title',
    },
    {
      label: 'Cuts',
      alignment: 'center',
      hasSort: true,
      sortValue: 'cuts',
    },
    {
      label: 'Size',
      alignment: 'center',
      hasSort: false,
      sortValue: '',
    },
    {
      label: 'Status',
      alignment: 'center',
      hasSort: true,
      sortValue: 'status',
    },
    {
      label: 'Created',
      alignment: 'right',
      hasSort: true,
      sortValue: 'createdAt',
    },
    {
      label: 'Updated',
      alignment: 'right',
      hasSort: true,
      sortValue: 'updatedAt',
    },
    {
      label: 'Actions',
      alignment: 'right',
      hasSort: false,
      sortValue: '',
      width: '90px',
    },
  ];
};

interface ITableHeader {
  cells: IHeaderCell[];
  activeSort: string;
  isAscending?: boolean;
  onSort?: (params?: any) => any;
}

const TableHeader = ({ activeSort, cells, isAscending, onSort }: ITableHeader): JSX.Element => {
  const classes = UserStoriesUI();
  const getSortingIcon = () => {
    if (typeof isAscending === 'boolean' && onSort) {
      return isAscending ? (
        <ArrowDownward className={classes.sortingIcon} />
      ) : (
        <ArrowUpward className={classes.sortingIcon} />
      );
    }

    return '';
  };

  return (
    <TableHead>
      <TableRow>
        {cells.map((cell) => {
          return (
            <TableCell
              classes={{
                root: clsx(classes.tableCell, {
                  [classes.clickableCell]: cell.hasSort,
                }),
              }}
              key={cell.label}
              align={cell?.alignment}
              width={cell?.width}
              {...(cell.hasSort && { onClick: onSort && onSort(cell.sortValue) })}
            >
              {cell.hasSort && activeSort === cell.sortValue && getSortingIcon()}
              {cell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

interface IUserStories {
  userId?: string;
  areFetched: boolean;
  onFetchCallback: () => void;
  onDeleteStory: (id: string, callback?: () => void) => void;
}

export default function UserStories({ userId, areFetched, onFetchCallback, onDeleteStory }: IUserStories): JSX.Element {
  const classes = UserStoriesUI();
  const dispatch = useDispatch();
  const stories = useSelector((state: RootState) => state.users.userStories);
  const [activeSort, setActiveSort] = useState<keyof IStory | ''>('');
  const [isAscending, setIsAscending] = useState(true);
  const [searchedStory, setSearchedStory] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedStoryId, setSelectedStoryId] = useState('');
  const [isDeleteStoryModalOpen, setIsDeleteStoryModalOpen] = useState(false);

  useEffect(() => {
    if (
      (userId && stories?.length === 0 && !areFetched) ||
      (userId && stories?.length && stories?.[0]?.author !== userId && !areFetched)
    ) {
      dispatch(getUserStories(userId));
      onFetchCallback();
    }
  }, [userId, stories, dispatch, areFetched, onFetchCallback]);

  const onDeleteStoryModalToggle = useCallback(
    (id?: string) => () => {
      if (id) {
        setSelectedStoryId(id);
      }
      setIsDeleteStoryModalOpen(!isDeleteStoryModalOpen);
    },
    [isDeleteStoryModalOpen],
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const onSearchStory = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchedStory(event.target.value);
    setPage(0);
  }, []);

  const sortedStories = useMemo(() => {
    if (stories) {
      return sort(
        activeSort,
        isAscending,
        [...stories].filter((story) => story.title.toLowerCase().includes(searchedStory.toLowerCase())),
      );
    }
    return [];
  }, [activeSort, isAscending, stories, searchedStory]);

  const onTitleSort = useCallback(
    (title: keyof IStory) => () => {
      if (activeSort === title) {
        setIsAscending(!isAscending);
        return;
      }
      setIsAscending(true);
      setActiveSort(title);
    },
    [isAscending, activeSort],
  );

  return (
    <Box className={classes.box}>
      <Box mb={2} maxWidth={'200px'}>
        <Input
          labelText="Story title"
          id="story-title"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            autoComplete: 'off',
            value: searchedStory,
            name: 'story-title',
            type: 'text',
            onChange: onSearchStory,
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TableContainer classes={{ root: classes.container }}>
        <Table className={classes.table} size="small">
          <TableHeader
            activeSort={activeSort}
            isAscending={isAscending}
            cells={getHeaderCells()}
            onSort={onTitleSort}
          />
          <TableBody>
            {sortedStories.length === 0 && (
              <TableRow>
                <TableCell classes={{ root: classes.tableCell }} align={'center'} colSpan={7}>
                  No stories
                </TableCell>
              </TableRow>
            )}
            {sortedStories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: IStory) => (
              <TableRow key={row._id}>
                <TableCell classes={{ root: classes.tableCell }}>
                  <img
                    className={classes.image}
                    src={row?.posterPortrait3x4Url !== '' ? row.posterPortrait3x4Url : row?.cover?.url}
                    alt={row.title}
                  />
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }} align="left">
                  {row.title}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }} align="center">
                  {row.cuts.length}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }} align="center">
                  {bytesToMB(row.fileSize ?? 0)}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }} align="center">
                  {row.status}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }} align="right">
                  {formatDate(row.createdAt)}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }} align="right">
                  {formatDate(row.updatedAt)}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }} align="right">
                  <ViewLinkButton className={classes.action} to={`/stories/${row._id}`} />
                  <DeleteButton className={classes.action} onClick={onDeleteStoryModalToggle(row._id)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          classes={{ root: classes.pagination }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedStories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <MessageModal
          isOpen={isDeleteStoryModalOpen}
          message={`Are you sure you want to delete the selected story?`}
          onAccept={onDeleteStory(selectedStoryId, onDeleteStoryModalToggle())}
          onCancel={onDeleteStoryModalToggle()}
        />
      </TableContainer>
    </Box>
  );
}
