import React from 'react';
/* Router */
import { Link } from 'react-router-dom';
/** Components */
import { Visibility } from '@material-ui/icons';
/** UI */
import ViewButtonUI from './view-button-ui';
import clsx from 'clsx';

interface IViewLinkButton {
  className?: string;
  to: string;
}

const ViewLinkButton = ({ className, to }: IViewLinkButton): JSX.Element => {
  const classes = ViewButtonUI();
  return (
    <Link
      to={to}
      className={clsx(classes.button, {
        [className as string]: className,
      })}
    >
      <Visibility className={classes.icon} />
    </Link>
  );
};

export default ViewLinkButton;
