import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadingFinished, loadingStarted } from '../loader/loaderSlice';
import { AppThunk } from '../../store';
import { AxiosResponse } from 'axios';
/** Utils */
import api from '../../../utils/api';
import qs from 'qs';
/** Constants */
import { DOMAIN_URLS } from '../../../config/urls';
import { DOMAINS_REDUCER } from '../../constants';
/** Interfaces */
import { ITableHeaderColumn } from 'interfaces/table/table-column';
import { IRequestListOptions } from 'interfaces/request-options';
import { batch } from 'react-redux';

import { IDomain } from 'interfaces/domain';

type IAddSortOptionPayload = {
  option: string;
  value: string;
};

interface IDomainList {
  domains: IDomain[];
  totalCount: number;
  hasNext: boolean;
  limit: number;
  offset: number;
  options: IRequestListOptions;
}

interface IDomainsState {
  domain: IDomain | null;
  domainList: IDomainList;
  isDomainListLoading: boolean;
}

const initialState: IDomainsState = {
  domain: null,
  domainList: {
    domains: [],
    totalCount: 0,
    hasNext: false,
    limit: 30,
    offset: 0,
    options: {
      sort: {},
      search: {},
    },
  },
  isDomainListLoading: false,
};

const domainsSlice = createSlice({
  name: DOMAINS_REDUCER,
  initialState,
  reducers: {
    setDomain: (state, action: PayloadAction<IDomain | null>) => {
      state.domain = action.payload;
    },
    setDomains: (state, action: PayloadAction<IDomain[]>) => {
      state.domainList.domains = action.payload;
    },
    setDomainListData: (state, action: PayloadAction<IDomainList>) => {
      state.domainList = { ...state.domainList, ...action.payload };
    },
    setDomainListSortOption: (state, action: PayloadAction<IAddSortOptionPayload>) => {
      const { option, value } = action.payload;
      state.domainList.options.sort = { [option]: value };
    },
    setDomainListSearchOption: (state, action: PayloadAction<any>) => {
      state.domainList.options.search = action.payload;
    },
    clearDomainListSortOption: (state) => {
      state.domainList.options.sort = initialState.domainList.options.sort;
    },
    clearDomainListOptions: (state) => {
      state.domainList.options = initialState.domainList.options;
    },
    toggleIsDomainListLoading: (state, action: PayloadAction<boolean>) => {
      state.isDomainListLoading = action.payload;
    },
  },
});

// Action
export const {
  setDomain,
  setDomains,
  setDomainListData,
  setDomainListSortOption,
  clearDomainListSortOption,
  toggleIsDomainListLoading,
  setDomainListSearchOption,
  clearDomainListOptions,
} = domainsSlice.actions;
export default domainsSlice.reducer;

export const getDomainList = (limit: number, offset: number, options: IRequestListOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(toggleIsDomainListLoading(true));
    const queryParams = qs.stringify(options);
    const domainListResponse: AxiosResponse<IDomainList> = await api.get(
      DOMAIN_URLS.DOMAIN_LIST(offset, limit, queryParams),
    );
    dispatch(setDomainListData(domainListResponse?.data));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(toggleIsDomainListLoading(false));
  }
};

export const setSortOption = (column: ITableHeaderColumn): AppThunk => (dispatch, getState) => {
  const {
    domains: {
      domainList: {
        options: { sort },
      },
    },
  } = getState();
  const option = column.id;

  if (sort[column.id]) {
    /** Toggle ASC or DESC value */
    const value = sort[column.id] === 'asc' ? 'desc' : 'asc';
    return dispatch(setDomainListSortOption({ option, value }));
  }

  return batch(() => {
    dispatch(clearDomainListSortOption());
    dispatch(setDomainListSortOption({ option, value: 'asc' }));
  });
};

export const getDomain = (id: string, successCallback?: (params: any) => void): AppThunk => async (dispatch) => {
  try {
    dispatch(loadingStarted());
    const domainResponse: AxiosResponse<IDomain> = await api.get(DOMAIN_URLS.GET_DOMAIN(id));
    batch(() => {
      dispatch(setDomain(domainResponse?.data));
      successCallback && successCallback(domainResponse?.data);
    });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(loadingFinished());
  }
};
