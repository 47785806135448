import React, { ReactNode } from 'react';
/** Components */
import { Box } from '@material-ui/core';
/** UI */
import DetailsNavWrapperUI from './details-nav-wrapper-ui';

interface IDetailsNavWrapper {
  children: ReactNode;
}

const DetailsNavWrapper = ({ children }: IDetailsNavWrapper): JSX.Element => {
  const classes = DetailsNavWrapperUI();

  return (
    <Box className={classes.wrapper} mb={4} display={'flex'} justifyContent={'space-between'} alignItems="flex-start">
      {children}
    </Box>
  );
};

export default DetailsNavWrapper;
