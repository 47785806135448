import { combineReducers } from '@reduxjs/toolkit';
/** CONSTANTS */
import {
  AUTH_REDUCER,
  DOMAINS_REDUCER,
  LOADER_REDUCER,
  SNIPPETS_REDUCER,
  STORIES_REDUCER,
  USERS_REDUCER,
  WORKSPACES_REDUCER,
} from './constants';
/* Auth */
import authReducer from './features/auth/authSlice';
/** Loader */
import loaderReducer from './features/loader/loaderSlice';
/** Users */
import usersReducer from './features/users/usersSlice';
/** Snippets */
import snippetsReducer from './features/snippets/snippetsSlice';
/** Stories */
import storiesReducer from './features/stories/storiesSlice';
/** Domains */
import domainsReducer from './features/domains/domainsSlice';
/** Workspaces */
import workspacesReducer from './features/workspaces/workspacesSlice';

const appReducer = combineReducers({
  [AUTH_REDUCER]: authReducer,
  [LOADER_REDUCER]: loaderReducer,
  [USERS_REDUCER]: usersReducer,
  [SNIPPETS_REDUCER]: snippetsReducer,
  [STORIES_REDUCER]: storiesReducer,
  [DOMAINS_REDUCER]: domainsReducer,
  [WORKSPACES_REDUCER]: workspacesReducer,
});

export default appReducer;
