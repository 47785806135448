import React, { ElementType } from 'react';
/** Components */
import { Typography } from '@material-ui/core';
/** UI */
import InfoBoxValueUI from './info-box-value-ui';

interface IInfoBoxValue {
  component?: ElementType<any>;
  text?: string | '' | Date | number;
}

const InfoBoxValue = ({ text = '', component = 'p' }: IInfoBoxValue): JSX.Element => {
  const classes = InfoBoxValueUI();
  return (
    <Typography className={classes.field} component={component} variant={'subtitle2'}>
      {text}
    </Typography>
  );
};

export default InfoBoxValue;
