import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
/** Router */
import { useHistory } from 'react-router-dom';
/** Redux */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { deleteSnippet, getSnippet, setSnippet, updateSnippet } from 'redux/features/snippets/snippetsSlice';
/** Components */
import { DetailsNavWrapper, GridContainer, GridItem, InfoBox } from '../shared';
import { QuaternaryLinkButton } from '../buttons';
import MessageModal from '../message-modal/message-modal';
import SnippetDetailsForm from './snippet-details-form/snippet-details-form';
/** Constants */
import { SNIPPET_STATUSES } from 'constants/snippets';
/** Interfaces */
import { ISnippetSingle } from 'interfaces/snippet';
import DangerButton from '../buttons/danger-button/danger-button';

const SnippetDetails = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const snippet = useSelector((state: RootState) => state.snippets.snippet);
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [snippetForm, setSnippetForm] = useState<ISnippetSingle | null>(null);

  const onSnippetFormInit = useCallback((snippet: ISnippetSingle) => {
    setSnippetForm(snippet);
  }, []);

  const onDeleteSnippet = useCallback(() => {
    if (snippet) {
      dispatch(
        deleteSnippet(snippet?._id, () => {
          history.push('/snippets');
        }),
      );
    }
  }, [dispatch, history, snippet]);

  const onDeleteModalToggle = useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (snippetForm) {
        setSnippetForm({
          ...snippetForm,
          [event.target.name as string]: event.target.value as string,
        });
      }
    },
    [snippetForm],
  );

  const onStatusSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (snippetForm) {
        setSnippetForm({
          ...snippetForm,
          status: event.target.value,
        });
      }
    },
    [snippetForm],
  );

  const onUpdateSnippet = useCallback(
    (manuallyMutatedFields?: any) => {
      if (snippet && snippetForm) {
        const newSnippetData = {
          ...snippetForm,
          ...manuallyMutatedFields,
        };
        dispatch(updateSnippet(snippet._id, newSnippetData));
      }
    },
    [dispatch, snippet, snippetForm],
  );

  const onUpdateSnippetSettings = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onUpdateSnippet();
    },
    [onUpdateSnippet],
  );

  useEffect(() => {
    const startPointOfSnippetId = history.location.pathname.lastIndexOf('/') + 1;
    const snippetId = history.location.pathname.substr(startPointOfSnippetId);
    dispatch(getSnippet(snippetId, onSnippetFormInit));

    return () => {
      dispatch(setSnippet(null));
    };
  }, [dispatch, history, onSnippetFormInit]);

  if (isLoading || !snippet) {
    return (
      <GridContainer>
        <GridItem gridProps={{ xs: 12 }}>
          <InfoBox>
            <span />
          </InfoBox>
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      <GridItem gridProps={{ xs: 12 }}>
        <DetailsNavWrapper>
          <QuaternaryLinkButton to={'/snippets'} text={'See all'} />

          <MessageModal
            isOpen={isDeleteModalOpen}
            message={`Are you sure you want to delete ${snippet?.name}?`}
            onAccept={onDeleteSnippet}
            onCancel={onDeleteModalToggle}
          />
          <DangerButton text={'Delete snippet'} onClick={onDeleteModalToggle} />
        </DetailsNavWrapper>
      </GridItem>

      <GridItem gridProps={{ xs: 12 }}>
        <SnippetDetailsForm
          formInfo={snippetForm}
          statuses={SNIPPET_STATUSES}
          onStatusSelect={onStatusSelect}
          onInputChange={onInputChange}
          onSubmit={onUpdateSnippetSettings}
        />
      </GridItem>
    </GridContainer>
  );
};

export default SnippetDetails;
