/** COMPONENTS */
import { IconButton, Box } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import TableSearch from '../table-search/table-search';
/** UI */
import TableToolBarUI from './table-toolbar-ui';
/** INTERFACES */
import { IUserListSortOptions } from 'interfaces/user-list';
import { ReactNode } from 'react';

interface IProps {
  maxWidth?: string;
  sortOptions: IUserListSortOptions;
  clearOptions?: () => void;
  searchByValue: string | number;
  searchByOptions?: any;
  searchValue: string | number;
  handleSearchValueChange: any;
  handleSearchParamChange: any;
  children?: ReactNode;
}

const TableToolBar = ({
  maxWidth = '1024px',
  sortOptions,
  searchByValue,
  searchByOptions,
  searchValue,
  clearOptions,
  handleSearchValueChange,
  handleSearchParamChange,
  children,
}: IProps): JSX.Element => {
  const classes = TableToolBarUI({ maxWidth });

  return (
    <div className={classes.root}>
      <Box mr={2}>
        <TableSearch
          inputValue={searchValue}
          dropdownPosition={'right'}
          dropdownWidth={'200px'}
          selectOption={searchByValue}
          options={searchByOptions}
          onSelect={handleSearchParamChange}
          onInputChange={handleSearchValueChange}
        />
      </Box>

      {Object.entries(sortOptions).length !== 0 && (
        <Box className={classes.clearSort} display={'flex'} alignItems={'center'} mr={2}>
          <span>Clear sorting</span>

          <IconButton
            className={classes.clearButton}
            onClick={clearOptions}
            aria-label="delete"
            style={{ color: 'var(--primary)' }}
          >
            <Clear className={classes.clearSortIcon} />
          </IconButton>
        </Box>
      )}

      {children && <div className={classes.childrenWrapper}>{children}</div>}
    </div>
  );
};

export default TableToolBar;
