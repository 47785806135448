import React, { ReactNode } from 'react';
/** COMPONENTS */
import { Grid, GridProps } from '@material-ui/core';
/** UI */
import GridContainerUI from './grid-container-ui';

type IProps = {
  children: ReactNode;
  className?: string;
  gridProps?: GridProps;
};

const GridContainer = ({ children, className, gridProps = {} }: IProps): JSX.Element => {
  const classes = GridContainerUI();
  return (
    <Grid container {...gridProps} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  );
};

export default GridContainer;
