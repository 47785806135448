import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  form: {
    width: '100%',
    maxWidth: '950px',
  },
  infoBox: {
    margin: '0 0 30px',
  },
});
