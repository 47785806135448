/** REDUX */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'redux/store';
import { loadingFinished, loadingStarted } from '../loader/loaderSlice';
/** CONSTANTS */
import { STORY_URLS, USER_URLS } from 'config/urls';
import { USERS_REDUCER } from 'redux/constants';
/** UTILS */
import { AxiosResponse } from 'axios';
import qs from 'qs';
import api from 'utils/api';
/** INTERFACES */
import { IUser, IRegisterUser, IRole } from 'interfaces/user';
import { ITableHeaderColumn } from 'interfaces/table/table-column';
import { IStory } from 'interfaces/stories';
import { IUserListSortOptions, IUserListSearchOptions } from 'interfaces/user-list';
import { batch } from 'react-redux';
import { IAddSortOptionPayload } from 'interfaces/table-search';
import { IWorkspace } from 'interfaces/workspaces';

interface IUserListOptions {
  sort: IUserListSortOptions | any;
  search: IUserListSearchOptions;
}

interface IUserList {
  users: IUser[];
  totalCount: number;
  hasNext: boolean;
  limit: number;
  offset: number;
  options: IUserListOptions;
}

interface IUserStatistics {
  usageType: {
    AUTOMATION: number;
    CURIOSITY: number;
    OTHER: number;
    SOCIAL_MEDIA: number;
    WEB_STORIES: number;
  };
  companySize: {
    SMALL: number;
    MEDIUM: number;
    LARGE: number;
    EXTRA_LARGE: number;
  };
}

interface IUserListMeta {
  usersCount: number;
  formsFilled: number;
  userStatistics: IUserStatistics;
}

interface IUserMeta {
  userStoriesCount: number;
  workspacesCount: number;
}

interface IUsersState {
  user: IUser | null;
  userStories: IStory[];
  userWorkspaces: IWorkspace[];
  userList: IUserList;
  isUserListLoading: boolean;
  roles: IRole[] | [];
  userListMeta: IUserListMeta;
  userMeta: IUserMeta;
}

const initialState: IUsersState = {
  user: null,
  userStories: [],
  userWorkspaces: [],
  userList: {
    users: [],
    totalCount: 0,
    hasNext: false,
    limit: 30,
    offset: 0,
    options: {
      sort: {},
      search: {},
    },
  },
  userListMeta: {
    usersCount: 0,
    formsFilled: 0,
    userStatistics: {
      usageType: {
        AUTOMATION: 0,
        CURIOSITY: 0,
        OTHER: 0,
        SOCIAL_MEDIA: 0,
        WEB_STORIES: 0,
      },
      companySize: {
        SMALL: 0,
        MEDIUM: 0,
        LARGE: 0,
        EXTRA_LARGE: 0,
      },
    },
  },
  userMeta: {
    userStoriesCount: 0,
    workspacesCount: 0,
  },
  isUserListLoading: false,
  roles: [],
};

const usersSlice = createSlice({
  name: USERS_REDUCER,
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser | null>) => {
      state.user = action.payload;
    },
    setUserMeta: (state, action: PayloadAction<IUserMeta | null>) => {
      if (action.payload) {
        state.userMeta = action.payload;
      } else {
        state.userMeta = {
          userStoriesCount: 0,
          workspacesCount: 0,
        };
      }
    },
    setUserStories: (state, action: PayloadAction<IStory[] | []>) => {
      state.userStories = action.payload;
    },
    setUserWorkspaces: (state, action: PayloadAction<IWorkspace[]>) => {
      state.userWorkspaces = action.payload;
    },
    setUserListLimit: (state, action: PayloadAction<number>) => {
      state.userList.limit = action.payload;
    },
    setUserListOffset: (state, action: PayloadAction<number>) => {
      state.userList.offset = action.payload;
    },
    setUsers: (state, action: PayloadAction<IUser[] | []>) => {
      state.userList.users = action.payload;
    },
    setUserListMeta: (state, action: PayloadAction<IUserListMeta | null>) => {
      if (action.payload) {
        state.userListMeta = action.payload;
      } else {
        state.userListMeta = {
          usersCount: 0,
          formsFilled: 0,
          userStatistics: {
            usageType: {
              AUTOMATION: 0,
              CURIOSITY: 0,
              OTHER: 0,
              SOCIAL_MEDIA: 0,
              WEB_STORIES: 0,
            },
            companySize: {
              SMALL: 0,
              MEDIUM: 0,
              LARGE: 0,
              EXTRA_LARGE: 0,
            },
          },
        };
      }
    },
    setUserListData: (state, action: PayloadAction<IUserList>) => {
      state.userList = { ...state.userList, ...action.payload };
    },
    setUserListSortOption: (state, action: PayloadAction<IAddSortOptionPayload>) => {
      const { option, value } = action.payload;
      state.userList.options.sort = { [option]: value };
    },
    setUserListSearchOption: (state, action: PayloadAction<any>) => {
      state.userList.options.search = action.payload;
    },
    clearUserListSortOption: (state) => {
      state.userList.options.sort = initialState.userList.options.sort;
    },
    clearUserListOptions: (state) => {
      state.userList.options = initialState.userList.options;
    },
    setUserRoles: (state, action: PayloadAction<IRole[] | []>) => {
      state.roles = action.payload;
    },
    toggleIsUserListLoading: (state, action: PayloadAction<boolean>) => {
      state.isUserListLoading = action.payload;
    },
  },
});

// Action
export const {
  setUser,
  setUsers,
  setUserStories,
  setUserWorkspaces,
  setUserListData,
  setUserListLimit,
  setUserListOffset,
  clearUserListOptions,
  setUserListSortOption,
  clearUserListSortOption,
  setUserListSearchOption,
  setUserRoles,
  toggleIsUserListLoading,
  setUserListMeta,
  setUserMeta,
} = usersSlice.actions;
export default usersSlice.reducer;

export const updateUser =
  (id: string, user: IUser): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const userResponse: AxiosResponse<IUser> = await api.put(USER_URLS.UPDATE_USER(id), {
        ...user,
      });
      batch(() => {
        dispatch(setUser(userResponse?.data));
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const getUserList =
  (limit: number, offset: number, options: IUserListOptions): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleIsUserListLoading(true));
      const queryParams = qs.stringify(options);
      const userListResponse: AxiosResponse<IUserList> = await api.get(USER_URLS.USER_LIST(offset, limit, queryParams));
      dispatch(setUserListData(userListResponse?.data));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(toggleIsUserListLoading(false));
    }
  };

export const getUserListMeta = (): AppThunk => async (dispatch) => {
  try {
    dispatch(toggleIsUserListLoading(true));
    const userListMetaResponse: AxiosResponse<IUserListMeta> = await api.get(USER_URLS.USER_LIST_META);
    dispatch(setUserListMeta(userListMetaResponse?.data));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(toggleIsUserListLoading(false));
  }
};

export const setSortOption =
  (column: ITableHeaderColumn): AppThunk =>
  (dispatch, getState) => {
    const {
      users: {
        userList: {
          options: { sort },
        },
      },
    } = getState();
    const option = column.id;

    if (sort[column.id]) {
      /** Toggle ASC or DESC value */
      const value = sort[column.id] === 'asc' ? 'desc' : 'asc';
      return dispatch(setUserListSortOption({ option, value }));
    }

    return batch(() => {
      dispatch(clearUserListSortOption());
      dispatch(setUserListSortOption({ option, value: 'asc' }));
    });
  };

export const getUserStories =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const { data }: AxiosResponse<IStory[]> = await api.get(USER_URLS.GET_USER_STORIES(id));
      batch(() => {
        dispatch(setUserStories(data));
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const getUserWorkspaces =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const { data }: AxiosResponse<IWorkspace[]> = await api.get(USER_URLS.GET_USER_WORKSPACES(id));
      batch(() => {
        dispatch(setUserWorkspaces(data));
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const getUserMeta =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const { data }: AxiosResponse<IUserMeta> = await api.get(USER_URLS.GET_USER_META(id));

      dispatch(setUserMeta(data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const getUser =
  (id: string, successCallback?: (params: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const userResponse: AxiosResponse<IUser> = await api.get(USER_URLS.GET_USER(id));
      batch(() => {
        dispatch(setUser(userResponse?.data));
        successCallback && successCallback(userResponse?.data?.user);
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const getUserRoles =
  (successCallback?: (params?: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const userRolesResponse: AxiosResponse<IRole[]> = await api.get(USER_URLS.GET_USER_ROLES);
      dispatch(setUserRoles(userRolesResponse?.data));
      successCallback && successCallback(userRolesResponse?.data);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const deleteUser =
  (id: string, successCallback?: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const users = state.users.userList.users;
      dispatch(loadingStarted());
      await api.delete(USER_URLS.DELETE_USER(id));
      dispatch(setUsers(users.filter((user) => user._id !== id)));
      successCallback && successCallback();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const registerUser =
  (userData: IRegisterUser, successCallback?: (params?: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const userResponse: AxiosResponse<IUser> = await api.post(USER_URLS.REGISTER_USER, userData);
      batch(() => {
        dispatch(setUser(userResponse?.data?.user));
        dispatch(setUserStories(userResponse?.data?.userStories));
        successCallback && successCallback(userResponse?.data?.user._id);
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const deleteUserStory =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      const stories = state[USERS_REDUCER].userStories.filter((story) => story._id !== id);
      dispatch(loadingStarted());
      await api.delete(STORY_URLS.DELETE_STORY(id));
      dispatch(setUserStories(stories));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };
