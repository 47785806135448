import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  link: {
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.57,
    letterSpacing: '0.00714em',
    color: 'var(--white)',
    textDecoration: 'none',
  },
});
