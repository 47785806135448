import LoginIconUI from './login-icon-ui';

const LoginIcon = (): JSX.Element => {
  const classes = LoginIconUI();
  return (
    <a className={classes.logoLink} target={'_blank'} rel='noopener noreferrer'>
      <img
        className={classes.logoImg}
        src={'https://cutnut.net/wp-content/uploads/2018/11/Cutnut_Logo_White.png'}
      ></img>
    </a>
  );
};

export default LoginIcon;
