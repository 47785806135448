/** COMPONENTS */
import { Drawer, IconButton, Divider } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import SidebarList from './sidebar-list/sidebar-list';
/** UI */
import { useTheme } from '@material-ui/core/styles';
import SideBarListUI from './sidebar-ui';
/** INTERFACES */
import { ISidebarListItem } from 'interfaces/sidebar-list-item';

type IProps = {
  sidebarListItems: ISidebarListItem[];
  isSidebarOpen: boolean;
  handleSidebarClose: () => void;
};

const Sidebar = ({ sidebarListItems, isSidebarOpen, handleSidebarClose }: IProps): JSX.Element => {
  const classes = SideBarListUI();
  const theme = useTheme();

  return (
    <Drawer
      className={classes.drawer}
      variant='persistent'
      anchor='left'
      open={isSidebarOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleSidebarClose}>
          {theme.direction === 'ltr' ? (
            <ChevronLeft style={{ color: 'var(--primary)' }} />
          ) : (
            <ChevronRight style={{ color: 'var(--primary)' }} />
          )}
        </IconButton>
      </div>
      <Divider />
      <SidebarList sidebarListItems={sidebarListItems} />
      <Divider />
    </Drawer>
  );
};

export default Sidebar;
