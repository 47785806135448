import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  maxWidth: string;
}

export default makeStyles<Theme, StyleProps>(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0',
      backgroundColor: 'transparent',
      margin: '0 0 15px',
      maxWidth: ({ maxWidth }) => (maxWidth ? maxWidth : '100%'),
    },
    childrenWrapper: {
      justifySelf: 'flex-end',
      marginLeft: 'auto',
    },
    clearSort: {
      color: 'var(--white)',
    },
    clearButton: {
      marginLeft: '8px',
      padding: '6px',
      '&:hover': {
        background: 'var(--shade-500)',
      },
    },
    clearSortIcon: {
      fontSize: '16px',
    },
  }),
);
