import moment from 'moment';

const formatDate = (date: Date | string, showTime?: boolean): string | Date => {
  if (date?.toString()?.length > 0) {
    const fullDate = new Date(date);
    const formattedDate = moment(fullDate).format('DD.MM.YYYY');

    if (showTime) {
      return moment(fullDate).format('DD.MM.YYYY HH:mm:SS');
    }
    return formattedDate;
  }

  return date;
};

export default formatDate;

export const generateDate = (date: Date): string => {
  return date.toISOString();
};

export const revertToJSDate = (date?: string): Date => {
  if (!date) {
    return new Date();
  }

  return new Date(date);
};
