import React from 'react';
/** Components */
import { FormControl, InputLabel, MenuItem, Select as MaterialSelect } from '@material-ui/core';
/** Interface */
import { IOption } from 'interfaces/select';
/** UI */
import SelectUI from './select-ui';
import clsx from 'clsx';

interface ISelect {
  id?: string;
  value: string | number;
  label?: string | number;
  name: string;
  options: IOption[];
  fieldPadding?: string;
  errorText?: string;
  onChange: (params?: any) => void;
}

const Select = ({ id, value, label, options, name, fieldPadding, onChange, errorText }: ISelect): JSX.Element => {
  const classes = SelectUI({ fieldPadding });

  return (
    <FormControl
      className={clsx(classes.formControl, {
        [classes.error]: errorText && errorText.length > 0,
      })}
    >
      {label && (
        <InputLabel className={classes.label} shrink id={name}>
          {label}
        </InputLabel>
      )}

      <MaterialSelect
        classes={{ root: classes.root, icon: classes.icon, select: classes.select }}
        MenuProps={{ classes: { paper: classes.menu } }}
        labelId={name}
        id={id}
        value={value}
        onChange={onChange}
      >
        {options.map((option: IOption) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </MaterialSelect>
      {errorText && errorText.length > 0 && (
        <div className={classes.selectErrorContainer}>
          <span className={classes.selectErrorSpan}>{errorText}</span>
        </div>
      )}
    </FormControl>
  );
};

export default Select;
