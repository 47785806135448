import { useEffect } from 'react';
import { GridItem, GridContainer } from '../shared';
import { Box } from '@material-ui/core';
import AnalyticsCard from '../shared/analytics-card/analytics-card';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getWorkspaceListMeta, setWorkspaceListMeta } from '../../redux/features/workspaces/workspacesSlice';

const WorkspaceListMeta = (): JSX.Element => {
  const dispatch = useDispatch();
  const { workspacesCount, workspacesTrialCount } = useSelector(
    (state: RootState) => state.workspaces.workspaceListMeta,
  );

  useEffect(() => {
    dispatch(getWorkspaceListMeta());

    return () => {
      dispatch(setWorkspaceListMeta(null));
    };
  }, [dispatch]);

  return (
    <GridContainer>
      <GridItem
        gridProps={{ xs: 12, style: { display: 'flex', columnGap: '12px', flexWrap: 'wrap', margin: '0 0 16px' } }}
      >
        <Box marginBottom={'16px'}>
          <AnalyticsCard label="Total Workspaces" value={workspacesCount} />
        </Box>

        <Box marginBottom={'16px'}>
          <AnalyticsCard label="Trial Workspaces" value={workspacesTrialCount} />
        </Box>
      </GridItem>
    </GridContainer>
  );
};

export default WorkspaceListMeta;
