import { createSlice } from '@reduxjs/toolkit';
/** CONSTANTS */
import { LOADER_REDUCER } from 'redux/constants';

interface ILoaderState {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
} as ILoaderState;

const loaderSlice = createSlice({
  name: LOADER_REDUCER,
  initialState,
  reducers: {
    loadingStarted(state) {
      state.isLoading = true;
    },
    loadingFinished(state) {
      state.isLoading = false;
    },
  },
});

export const { loadingStarted, loadingFinished } = loaderSlice.actions;
export default loaderSlice.reducer;
