import { useMemo, useCallback } from 'react';
/** COMPONENTS */
import { TableBody as TableBodyContainer, TableRow, TableCell, CircularProgress } from '@material-ui/core';
import ActionsWrapper from './actions-wrapper/actions-wrapper';
import { DeleteButton, ViewLinkButton } from 'components/buttons';
import Row from './table-row/table-row';
/** INTERFACES */
import { ITableRow, ITableCell } from 'interfaces/table/table-row';
import { ITableData } from 'interfaces/table/table-data';
/** UI */
import TableUI from './../table-ui';

interface IProps {
  colSpan: number;
  rowsPerPage: number;
  withActions: boolean;
  data: ITableData;
  tableRowsData: ITableRow[];
  toggleDeleteModal: () => void;
  setEntityId: (id: string) => void;
  isLoading?: boolean;
}

const TableBody = ({
  colSpan,
  rowsPerPage,
  data,
  tableRowsData,
  withActions,
  toggleDeleteModal,
  setEntityId,
  isLoading,
}: IProps): JSX.Element => {
  const classes = TableUI({});
  const emptyRows = useMemo(() => {
    if (!data) {
      return 0;
    }
    return rowsPerPage - data.length;
  }, [rowsPerPage, data]);

  const generateRows = useCallback(() => {
    return tableRowsData.map((rowData: ITableRow, index: number) => {
      const { cells, actionCellProps, isSelected, callback } = rowData;
      let rowCells = cells;

      if (withActions) {
        const children = actionCellProps ? (
          <ActionsWrapper>
            <ViewLinkButton to={actionCellProps.detailsPageLink} />
            {actionCellProps && typeof actionCellProps.deleteEntityId !== 'undefined' && (
              <DeleteButton
                onClick={() => {
                  if (actionCellProps.deleteEntityId) {
                    toggleDeleteModal();
                    setEntityId(actionCellProps.deleteEntityId);
                  }
                }}
              />
            )}
          </ActionsWrapper>
        ) : null;
        const actionCell: ITableCell = {
          align: actionCellProps?.align || 'right',
          width: 100,
          children,
        };

        rowCells = [...rowCells, actionCell];
      }

      return <Row key={index} cells={rowCells} isSelected={isSelected} onClick={callback} />;
    });
  }, [tableRowsData, withActions, toggleDeleteModal, setEntityId]);

  return (
    <TableBodyContainer>
      {generateRows()}
      {isLoading && (
        <TableRow style={{ minHeight: 50 }}>
          <TableCell className={classes.tableCell} align={'center'} colSpan={colSpan}>
            <CircularProgress size={30} className={classes.spinner} />
          </TableCell>
        </TableRow>
      )}
    </TableBodyContainer>
  );
};

export default TableBody;
