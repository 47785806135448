function compareValues(key: string, isAscending?: boolean) {
  return function innerSort(a: any, b: any) {
    let varA = '';
    let varB = '';
    if (typeof a[key] === 'string') {
      varA = a[key].toUpperCase();
      varB = b[key].toUpperCase();
    } else if (Array.isArray(a[key])) {
      varA = a[key].length;
      varB = b[key].length;
    } else {
      varA = a[key];
      varB = b[key];
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    if (typeof isAscending !== 'boolean') {
      return comparison;
    }
    return !isAscending ? comparison * -1 : comparison;
  };
}

export default compareValues;
