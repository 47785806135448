import { batch } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadingFinished, loadingStarted } from '../loader/loaderSlice';
import { AppThunk } from '../../store';
import { AxiosResponse } from 'axios';
/** Utils */
import api from '../../../utils/api';
import qs from 'qs';
/** Constants */
import { WORKSPACE_URLS } from '../../../config/urls';
import { WORKSPACES_REDUCER } from '../../constants';
/** Interfaces */
import { ITableHeaderColumn } from 'interfaces/table/table-column';
import { IRequestListOptions } from 'interfaces/request-options';
import { IWorkspace, LoadStrategy, WorkspaceTypes } from 'interfaces/workspaces';

type IAddSortOptionPayload = {
  option: string;
  value: string;
};

interface IWorkspaceList {
  workspaces: IWorkspace[];
  totalCount: number;

  hasNext: boolean;
  limit: number;
  offset: number;
  options: IRequestListOptions;
}

interface IWorkspaceListMeta {
  workspacesCount: number;
  workspacesTrialCount: number;
}

interface IWorkspaceMeta {
  numberOfStories: number;
  numberOfPublishedStories: number;
  cutsCount: number;
}

interface IWorkspacesState {
  workspace: IWorkspace | null;
  workspaceList: IWorkspaceList;
  workspaceListMeta: IWorkspaceListMeta;
  isWorkspaceListLoading: boolean;
  workspaceTypeDraft: null | WorkspaceTypes;
  workspaceTrialEndsAtDraft: null | string;
  workspaceMeta: IWorkspaceMeta;
}

const initialState: IWorkspacesState = {
  workspace: null,
  workspaceList: {
    workspaces: [],
    totalCount: 0,
    hasNext: false,
    limit: 30,
    offset: 0,
    options: {
      sort: {},
      search: {},
    },
  },
  workspaceListMeta: {
    workspacesCount: 0,
    workspacesTrialCount: 0,
  },
  workspaceMeta: {
    numberOfStories: 0,
    numberOfPublishedStories: 0,
    cutsCount: 0,
  },
  isWorkspaceListLoading: false,
  workspaceTypeDraft: null,
  workspaceTrialEndsAtDraft: null,
};

const workspacesSlice = createSlice({
  name: WORKSPACES_REDUCER,
  initialState,
  reducers: {
    setWorkspace: (state, action: PayloadAction<IWorkspace | null>) => {
      state.workspace = action.payload;
      state.workspaceTypeDraft = null;
      state.workspaceTrialEndsAtDraft = null;
    },
    setWorkspaceMeta: (state, action: PayloadAction<IWorkspaceMeta | null>) => {
      if (action.payload) {
        state.workspaceMeta = action.payload;
      } else {
        state.workspaceMeta = {
          numberOfStories: 0,
          numberOfPublishedStories: 0,
          cutsCount: 0,
        };
      }
    },
    setWorkspaces: (state, action: PayloadAction<IWorkspace[]>) => {
      state.workspaceList.workspaces = action.payload;
    },
    setWorkspaceListData: (state, action: PayloadAction<IWorkspaceList>) => {
      state.workspaceList = { ...state.workspaceList, ...action.payload };
    },
    setWorkspaceListSortOption: (state, action: PayloadAction<IAddSortOptionPayload>) => {
      const { option, value } = action.payload;
      state.workspaceList.options.sort = { [option]: value };
    },
    setWorkspaceListSearchOption: (state, action: PayloadAction<any>) => {
      state.workspaceList.options.search = action.payload;
    },
    clearWorkspaceListSortOption: (state) => {
      state.workspaceList.options.sort = initialState.workspaceList.options.sort;
    },
    clearWorkspaceListOptions: (state) => {
      state.workspaceList.options = initialState.workspaceList.options;
    },
    toggleIsWorkspaceListLoading: (state, action: PayloadAction<boolean>) => {
      state.isWorkspaceListLoading = action.payload;
    },
    setWorkspaceType: (state, action: PayloadAction<WorkspaceTypes | undefined>) => {
      if (state.workspace && action.payload) {
        if (state.workspaceTypeDraft === null) {
          state.workspaceTypeDraft = state.workspace.type;
        }

        state.workspace.type = action.payload;
      }
    },
    setWorkspaceTrialEndsAt: (state, action: PayloadAction<string | null>) => {
      if (state.workspace) {
        if (state.workspaceTrialEndsAtDraft === null) {
          state.workspaceTrialEndsAtDraft = state.workspace.trialEndsAt;
        }

        state.workspace.trialEndsAt = action.payload;
      }
    },
    setLoadStrategy: (state, action: PayloadAction<LoadStrategy>) => {
      if (state.workspace) {
        state.workspace.loadStrategy = action.payload;
      }
    },
    resetDraft: (state) => {
      if (state.workspace && state.workspaceTrialEndsAtDraft !== null) {
        state.workspace.trialEndsAt = state.workspaceTrialEndsAtDraft;
        state.workspaceTrialEndsAtDraft = null;
      }

      if (state.workspace && state.workspaceTypeDraft !== null) {
        state.workspace.type = state.workspaceTypeDraft;
        state.workspaceTypeDraft = null;
      }
    },
    setWorkspaceListMeta(state, action: PayloadAction<IWorkspaceListMeta | null>) {
      if (action.payload) {
        state.workspaceListMeta = action.payload;
      } else {
        state.workspaceListMeta = {
          workspacesCount: 0,
          workspacesTrialCount: 0,
        };
      }
    },
    toggleStoryConversion(state, action: PayloadAction<boolean>) {
      if (state.workspace) {
        state.workspace.isStoryConversionEnabled = action.payload;
      }
    },
    toggleCustomCDN(state, action: PayloadAction<boolean>) {
      if (state.workspace) {
        state.workspace.customCDN.isEnabled = action.payload;
      }
    },
    changeCDN(state, action: PayloadAction<string>) {
      if (state.workspace) {
        if (state.workspace?.customCDN?.cdns?.[0] !== undefined) {
          state.workspace.customCDN.cdns[0] = action.payload;
          return;
        }

        state.workspace?.customCDN?.cdns?.push(action.payload);
      }
    },
    changeMask(state, action: PayloadAction<string>) {
      if (state.workspace) {
        state.workspace.customCDN.mask = action.payload;
      }
    },
  },
});

// Action
export const {
  setWorkspace,
  setWorkspaceMeta,
  setWorkspaces,
  setWorkspaceListData,
  setWorkspaceListSortOption,
  clearWorkspaceListSortOption,
  toggleIsWorkspaceListLoading,
  setWorkspaceListSearchOption,
  clearWorkspaceListOptions,
  setWorkspaceType,
  setWorkspaceTrialEndsAt,
  resetDraft,
  setWorkspaceListMeta,
  toggleStoryConversion,
  toggleCustomCDN,
  changeCDN,
  changeMask,
  setLoadStrategy,
} = workspacesSlice.actions;
export default workspacesSlice.reducer;

export const updateWorkspaceData = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const id = state?.workspaces?.workspace?._id;
  const trialEndsAt = state?.workspaces?.workspace?.trialEndsAt;
  const type = state?.workspaces?.workspace?.type;
  const isStoryConversionEnabled = state?.workspaces?.workspace?.isStoryConversionEnabled;
  const customCDN = state?.workspaces?.workspace?.customCDN;
  const loadStrategy = state?.workspaces?.workspace?.loadStrategy;

  if (id) {
    dispatch(loadingStarted());
    const payload: { type: WorkspaceTypes; trialEndsAt: null | string } | any = {};
    if (type === WorkspaceTypes.PAID) {
      payload.type = type;
      payload.trialEndsAt = null;
    } else {
      payload.type = type;
      payload.trialEndsAt = trialEndsAt;
    }

    payload.isStoryConversionEnabled = isStoryConversionEnabled;
    payload.customCDN = customCDN;
    payload.loadStrategy = loadStrategy;

    try {
      const workspaceResponse: AxiosResponse<IWorkspace> = await api.put(WORKSPACE_URLS.UPDATE_WORKSPACE(id), payload);

      dispatch(setWorkspace(workspaceResponse.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  }
};

export const getWorkspaceList =
  (limit: number, offset: number, options: IRequestListOptions): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleIsWorkspaceListLoading(true));
      const queryParams = qs.stringify(options);
      const workspaceListResponse: AxiosResponse<IWorkspaceList> = await api.get(
        WORKSPACE_URLS.WORKSPACE_LIST(offset, limit, queryParams),
      );
      dispatch(setWorkspaceListData(workspaceListResponse?.data));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(toggleIsWorkspaceListLoading(false));
    }
  };

export const getWorkspaceListMeta = (): AppThunk => async (dispatch) => {
  try {
    dispatch(toggleIsWorkspaceListLoading(true));
    const workspaceListResponse: AxiosResponse<IWorkspaceListMeta> = await api.get(WORKSPACE_URLS.WORKSPACE_LIST_META);
    dispatch(setWorkspaceListMeta(workspaceListResponse?.data));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(toggleIsWorkspaceListLoading(false));
  }
};

export const setSortOption =
  (column: ITableHeaderColumn): AppThunk =>
  (dispatch, getState) => {
    const {
      workspaces: {
        workspaceList: {
          options: { sort },
        },
      },
    } = getState();
    const option = column.id;

    if (sort[column.id]) {
      /** Toggle ASC or DESC value */
      const value = sort[column.id] === 'asc' ? 'desc' : 'asc';
      return dispatch(setWorkspaceListSortOption({ option, value }));
    }

    return batch(() => {
      dispatch(clearWorkspaceListSortOption());
      dispatch(setWorkspaceListSortOption({ option, value: 'asc' }));
    });
  };

export const getWorkspace =
  (id: string, successCallback?: (params: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const workspaceResponse: AxiosResponse<IWorkspace> = await api.get(WORKSPACE_URLS.GET_WORKSPACE(id));
      batch(() => {
        dispatch(setWorkspace(workspaceResponse?.data));
        successCallback && successCallback(workspaceResponse?.data);
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const getWorkspaceMeta =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const workspaceMetaResponse: AxiosResponse<IWorkspaceMeta> = await api.get(WORKSPACE_URLS.GET_WORKSPACE_META(id));
      batch(() => {
        dispatch(setWorkspaceMeta(workspaceMetaResponse?.data));
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const manualAcceptUser =
  (userEmail: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(loadingStarted());

    try {
      const state = getState();
      const workspaceId = state.workspaces.workspace?._id;

      if (workspaceId) {
        const data = {
          email: userEmail,
          workspaceId,
        };

        await api.put(WORKSPACE_URLS.ACCEPT_INVITATION, data);

        batch(() => {
          dispatch(getWorkspace(workspaceId));
          dispatch(getWorkspaceMeta(workspaceId));
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const removeWorkspaceUser =
  (userId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(loadingStarted());

    try {
      const state = getState();
      const workspaceId = state.workspaces.workspace?._id;

      if (workspaceId) {
        const data = {
          workspaceId,
        };

        await api.put(WORKSPACE_URLS.REMOVE_MEMBER(userId), data);

        batch(() => {
          dispatch(getWorkspace(workspaceId));
          dispatch(getWorkspaceMeta(workspaceId));
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const addWorkspaceMembers =
  (usersIds: string[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(loadingStarted());

    try {
      const state = getState();
      const workspaceId = state.workspaces.workspace?._id;

      if (workspaceId) {
        const data = {
          workspaceId,
          usersIds,
        };

        await api.post(WORKSPACE_URLS.ADD_MEMBERS, data);

        batch(() => {
          dispatch(getWorkspace(workspaceId));
          dispatch(getWorkspaceMeta(workspaceId));
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const generateSitemap =
  (type: 'sitemap' | 'newssitemap' | 'rssfeed'): AppThunk =>
  async (dispatch, getState) => {
    dispatch(loadingStarted());

    try {
      const state = getState();
      const workspaceId = state.workspaces.workspace?._id;

      if (workspaceId) {
        const data = {
          workspaceId,
          type,
        };

        await api.post(WORKSPACE_URLS.INIT_SITEMAP, data);

        batch(() => {
          dispatch(getWorkspace(workspaceId));
          dispatch(getWorkspaceMeta(workspaceId));
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(loadingFinished());
    }
  };

export const udpateSitemap =
  (type: 'sitemap' | 'newssitemap' | 'rssfeed'): AppThunk =>
  async (dispatch, getState) => {
    dispatch(loadingStarted());

    try {
      const state = getState();
      const workspaceId = state.workspaces.workspace?._id;

      if (workspaceId) {
        const data = {
          workspaceId,
          type,
        };

        await api.post(WORKSPACE_URLS.UPDATE_SITEMAP, data);

        batch(() => {
          dispatch(getWorkspace(workspaceId));
          dispatch(getWorkspaceMeta(workspaceId));
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(loadingFinished());
    }
  };
