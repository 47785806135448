import React from 'react';
import SecondaryButtonUI from './primary-button-ui';
import clsx from 'clsx';

type ISizeType = 'small' | 'medium';

interface IPrimaryButton {
  className?: string;
  type?: 'button' | 'submit';
  text: string;
  isDisabled?: boolean;
  sizeType?: ISizeType;
  onClick?: () => void;
}

const PrimaryButton = ({
  className,
  type = 'button',
  text,
  isDisabled,
  sizeType = 'medium',
  onClick,
}: IPrimaryButton): JSX.Element => {
  const classes = SecondaryButtonUI();
  const classNames = clsx(classes.button, classes[sizeType], {
    [classes.disabled]: isDisabled,
    [className as string]: className,
  });

  return (
    <button type={type} className={classNames} onClick={onClick}>
      {text}
    </button>
  );
};

export default PrimaryButton;
