import React, { ReactNode } from 'react';
/** COMPONENTS */
import { Grid, GridProps } from '@material-ui/core';
/** UI */
import GridItemUI from './grid-item-ui';

type IProps = {
  children: ReactNode;
  className?: string;
  gridProps?: GridProps;
};

const GridItem = ({ children, className, gridProps = {} }: IProps): JSX.Element => {
  const classes = GridItemUI();
  return (
    <Grid item {...gridProps} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  );
};

export default GridItem;
