import { makeStyles, Theme } from '@material-ui/core/styles';

export interface StyleProps {
  display: 'flex' | 'block' | 'inline-block' | 'inline' | 'inline-flex';
  flexFlow: string | 'row wrap' | 'column wrap';
}

export default makeStyles<Theme, StyleProps>(() => ({
  box: {
    display: ({ display }) => display,
    flexFlow: ({ flexFlow }) => flexFlow,
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '12px',
    background: 'var(--shade-900-85)',
    backdropFilter: 'blur(50px)',
    padding: '19px 24px 26px 24px',
    boxShadow: '24px 32px 72px var(--black-18)',
  },
}));
