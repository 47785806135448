import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  box: {
    padding: '19px 24px 26px',
    background: 'var(--shade-900-85)',
    borderRadius: '12px',
    boxShadow: '24px 32px 72px var(--black-18)',
    margin: '0 0 30px',
  },
  container: {
    background: 'transparent',
    maxWidth: '950px',
  },
  table: {
    minWidth: '0',
  },
  image: {
    width: '30px',
    height: 'auto',
    borderRadius: '5px',
  },
  tableCell: {
    color: 'var(--white)',
  },
  clickableCell: {
    cursor: 'pointer',
  },
  sortingIcon: {
    verticalAlign: 'sub',
    fontSize: '17px',
    marginRight: '3px',
  },
  pagination: {
    color: 'var(--white)',
    '& svg': {
      color: 'var(--white)',
    },
  },
  action: {
    margin: '2px',
  },
  acceptBox: {
    borderRadius: '6px',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.2s ease',
    outline: 'none',
    border: 'none',
    background: 'transparent',
    '&:hover': {
      background: 'var(--shade-500-85)',
    },
  },
  acceptIcon: {
    color: 'var(--shade-100)',
    fontSize: '14px',
  },
});
