import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles<Theme>(() =>
  createStyles({
    container: {
      flex: '1 1 250px',
      position: 'relative',
      minHeight: '141px',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '18px',
      justifyContent: 'space-between',
    },
    removeMargin: {
      marginBottom: '0',
    },
    alignEnd: {
      alignItems: 'flex-end',
    },
    alignStart: {
      alignItems: 'flex-start',
    },
    base: {
      background: 'var(--shade-900-85)',
      boxShadow: '24px 32px 72px var(--black-18)',
      backdropFilter: 'blur(50px)',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      padding: '28px 38px 31px',
    },
    labelContainer: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.01em',
      color: 'var(--shade-100)',
      marginRight: 'auto',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    valueContainer: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '40px',
      lineHeight: 1,
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.01em',
      color: 'var(--white)',
      alignSelf: 'baseline',      
    },
    totalContainer: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: 1,
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.01em',
      color: 'var(--white)',
      alignSelf: 'baseline',
    },
  }),
);
