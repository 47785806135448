import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  button: {
    display: 'inline-block',
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: 500,
    color: 'var(--shade-900)',
    background: 'var(--danger)',
    border: '1px solid var(--danger)',
    letterSpacing: '0.01em',
    filter: 'drop-shadow(0px 4px 12px var(--black-16))',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  medium: {
    fontSize: '12px',
    lineHeight: '16px',
    borderRadius: '6px',
    padding: '8.5px 12px 7.5px',
  },
  small: {
    fontSize: '10px',
    lineHeight: '14px',
    borderRadius: '4px',
    padding: '5.5px 8px 4.5px',
  },
  disabled: {
    borderColor: 'var(--danger-20)',
    background: 'var(--danger-20)',
    cursor: 'not-allowed',
  },
});
