import React from 'react';
/** Interfaces */
import { IDomain } from 'interfaces/domain';
/** Components */
import { Box } from '@material-ui/core';
import { InfoBox, InfoBoxLabel, InfoBoxLink, InfoBoxValue } from '../../shared';
/** Utils */
import formatDate from 'utils/formatDate';
/** UI */
import DomainInfoUI from './domain-info-ui';
import { IUser } from 'interfaces/user';

interface IDomainInfo {
  domain: IDomain | null;
}

const DomainInfo = ({ domain }: IDomainInfo): JSX.Element => {
  const classes = DomainInfoUI();
  const domainAuthor = domain?.workspaces?.reduce(
    (prev, workspace) => prev || workspace?.users?.find((u) => u?._id === domain?.author),
    undefined as unknown as IUser,
  );

  return (
    <InfoBox className={classes.infoBox}>
      <Box display={'flex'} flexWrap={'wrap'} width={'100%'}>
        <Box pr={3} mb={2}>
          <InfoBoxLabel text="Domain" />

          <InfoBoxValue component={'h1'} text={domain?.domainName} />
        </Box>

        <Box mr={3} mb={2}>
          <InfoBoxLabel text="Author" />
          {domainAuthor ? (
            <InfoBoxLink to={`/users/${domainAuthor?._id}`} text={`${domainAuthor?.email}`} />
          ) : (
            <InfoBoxValue text={'Unknown'} />
          )}
        </Box>

        <Box pr={3} mb={2}>
          <InfoBoxLabel text="Created" />

          <InfoBoxValue text={formatDate(domain?.createdAt || '')} />
        </Box>

        <Box pr={3} mb={2}>
          <InfoBoxLabel text="Updated" />

          <InfoBoxValue text={formatDate(domain?.updatedAt || '')} />
        </Box>
      </Box>

      <Box display={'flex'} flexWrap={'wrap'}>
        <Box mb={2}>
          <InfoBoxLabel text="Used by" />
          {domain?.workspaces?.map((ws) => (
            <Box mb={1}>
              <InfoBoxLink to={`/workspaces/${ws?._id}`} text={`${ws?.title}`} />
            </Box>
          ))}
        </Box>
      </Box>
    </InfoBox>
  );
};

export default DomainInfo;
