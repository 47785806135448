import { useMemo, useCallback } from 'react';
/** COMPONENTS */
import { GridContainer, GridItem, InfoBox } from 'components/shared';
import { Table } from 'components/shared/table/';
import TableToolBar from '../shared/table/table-toolbar/table-toolbar';
import { Email, Person } from '@material-ui/icons';
import PageTitle from '../shared/page-title/page-title';
import { PrimaryLinkButton } from '../buttons';
/** REDUX */
import {
  getUserList,
  setSortOption,
  clearUserListSortOption,
  deleteUser,
  setUserListSearchOption,
} from 'redux/features/users/usersSlice';
import { useDispatch, useSelector, batch } from 'react-redux';
import { RootState } from 'redux/store';
/** INTERFACES */
import { IUser } from 'interfaces/user';
import { ITableHeaderColumn, ITableHeaderUserColumnIds } from 'interfaces/table/table-column';
import { ITableRow, ITableCell, ITableActionCell } from 'interfaces/table/table-row';
/** Utils */
import formatDate from '../../utils/formatDate';
import useList from '../../hooks/useList';

const columns: ITableHeaderColumn[] = [
  { id: ITableHeaderUserColumnIds._id, label: 'ID', minWidth: 50, align: 'left' },
  { id: ITableHeaderUserColumnIds.email, label: 'E-Mail', minWidth: 50, sortOption: true, align: 'left' },
  { id: ITableHeaderUserColumnIds.username, label: 'Username', minWidth: 50, sortOption: true, align: 'left' },
  { id: ITableHeaderUserColumnIds.createdAt, label: 'Creation date', minWidth: 144, sortOption: true, align: 'left' },
];

const searchByOptions = [
  { name: 'E-mail', value: 'email', icon: <Email /> },
  { name: 'Username', value: 'username', icon: <Person /> },
];

const UserList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { users, limit, totalCount, options } = useSelector((state: RootState) => state.users.userList);
  const isUserListLoading = useSelector((state: RootState) => state.users.isUserListLoading);

  const getList = useCallback(
    (paramLimit: number, page: number) => {
      dispatch(getUserList(paramLimit, page === 1 ? 0 : (page - 1) * paramLimit, options));
    },
    [dispatch, options],
  );

  const searchParamChangeCallback = useCallback(
    (searchParam: string, searchValue: string | number) => {
      dispatch(setUserListSearchOption({ [searchParam]: searchValue }));
    },
    [dispatch],
  );

  const { searchValue, searchParam, handleSearchParamChange, handleSearchValueChange } = useList({
    getList,
    limit,
    defaultSearchParam: searchByOptions[0].value,
    debounceSearchCallback: searchParamChangeCallback,
    searchParamChangeCallback,
  });

  const handleDeleteUser = useCallback(
    (userId: string) => {
      batch(() => {
        dispatch(deleteUser(userId));
      });
    },
    [dispatch],
  );

  const clearOptions = useCallback(() => {
    dispatch(clearUserListSortOption());
  }, [dispatch]);

  const handleSortOptionClick = useCallback(
    (column: ITableHeaderColumn) => {
      dispatch(setSortOption(column));
    },
    [dispatch],
  );

  const tableRowsData: ITableRow[] = useMemo(() => {
    return users.map((user: IUser) => {
      const cells: ITableCell[] = [
        { children: user?._id, align: 'left' },
        { children: user?.email, align: 'left' },
        { children: user?.username, align: 'left' },
        { children: formatDate(user?.createdAt), align: 'left' },
      ];
      const actionCellProps: ITableActionCell = {
        detailsPageLink: `/users/${user._id}`,
        deleteEntityId: user._id,
      };

      const row: ITableRow = {
        cells,
        actionCellProps,
      };

      return row;
    });
  }, [users]);

  return (
    <GridContainer>
      <GridItem gridProps={{ xs: 12 }}>
        <InfoBox flexFlow={'column wrap'}>
          <PageTitle text={'Users'} />
          <TableToolBar
            searchValue={searchValue}
            searchByValue={searchParam}
            searchByOptions={searchByOptions}
            sortOptions={options.sort}
            clearOptions={clearOptions}
            handleSearchValueChange={handleSearchValueChange}
            handleSearchParamChange={handleSearchParamChange}
          >
            <PrimaryLinkButton to={'/users/create'} text={'Create new user'} />
          </TableToolBar>
          <Table
            isLoading={isUserListLoading}
            data={users}
            columns={columns}
            withActions={true}
            rowsPerPage={limit}
            pagesCount={totalCount}
            sortOptions={options.sort}
            tableRowsData={tableRowsData}
            deleteMessageModal={'Are you sure you want to delete user ?'}
            handleDelete={handleDeleteUser}
            handleSortOptionClick={handleSortOptionClick}
          />
        </InfoBox>
      </GridItem>
    </GridContainer>
  );
};

export default UserList;
