/** REDUX */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'redux/store';
/** CONSTANTS */
import { SNIPPET_URLS } from 'config/urls';
import { SNIPPETS_REDUCER } from 'redux/constants';
import { batch } from 'react-redux';
/** UTILS */
import { AxiosResponse } from 'axios';
import qs from 'qs';
import api from 'utils/api';
/** INTERFACES */
import { ITableHeaderColumn } from 'interfaces/table/table-column';
import { IRequestListOptions } from '../../../interfaces/request-options';
import { loadingFinished, loadingStarted } from '../loader/loaderSlice';
import { ISnippet, ISnippetSingle } from '../../../interfaces/snippet';

type IAddSortOptionPayload = {
  option: string;
  value: string;
};

interface ISnippetList {
  snippets: any;
  totalCount: number;
  hasNext: boolean;
  limit: number;
  offset: number;
  options: IRequestListOptions;
}

interface ISnippetsState {
  snippet: ISnippetSingle | null;
  snippetList: ISnippetList;
  isSnippetListLoading: boolean;
}

const initialState: ISnippetsState = {
  snippet: null,
  snippetList: {
    snippets: [],
    totalCount: 0,
    hasNext: false,
    limit: 30,
    offset: 0,
    options: {
      sort: {},
      search: {},
    },
  },
  isSnippetListLoading: false,
};

const snippetsSlice = createSlice({
  name: SNIPPETS_REDUCER,
  initialState,
  reducers: {
    setSnippet: (state, action: PayloadAction<ISnippetSingle | null>) => {
      state.snippet = action.payload;
    },
    setSnippets: (state, action: PayloadAction<ISnippet[] | []>) => {
      state.snippetList.snippets = action.payload;
    },
    setSnippetListData: (state, action: PayloadAction<ISnippetList>) => {
      state.snippetList = { ...state.snippetList, ...action.payload };
    },
    setSnippetListSortOption: (state, action: PayloadAction<IAddSortOptionPayload>) => {
      const { option, value } = action.payload;
      state.snippetList.options.sort = { [option]: value };
    },
    setSnippetListSearchOption: (state, action: PayloadAction<any>) => {
      state.snippetList.options.search = action.payload;
    },
    clearSnippetListSortOption: (state) => {
      state.snippetList.options.sort = initialState.snippetList.options.sort;
    },
    clearSnippetListOptions: (state) => {
      state.snippetList.options = initialState.snippetList.options;
    },
    toggleIsSnippetListLoading: (state, action: PayloadAction<boolean>) => {
      state.isSnippetListLoading = action.payload;
    },
  },
});

// Action
export const {
  setSnippet,
  setSnippets,
  setSnippetListData,
  setSnippetListSortOption,
  clearSnippetListSortOption,
  toggleIsSnippetListLoading,
  setSnippetListSearchOption,
  clearSnippetListOptions,
} = snippetsSlice.actions;
export default snippetsSlice.reducer;

export const getSnippetList = (limit: number, offset: number, options: IRequestListOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(toggleIsSnippetListLoading(true));
    const queryParams = qs.stringify(options);
    const snippetListResponse: AxiosResponse<ISnippetList> = await api.get(
      SNIPPET_URLS.SNIPPET_LIST(offset, limit, queryParams),
    );
    dispatch(setSnippetListData(snippetListResponse?.data));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(toggleIsSnippetListLoading(false));
  }
};

export const setSortOption = (column: ITableHeaderColumn): AppThunk => (dispatch, getState) => {
  const {
    snippets: {
      snippetList: {
        options: { sort },
      },
    },
  } = getState();
  const option = column.id;

  if (sort[column.id]) {
    /** Toggle ASC or DESC value */
    const value = sort[column.id] === 'asc' ? 'desc' : 'asc';
    return dispatch(setSnippetListSortOption({ option, value }));
  }

  return batch(() => {
    dispatch(clearSnippetListSortOption());
    dispatch(setSnippetListSortOption({ option, value: 'asc' }));
  });
};

export const deleteSnippet = (id: string, successCallback?: () => void): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const snippets = state.snippets.snippetList.snippets;
    dispatch(loadingStarted());
    await api.delete(SNIPPET_URLS.DELETE_SNIPPET(id));
    dispatch(setSnippets(snippets.filter((snippet: ISnippet) => snippet._id !== id)));
    successCallback && successCallback();
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(loadingFinished());
  }
};

export const getSnippet = (id: string, successCallback?: (params: any) => void): AppThunk => async (dispatch) => {
  try {
    dispatch(loadingStarted());
    const snippetResponse: AxiosResponse<ISnippetSingle> = await api.get(SNIPPET_URLS.GET_SNIPPET(id));
    batch(() => {
      dispatch(setSnippet(snippetResponse?.data));
      successCallback && successCallback(snippetResponse?.data);
    });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(loadingFinished());
  }
};

export const updateSnippet = (id: string, snippet: ISnippetSingle): AppThunk => async (dispatch) => {
  try {
    dispatch(loadingStarted());
    const snippetResponse: AxiosResponse<ISnippetSingle> = await api.put(SNIPPET_URLS.UPDATE_SNIPPET(id), {
      ...snippet,
    });
    batch(() => {
      dispatch(setSnippet(snippetResponse?.data));
    });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(loadingFinished());
  }
};
