import React from 'react';
/* Components */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box, Typography } from '@material-ui/core';
import { SecondaryButton, PrimaryButton } from '../buttons';
/** UI */
import MessageModalUI from './message-modal-ui';

interface IMessageModal {
  isOpen: boolean;
  acceptButtonText?: string;
  cancelButtonText?: string;
  onCancel?: () => void;
  onAccept?: void | (() => void);
  message: string;
  description?: string;
}

const MessageModal = ({
  isOpen,
  onCancel,
  onAccept,
  message,
  description,
  acceptButtonText = 'Accept',
  cancelButtonText = 'Cancel',
}: IMessageModal): JSX.Element => {
  const classes = MessageModalUI();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <Box className={classes.messageContainer}>
            <Typography className={classes.message} component={'h2'}>
              {message}
            </Typography>

            {description && (
              <Typography className={classes.description} component={'p'}>
                {description}
              </Typography>
            )}
          </Box>

          <Box>
            {onAccept && <PrimaryButton className={classes.button} text={acceptButtonText} onClick={onAccept} />}

            {onCancel && <SecondaryButton className={classes.button} text={cancelButtonText} onClick={onCancel} />}
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};

export default MessageModal;
