import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
/** Router */
import { useHistory } from 'react-router-dom';
/** Redux */
import { getUserRoles, registerUser } from 'redux/features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
/** Components*/
import { CheckBox, FormControlLabel, GridContainer, GridItem, InfoBox, Input, Select } from '../shared';
import { Box, InputAdornment } from '@material-ui/core';
import { AccountCircle, Email, VpnKey } from '@material-ui/icons';
import { PrimaryButton } from '../buttons';
import PageTitle from '../shared/page-title/page-title';
/** Interfaces */
import { IRegisterUser, IRole } from 'interfaces/user';
import { IUserFormRegistrationErrors } from 'interfaces/userForm';
/** Constants */
import { EMAIL_REGEX, USERNAME_REGEX } from 'constants/regex';
/** UI */
import UserRegistrationUI from './user-registration-ui';

const initialUserFormErrors = { username: '', email: '', password: '', role: '' };

const UserRegistration = (): JSX.Element => {
  const classes = UserRegistrationUI();
  const dispatch = useDispatch();
  const history = useHistory();
  const userRoles = useSelector((state: RootState) => state.users.roles);
  const [userFormErrors, setUserFormErrors] = useState<IUserFormRegistrationErrors>(initialUserFormErrors);
  const [userForm, setUserForm] = useState<IRegisterUser>({
    email: '',
    password: '',
    username: '',
    role: '',
    blocked: false,
    paid: false,
    instagramExportAllowed: true,
    privacyPolicy: false,
    isVerified: false,
  });

  const roleSelectOptions = userRoles.map((role: IRole) => ({
    value: role.name,
    label: role.name,
  }));

  const onUpdateUser = (manuallyMutatedFields?: any) => {
    if (userForm && userForm.role !== '') {
      const newUserData = {
        ...userForm,
        roleId: userForm?.role?._id,
        ...manuallyMutatedFields,
      };

      dispatch(
        registerUser(newUserData, (userId: string) => {
          history.push(`/users/${userId}`);
        }),
      );
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (userForm) {
      setUserForm({
        ...userForm,
        [event.target.name as string]: event.target.value as string,
      });
      setUserFormErrors(initialUserFormErrors);
    }
  };

  const onCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (userForm) {
      setUserForm({
        ...userForm,
        [event.target.name as string]: !userForm[event.target.name as keyof IRegisterUser],
      });
    }
  };

  const validateForm = () => {
    if (userForm) {
      const isUsernamePassedTest = USERNAME_REGEX.test(userForm.username);
      const isEmailPassedTest = EMAIL_REGEX.test(userForm.email);
      setUserFormErrors({
        username:
          userForm.username.length === 0
            ? 'Field cannot be empty!'
            : isUsernamePassedTest
            ? ''
            : `Username can contain only letters, numbers and '.' '-' '_'`,
        email: isEmailPassedTest ? '' : 'Please enter a valid email!',
        password: userForm.password.length >= 6 ? '' : 'Password minimum length is 6 characters',
        role: typeof userForm.role === 'string' && userForm.role === '' ? 'Please select a role!' : '',
      });
      return userForm.username.length > 0 && isUsernamePassedTest && isEmailPassedTest;
    }
    return false;
  };

  const onUpdateUserSettings = (event: FormEvent) => {
    event.preventDefault();
    validateForm();

    if (validateForm()) {
      onUpdateUser();
    }
  };

  const onRoleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (userForm) {
      const role = userRoles.find((role) => role.name === event.target.value) as IRole;

      setUserForm({
        ...userForm,
        role: role,
      });
    }
  };

  const isSubmitDisabled =
    userFormErrors.email.length > 0 ||
    userFormErrors.username.length > 0 ||
    userFormErrors.password.length > 0 ||
    userFormErrors.role.length > 0;

  const updateRoles = useCallback(
    (roles: IRole[]) => {
      setUserForm({
        ...userForm,
        role: roles.find((role) => role.name === 'APP_USER') || roles[0],
      });
    },
    [userForm],
  );

  useEffect(() => {
    if (userRoles.length === 0) {
      dispatch(getUserRoles(updateRoles));
    }
  }, [dispatch, userRoles, updateRoles]);

  return (
    <InfoBox flexFlow={'column wrap'}>
      <PageTitle text={'Create new user'} />

      <form className={classes.form} onSubmit={onUpdateUserSettings} autoComplete="off">
        <GridContainer>
          <GridItem gridProps={{ xs: 12, md: 6 }}>
            <Input
              labelText="Username"
              id="username"
              formControlProps={{
                fullWidth: true,
              }}
              error={userFormErrors.username.length > 0}
              errorText={userFormErrors.username}
              inputProps={{
                autoComplete: 'new-password',
                placeholder: 'Username',
                value: userForm?.username,
                name: 'username',
                type: 'text',
                onChange: onInputChange,
                endAdornment: (
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>

          <GridItem gridProps={{ xs: 12, md: 6 }}>
            <Input
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              error={userFormErrors.email.length > 0}
              errorText={userFormErrors.email}
              inputProps={{
                autoComplete: 'new-password',
                value: userForm?.email,
                name: 'email',
                type: 'email',
                onChange: onInputChange,
                endAdornment: (
                  <InputAdornment position="end">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>

          {/* <GridItem gridProps={{ xs: 12, md: 6 }}>
            <Input
              labelText="Name"
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              error={userFormErrors.firstName.length > 0}
              errorText={userFormErrors.firstName}
              inputProps={{
                autoComplete: 'off',
                value: userForm?.firstName,
                name: 'firstName',
                type: 'text',
                onChange: onInputChange,
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFields />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem> */}

          <GridItem gridProps={{ xs: 12, md: 6 }}>
            <Input
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              error={userFormErrors.password.length > 0}
              errorText={userFormErrors.password}
              inputProps={{
                autoComplete: 'new-password',
                value: userForm?.password,
                name: 'password',
                type: 'password',
                onChange: onInputChange,
                endAdornment: (
                  <InputAdornment position="end">
                    <VpnKey />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>

          <GridItem gridProps={{ xs: 12, md: 6 }}>
            <Select
              value={typeof userForm?.role === 'string' ? userForm?.role : userForm?.role.name}
              label={'Role'}
              name={'role'}
              errorText={userFormErrors.role}
              options={roleSelectOptions}
              onChange={onRoleSelect}
            />
          </GridItem>
        </GridContainer>

        <Box display={'flex'} justifyContent={'flex-start'} flexWrap={'wrap'} mb={2}>
          <Box mr={3}>
            <FormControlLabel
              control={<CheckBox isChecked={userForm?.paid || false} onChange={onCheckBoxChange} name="paid" />}
              label="Paid"
            />
          </Box>

          <Box mr={3}>
            <FormControlLabel
              control={
                <CheckBox
                  isChecked={userForm?.privacyPolicy || false}
                  onChange={onCheckBoxChange}
                  name="privacyPolicy"
                />
              }
              label="Privacy Policy"
            />
          </Box>

          <Box mr={3}>
            <FormControlLabel
              control={
                <CheckBox
                  isChecked={userForm?.instagramExportAllowed || false}
                  onChange={onCheckBoxChange}
                  name="instagramExportAllowed"
                />
              }
              label="Instagram Export"
            />
          </Box>

          <Box mr={3}>
            <FormControlLabel
              control={
                <CheckBox isChecked={userForm?.isVerified || false} onChange={onCheckBoxChange} name="isVerified" />
              }
              label="Verified email"
            />
          </Box>
        </Box>

        <Box mt={4}>
          <PrimaryButton
            className={classes.registerButton}
            isDisabled={isSubmitDisabled}
            type={'submit'}
            text={'Create user'}
          />
        </Box>
      </form>
    </InfoBox>
  );
};

export default UserRegistration;
