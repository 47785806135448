import React from 'react';
/** UI */
import clsx from 'clsx';
import QuaternaryButtonUI from './quaternary-button-ui';

type ISizeType = 'small' | 'medium';

interface IQuaternaryButton {
  className?: string;
  type: 'button' | 'submit';
  text: string;
  isDisabled?: boolean;
  sizeType?: ISizeType;
  onClick?: () => void;
}

const QuaternaryButton = ({
  className,
  type = 'button',
  text,
  isDisabled,
  sizeType = 'medium',
  onClick,
}: IQuaternaryButton): JSX.Element => {
  const classes = QuaternaryButtonUI();
  const classNames = clsx(classes.button, isDisabled && classes.disabled, classes[sizeType], className);

  return (
    <button type={type} className={classNames} onClick={onClick}>
      {text}
    </button>
  );
};
export default QuaternaryButton;
