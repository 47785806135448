import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  label: {
    fontSize: '14px',
    margin: '0 0 5px',
    fontFamily: 'Heebo',
    color: 'var(--white)',
    fontWeight: 'bold',
  },
});
