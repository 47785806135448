import React from 'react';
/** Components */
import TableSearchSelect from './table-search-select';
/** Interfaces */
import { ITableSearchSelect } from 'interfaces/table-search';
/** UI */
import TableSearchUI from './table-search-ui';

interface ITableSearch extends ITableSearchSelect {
  inputValue: string | number;
  onInputChange: (param?: any) => void;
}

const TableSearch = ({
  inputValue,
  dropdownPosition,
  dropdownWidth,
  dropdownTriggerStyles,
  isDisabled,
  selectOption,
  options,
  onSelect,
  children,
  onInputChange,
}: ITableSearch): JSX.Element => {
  const classes = TableSearchUI({});
  return (
    <div className={classes.tableSearchWrapper}>
      <input className={classes.tableSearchInput} value={inputValue} type="text" onChange={onInputChange} />
      <TableSearchSelect
        isDisabled={isDisabled}
        dropdownPosition={dropdownPosition}
        dropdownWidth={dropdownWidth}
        dropdownTriggerStyles={dropdownTriggerStyles}
        selectOption={selectOption}
        options={options}
        onSelect={onSelect}
      >
        {children}
      </TableSearchSelect>
    </div>
  );
};

export default TableSearch;
