import { ChangeEvent } from 'react';
/** Components */
import { Checkbox } from '@material-ui/core';
/** UI */
import CheckboxUI from './checkbox-ui';

interface ICheckBox {
  isChecked: boolean;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CheckBox = ({ isChecked, name, onChange }: ICheckBox): JSX.Element => {
  const classes = CheckboxUI();
  return (
    <Checkbox
      className={classes.checkbox}
      classes={{
        root: classes.root,
        checked: classes.checked,
      }}
      checked={isChecked}
      onChange={onChange}
      name={name}
    />
  );
};

export default CheckBox;
