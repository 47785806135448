import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
/** REDUX */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { authUser, isUserAuthenticated } from 'redux/features/auth/authSlice';
/** ROUTES */
import Routes from './routes';
/** COMPONENTS */
import Spinner from 'components/spinner';

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const isAppLoading = useSelector((state: RootState) => state.loader.isLoading);
  const isAuthenticated = useSelector(isUserAuthenticated);

  useEffect(() => {
    dispatch(authUser());
  }, [dispatch]);

  if (isAuthenticated === null) {
    return <Spinner />;
  }

  return (
    <>
      {isAppLoading && <Spinner />}
      <Router>
        <Routes />
      </Router>
    </>
  );
};

export default App;
