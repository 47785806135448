import StoryList from '../components/story-list/story-list';
import StoryListMeta from '../components/story-list/story-list-meta';

const StoriesPage = (): JSX.Element => (
  <>
    <StoryListMeta />
    <StoryList />
  </>
);
export default StoriesPage;
