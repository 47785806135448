import { memo } from 'react';
import AnalyticsCardUI from './analytics-card-ui';
import clsx from 'clsx';

type Props = {
  label: string;
  value: string | number;
  total?: number;
};

const AnalyticsCard = ({ label, value, total }: Props) => {
  const classes = AnalyticsCardUI();

  if (total !== undefined) {
    return (
      <div className={classes.container}>
        <div className={classes.base}>
          <div className={classes.row}>
            <div className={classes.labelContainer}>{label}</div>
          </div>
          <div className={clsx(classes.row, classes)}>
            <div className={classes.valueContainer}>{value}</div>
          </div>
          <div className={clsx(classes.row, classes.removeMargin)}>
            <div className={classes.totalContainer}>{parseFloat(((Number(value) / total) * 100).toFixed(2))}%</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.base}>
        <div className={classes.row}>
          <div className={classes.labelContainer}>{label}</div>
        </div>
        <div className={clsx(classes.row, classes.removeMargin)}>
          <div className={classes.valueContainer}>{value}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(AnalyticsCard);
