import DatePicker from 'react-datepicker';
import DatePickerWrapper from '../../shared/datepicker-wrapper/date-picker-wrapper';
import FormControlLabelComponent from 'components/shared/form-control-label/form-control-label';
import { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { CheckBox, GridItem, InfoBox, InfoBoxLabel, Input, Select } from '../../shared';
import { generateDate } from '../../../utils/formatDate';
import { PrimaryButton, QuaternaryButton } from '../../buttons';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { LoadStrategy, WorkspaceTypes } from '../../../interfaces/workspaces';
import {
  changeCDN,
  changeMask,
  generateSitemap,
  resetDraft,
  setLoadStrategy,
  setWorkspaceTrialEndsAt,
  setWorkspaceType,
  toggleCustomCDN,
  toggleStoryConversion,
  udpateSitemap,
  updateWorkspaceData,
} from '../../../redux/features/workspaces/workspacesSlice';
import { debounce } from 'lodash';
import { useDidUpdate } from 'hooks/useDidUpdate';

const typeValues = Object.keys(WorkspaceTypes).map((typeKey) => ({
  value: WorkspaceTypes[typeKey as WorkspaceTypes],
  label: WorkspaceTypes[typeKey as WorkspaceTypes],
}));

const loadStrategies = Object.keys(LoadStrategy).map((str) => ({
  value: LoadStrategy[str as LoadStrategy],
  label: LoadStrategy[str as LoadStrategy].replace(/_/gim, ' '),
}));

const WorkspaceSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const workspaceType = useSelector((state: RootState) => state.workspaces?.workspace?.type);
  const loadStrategy = useSelector((state: RootState) => state.workspaces?.workspace?.loadStrategy);
  const isStoryConversionEnabled = useSelector(
    (state: RootState) => state.workspaces?.workspace?.isStoryConversionEnabled,
  );

  const customCDN = useSelector((state: RootState) => state.workspaces?.workspace?.customCDN);
  const customDomains = useSelector((state: RootState) => state.workspaces?.workspace?.domainSettings?.domainList);
  const sitemapInitialized = useSelector((state: RootState) => state.workspaces?.workspace?.sitemapInitialized);
  const newSitemapInitialized = useSelector(
    (state: RootState) => state.workspaces?.workspace?.newssitemap.isInitialized,
  );
  const rssFeedInitialized = useSelector((state: RootState) => state.workspaces?.workspace?.rssFeed.isInitialized);

  const workspaceTrialEndsAt = useSelector((state: RootState) =>
    state.workspaces?.workspace?.trialEndsAt ? new Date(state.workspaces?.workspace?.trialEndsAt) : null,
  );

  const [currentCDN, setCurrentCDN] = useState<string>(customCDN?.cdns?.[0] ?? '');
  const [mask, setMask] = useState<string>(customCDN?.mask ?? '');

  const onTypeSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as WorkspaceTypes;
      if (value === WorkspaceTypes.TRIAL) {
        const newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() + 14);
        dispatch(setWorkspaceTrialEndsAt(generateDate(newEndDate)));
      }
      dispatch(setWorkspaceType(event.target.value as WorkspaceTypes));
    },
    [dispatch],
  );

  const onLoadStrategySelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as LoadStrategy;
      dispatch(setLoadStrategy(value));
    },
    [dispatch],
  );

  const onStoryConversionToggle = useCallback(() => {
    dispatch(toggleStoryConversion(!isStoryConversionEnabled));
  }, [dispatch, isStoryConversionEnabled]);

  const onCustomCDNToggle = useCallback(() => {
    dispatch(toggleCustomCDN(!customCDN?.isEnabled));
  }, [customCDN?.isEnabled, dispatch]);

  const onCDNChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setCurrentCDN(e.target.value);
  }, []);

  const onMaskChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setMask(e.target.value);
  }, []);

  const onTrialEndsDateChange = useCallback(
    (date: Date) => {
      dispatch(setWorkspaceTrialEndsAt(generateDate(date)));
    },
    [dispatch],
  );

  const saveChanges = useCallback(() => {
    dispatch(updateWorkspaceData());
  }, [dispatch]);

  const debouncedCDNUpdate = useCallback(
    debounce((dispatch, newCDN: string) => {
      dispatch(changeCDN(newCDN));
    }, 300),
    [],
  );

  const debouncedMaskUpdate = useCallback(
    debounce((dispatch, newCDN: string) => {
      dispatch(changeMask(newCDN));
    }, 300),
    [],
  );

  const onGenerateSitemap = useCallback(
    (type: 'sitemap' | 'newssitemap' | 'rssfeed') => {
      dispatch(generateSitemap(type));
    },
    [dispatch],
  );

  const onUpdateSitemap = useCallback(
    (type: 'sitemap' | 'newssitemap' | 'rssfeed') => {
      dispatch(udpateSitemap(type));
    },
    [dispatch],
  );

  useDidUpdate(() => {
    debouncedCDNUpdate(dispatch, currentCDN);
  }, [dispatch, currentCDN, debouncedCDNUpdate]);

  useDidUpdate(() => {
    debouncedMaskUpdate(dispatch, mask);
  }, [dispatch, mask, debouncedMaskUpdate]);

  useEffect(() => {
    return () => {
      dispatch(resetDraft());
    };
  }, [dispatch]);

  return (
    <InfoBox>
      <GridItem gridProps={{ xs: 12 }}>
        <Box display={'flex'} flexWrap={'wrap'}>
          <Box pt={1} pr={3} mb={2}>
            <InfoBoxLabel text="Workspace type" />

            <Box mb={2}>
              <Select
                value={workspaceType || ''}
                name={'type'}
                options={typeValues}
                fieldPadding="0"
                onChange={onTypeSelect}
              />
            </Box>
          </Box>

          {workspaceType === WorkspaceTypes.TRIAL && (
            <Box pt={1} pr={3} mb={2}>
              <Box mb={2}>
                <InfoBoxLabel text="Trial end date" />

                <DatePickerWrapper>
                  <DatePicker
                    selected={workspaceTrialEndsAt}
                    dateFormat="dd.MM.yyyy"
                    onChange={onTrialEndsDateChange}
                  />
                </DatePickerWrapper>
              </Box>
            </Box>
          )}

          {(((customCDN?.isEnabled && customCDN?.cdns?.some((cdn) => !!cdn)) ?? 0 > 0) ||
            (customDomains?.length ?? 0) > 0) && (
            <Box pt={1} ml={5} display="flex">
              <Box mr={2}>
                <QuaternaryButton
                  type="button"
                  text={sitemapInitialized ? 'Rebuild sitemap' : 'Build sitemap'}
                  onClick={() => (sitemapInitialized ? onUpdateSitemap('sitemap') : onGenerateSitemap('sitemap'))}
                />
              </Box>
              <Box mr={2}>
                <QuaternaryButton
                  type="button"
                  text={newSitemapInitialized ? 'Rebuild GNS' : 'Build GNS'}
                  onClick={() =>
                    newSitemapInitialized ? onUpdateSitemap('newssitemap') : onGenerateSitemap('newssitemap')
                  }
                />
              </Box>
              <Box mr={2}>
                <QuaternaryButton
                  type="button"
                  text={rssFeedInitialized ? 'Rebuild RSS Feed' : 'Build RSS Feed'}
                  onClick={() => (rssFeedInitialized ? onUpdateSitemap('rssfeed') : onGenerateSitemap('rssfeed'))}
                />
              </Box>
            </Box>
          )}
        </Box>

        <Box display={'flex'} flexWrap={'wrap'}>
          <Box pt={1} pr={3} mb={2}>
            <InfoBoxLabel text="Widgets loading strategy" />

            <Box mb={2}>
              <Select
                value={loadStrategy || ''}
                name={'strategy'}
                options={loadStrategies}
                fieldPadding="0"
                onChange={onLoadStrategySelect}
              />
            </Box>
          </Box>
        </Box>
        <Box mr={3}>
          <FormControlLabelComponent
            control={
              <CheckBox
                isChecked={isStoryConversionEnabled || false}
                onChange={onStoryConversionToggle}
                name="isStoryConversionEnabled"
              />
            }
            label="Story conversion enabled"
          />
        </Box>

        <Box mr={3}>
          <FormControlLabelComponent
            control={
              <CheckBox
                isChecked={customCDN?.isEnabled || false}
                onChange={onCustomCDNToggle}
                name="isCustomCDNEnabled"
              />
            }
            label="Custom CDN enabled"
          />
          {customCDN?.isEnabled ? (
            <Box mr={3} width="25%">
              <Input
                labelText="CDN"
                id="cdn"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  autoComplete: 'off',
                  value: currentCDN,
                  name: 'cdn',
                  type: 'text',
                  onChange: onCDNChange,
                }}
              />
              <Input
                labelText="MASK"
                id="mask"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  autoComplete: 'off',
                  value: mask,
                  name: 'mask',
                  type: 'text',
                  onChange: onMaskChange,
                }}
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </GridItem>

      <GridItem gridProps={{ xs: 12 }}>
        <Box mt={4}>
          <PrimaryButton isDisabled={false} type={'submit'} text={'Save changes'} onClick={saveChanges} />
        </Box>
      </GridItem>
    </InfoBox>
  );
};

export default WorkspaceSettings;
