import React, { useCallback, useMemo, useState } from 'react';
/** Config */
import { environment } from 'config/environment';
/** Components */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
/* Utils */
import sortCompareValues from 'utils/sortCompareValues';
import formatDate from 'utils/formatDate';
/** Interfaces */
import { ICut } from 'interfaces/cut';
/* UI */
import UserStoriesUI from './story-slides-ui';
import clsx from 'clsx';

const sort = (by: keyof ICut | '' | number, isAscending: boolean, array: any) => {
  switch (by) {
    case 'title':
    case 'createdAt':
    case 'updatedAt':
      return array.sort(sortCompareValues(by, isAscending));
    default:
      return array;
  }
};

interface IHeaderCell {
  label: string;
  alignment: 'left' | 'center' | 'right';
  hasSort: boolean;
  sortValue: string | null | undefined;
  width?: string;
}

const getHeaderCells = (): IHeaderCell[] => {
  return [
    {
      label: 'Cover',
      alignment: 'center',
      hasSort: false,
      sortValue: '',
      width: '80px',
    },
    {
      label: 'Created',
      alignment: 'right',
      hasSort: true,
      sortValue: 'createdAt',
    },
    {
      label: 'Updated',
      alignment: 'right',
      hasSort: true,
      sortValue: 'updatedAt',
    },
  ];
};

interface ITableHeader {
  cells: IHeaderCell[];
  activeSort: string;
  isAscending?: boolean;
  onSort?: (params?: any) => any;
}

const TableHeader = ({ activeSort, cells, isAscending, onSort }: ITableHeader): JSX.Element => {
  const classes = UserStoriesUI();
  const getSortingIcon = () => {
    if (typeof isAscending === 'boolean' && onSort) {
      return isAscending ? (
        <ArrowDownward className={classes.sortingIcon} />
      ) : (
        <ArrowUpward className={classes.sortingIcon} />
      );
    }

    return '';
  };

  return (
    <TableHead>
      <TableRow>
        {cells.map((cell) => {
          return (
            <TableCell
              classes={{
                root: clsx(classes.tableCell, {
                  [classes.clickableCell]: cell.hasSort,
                }),
              }}
              key={cell.label}
              align={cell?.alignment}
              width={cell?.width}
              {...(cell.hasSort && { onClick: onSort && onSort(cell.sortValue) })}
            >
              {cell.hasSort && activeSort === cell.sortValue && getSortingIcon()}
              {cell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

interface IStorySlides {
  slides: ICut[];
  storyId?: string;
}

export default function StorySlides({ slides, storyId = '' }: IStorySlides): JSX.Element {
  const classes = UserStoriesUI();
  const [activeSort, setActiveSort] = useState<keyof ICut | ''>('');
  const [isAscending, setIsAscending] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const sortedStories = useMemo(() => {
    if (slides) {
      return sort(activeSort, isAscending, [...slides]);
    }
    return [];
  }, [activeSort, isAscending, slides]);

  const onTitleSort = useCallback(
    (title: keyof ICut) => () => {
      if (activeSort === title) {
        setIsAscending(!isAscending);
        return;
      }
      setIsAscending(true);
      setActiveSort(title);
    },
    [isAscending, activeSort],
  );

  return (
    <Box className={classes.box}>
      <TableContainer classes={{ root: classes.container }}>
        <Table className={classes.table} size="small">
          <TableHeader
            activeSort={activeSort}
            isAscending={isAscending}
            cells={getHeaderCells()}
            onSort={onTitleSort}
          />
          <TableBody>
            {sortedStories.length === 0 && (
              <TableRow>
                <TableCell classes={{ root: classes.tableCell }} align={'center'} colSpan={7}>
                  No slides
                </TableCell>
              </TableRow>
            )}
            {sortedStories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: ICut) => (
              <TableRow key={row._id}>
                <TableCell classes={{ root: classes.tableCell }} align={'center'}>
                  <iframe
                    width={61.2}
                    height={110.4}
                    frameBorder={0}
                    src={`${environment.host}/amp/${storyId}/cuts/${row.position}/social-export`}
                  />
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }} align="right">
                  {formatDate(row.createdAt, true)}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }} align="right">
                  {formatDate(row.updatedAt, true)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          classes={{ root: classes.pagination }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedStories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
