import React, { ReactNode } from 'react';
/** Components */
import { InfoBox } from '../info-box';
/** UI */
import FormWrapperUI from './form-wrapper-ui';

interface IFormWrapper {
  children: ReactNode;
  onSubmit: (params?: any) => void;
}

const FormWrapper = ({ children, onSubmit }: IFormWrapper): JSX.Element => {
  const classes = FormWrapperUI();
  return (
    <InfoBox className={classes.infoBox}>
      <form className={classes.form} onSubmit={onSubmit} autoComplete="off">
        {children}
      </form>
    </InfoBox>
  );
};

export default FormWrapper;
