import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { isUserAuthenticated } from 'redux/features/auth/authSlice';

const PrivateRoute = (props: RouteProps): JSX.Element => {
  const isAuthenticated = useSelector(isUserAuthenticated);
  return isAuthenticated ? <Route {...props} /> : <Redirect to="/login" />;
};

export default PrivateRoute;
