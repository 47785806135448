import { useEffect } from 'react';
import { GridItem, GridContainer } from '../shared';
import { Box } from '@material-ui/core';
import AnalyticsCard from '../shared/analytics-card/analytics-card';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getUserListMeta, setUserListMeta } from '../../redux/features/users/usersSlice';

const UserListMeta = (): JSX.Element => {
  const dispatch = useDispatch();
  const { usersCount, formsFilled, userStatistics } = useSelector((state: RootState) => state.users.userListMeta);

  useEffect(() => {
    dispatch(getUserListMeta());

    return () => {
      dispatch(setUserListMeta(null));
    };
  }, [dispatch]);

  return (
    <GridContainer>
      <GridItem
        gridProps={{ xs: 12, style: { display: 'flex', columnGap: '12px', flexWrap: 'wrap', margin: '0 0 16px' } }}
      >
        <Box marginBottom={'16px'} style={{ display: 'flex', gap: '28px' }}>
          <AnalyticsCard label={'Total Users'} value={usersCount} />
          <AnalyticsCard label={'Form submissions'} value={formsFilled} />
        </Box>
      </GridItem>
      <GridItem
        gridProps={{ xs: 12, style: { display: 'flex', columnGap: '12px', flexWrap: 'wrap', margin: '0 0 16px' } }}
      >
        <Box marginBottom={'16px'} style={{ display: 'flex', gap: '28px' }}>
          <AnalyticsCard total={formsFilled} label={'Web Stories'} value={userStatistics?.usageType?.WEB_STORIES} />
          <AnalyticsCard total={formsFilled} label={'Social Media'} value={userStatistics?.usageType?.SOCIAL_MEDIA} />
          <AnalyticsCard total={formsFilled} label={'Automation'} value={userStatistics?.usageType?.AUTOMATION} />
          <AnalyticsCard total={formsFilled} label={'Curiosity'} value={userStatistics?.usageType?.CURIOSITY} />
          <AnalyticsCard total={formsFilled} label={'Other'} value={userStatistics?.usageType?.OTHER} />
        </Box>
      </GridItem>
      <GridItem
        gridProps={{ xs: 12, style: { display: 'flex', columnGap: '12px', flexWrap: 'wrap', margin: '0 0 16px' } }}
      >
        <Box marginBottom={'16px'} style={{ display: 'flex', gap: '28px' }}>
          <AnalyticsCard total={formsFilled} label={'0-10'} value={userStatistics?.companySize?.SMALL} />
          <AnalyticsCard total={formsFilled} label={'11-50'} value={userStatistics?.companySize?.MEDIUM} />
          <AnalyticsCard total={formsFilled} label={'51-100'} value={userStatistics?.companySize?.LARGE} />
          <AnalyticsCard total={formsFilled} label={'+100'} value={userStatistics?.companySize?.EXTRA_LARGE} />
        </Box>
      </GridItem>
    </GridContainer>
  );
};

export default UserListMeta;
