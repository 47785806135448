import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
/** Components */
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { ArrowUpward, ArrowDownward, Search } from '@material-ui/icons';
import { Input } from 'components/shared';
import { DeleteButton, PrimaryButton, ViewLinkButton } from 'components/buttons';
/* Utils */
import sortCompareValues from 'utils/sortCompareValues';
import formatDate from 'utils/formatDate';
/* UI */
import UserStoriesUI from './workspace-users-ui';
import clsx from 'clsx';
import { UserStatus, WorkspaceUser } from 'interfaces/workspaces';
import { CheckCircle } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { manualAcceptUser, removeWorkspaceUser } from 'redux/features/workspaces/workspacesSlice';
import MessageModal from 'components/message-modal/message-modal';
import UsersModal from 'components/users-modal/users-modal';

const sort = (by: keyof WorkspaceUser | '', isAscending: boolean, array: any) => {
  switch (by) {
    case 'email':
      return array.sort(sortCompareValues(by, isAscending));
    default:
      return array;
  }
};

interface IHeaderCell {
  label: string;
  alignment: 'left' | 'center' | 'right';
  hasSort: boolean;
  sortValue: string | null | undefined;
  width?: string;
}

const getHeaderCells = (): IHeaderCell[] => {
  return [
    {
      label: 'ID',
      alignment: 'left',
      hasSort: false,
      sortValue: '',
    },
    {
      label: 'Email',
      alignment: 'left',
      hasSort: true,
      sortValue: 'email',
    },
    {
      label: 'Role',
      alignment: 'left',
      hasSort: false,
      sortValue: '',
    },
    {
      label: 'Status',
      alignment: 'left',
      hasSort: false,
      sortValue: '',
    },
    {
      label: 'AddedAt',
      alignment: 'right',
      hasSort: false,
      sortValue: '',
    },
    {
      label: 'Actions',
      alignment: 'right',
      hasSort: false,
      sortValue: '',
      width: '110px',
    },
  ];
};

interface ITableHeader {
  cells: IHeaderCell[];
  activeSort: string;
  isAscending?: boolean;
  onSort?: (params?: any) => any;
}

const TableHeader = ({ activeSort, cells, isAscending, onSort }: ITableHeader): JSX.Element => {
  const classes = UserStoriesUI();
  const getSortingIcon = () => {
    if (typeof isAscending === 'boolean' && onSort) {
      return isAscending ? (
        <ArrowDownward className={classes.sortingIcon} />
      ) : (
        <ArrowUpward className={classes.sortingIcon} />
      );
    }

    return '';
  };

  return (
    <TableHead>
      <TableRow>
        {cells.map((cell) => {
          return (
            <TableCell
              classes={{
                root: clsx(classes.tableCell, {
                  [classes.clickableCell]: cell.hasSort,
                }),
              }}
              key={cell.label}
              align={cell?.alignment}
              width={cell?.width}
              {...(cell.hasSort && { onClick: onSort && onSort(cell.sortValue) })}
            >
              {cell.hasSort && activeSort === cell.sortValue && getSortingIcon()}
              {cell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

interface IWorkspaceUsers {
  workspaceUsers: WorkspaceUser[];
}

export default function WorkspaceUsers({ workspaceUsers }: IWorkspaceUsers): JSX.Element {
  const classes = UserStoriesUI();
  const dispatch = useDispatch();

  const [activeSort, setActiveSort] = useState<keyof WorkspaceUser | ''>('');
  const [isAscending, setIsAscending] = useState(true);
  const [searchedUser, setSearchedUser] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [deleteMember, setDeleteMember] = useState<{ email: string; _id: string } | null>(null);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const onSearchUser = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchedUser(event.target.value);
    setPage(0);
  }, []);

  const onManualAccept = useCallback(
    (userId) => {
      dispatch(manualAcceptUser(userId));
    },
    [dispatch],
  );

  const toggleRemoveMember = (_id: string, email: string) => setDeleteMember({ email, _id });

  const onRemoveMember = useCallback(() => {
    if (deleteMember?._id) {
      dispatch(removeWorkspaceUser(deleteMember._id));
      setDeleteMember(null);
    }
  }, [deleteMember?._id, dispatch]);

  const onCancelRemoveMember = () => setDeleteMember(null);

  const sortedUsers = useMemo(() => {
    if (workspaceUsers) {
      return sort(
        activeSort,
        isAscending,
        [...workspaceUsers].filter((u) => u.email.toLowerCase().includes(searchedUser.toLowerCase())),
      );
    }
    return [];
  }, [activeSort, isAscending, workspaceUsers, searchedUser]);

  const onTitleSort = useCallback(
    (email: keyof WorkspaceUser) => () => {
      if (activeSort === email) {
        setIsAscending(!isAscending);
        return;
      }
      setIsAscending(true);
      setActiveSort(email);
    },
    [isAscending, activeSort],
  );

  const toggleAddUserModal = () => {
    setAddUserModalOpen((prev) => !prev);
  };

  return (
    <>
      <Box className={classes.box}>
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
          <Box mb={2} maxWidth={'200px'}>
            <Input
              labelText="User email"
              id="user-email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                autoComplete: 'off',
                value: searchedUser,
                name: 'user-email',
                type: 'text',
                onChange: onSearchUser,
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <PrimaryButton text="Add user" onClick={toggleAddUserModal} />
          </Box>
        </Box>
        <TableContainer classes={{ root: classes.container }}>
          <Table className={classes.table} size="small">
            <TableHeader
              activeSort={activeSort}
              isAscending={isAscending}
              cells={getHeaderCells()}
              onSort={onTitleSort}
            />
            <TableBody>
              {sortedUsers.length === 0 && (
                <TableRow>
                  <TableCell classes={{ root: classes.tableCell }} align={'center'} colSpan={7}>
                    No users
                  </TableCell>
                </TableRow>
              )}
              {sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: WorkspaceUser) => {
                return (
                  <TableRow key={`${row.email} - ${row.addedAt}`}>
                    <TableCell classes={{ root: classes.tableCell }} align="left">
                      {row?._id}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }} align="left">
                      {row?.email}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }} align="left">
                      {row?.role?.name}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }} align="left">
                      {row?.status}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }} align="right">
                      {formatDate(row?.addedAt ?? '', true)}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }} align="right">
                      {row?.status === UserStatus.pending && (
                        <Box className={classes.acceptBox} onClick={() => onManualAccept(row.email)}>
                          <CheckCircle className={classes.acceptIcon} />
                        </Box>
                      )}
                      <ViewLinkButton className={classes.action} to={`/users/${row._id}`} />
                      {row?.role?.name !== 'owner' && (
                        <DeleteButton onClick={() => toggleRemoveMember(row._id, row.email)} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            classes={{ root: classes.pagination }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
      <MessageModal
        isOpen={deleteMember !== null}
        message={`Are you sure you want to remove ${deleteMember?.email} from the workspace?`}
        onAccept={onRemoveMember}
        onCancel={onCancelRemoveMember}
      />
      {addUserModalOpen && <UsersModal isOpen={addUserModalOpen} onClose={toggleAddUserModal} />}
    </>
  );
}
