import { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckBox, DetailsNavWrapper, GridContainer, GridItem, InfoBoxValue } from '../shared';
import { Box, FormControlLabel } from '@material-ui/core';
import { PrimaryButton, QuaternaryLinkButton } from '../buttons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import MessageModal from '../message-modal/message-modal';
import DangerButton from '../buttons/danger-button/danger-button';
import { deleteStory, getStory, publishStory, setStory } from '../../redux/features/stories/storiesSlice';
import StoryInfo from './story-info/story-info';
import StorySlides from './story-slides/story-slides';
import AnalyticsCard from '../shared/analytics-card/analytics-card';
import { buildPreviewLink } from 'utils/common';

const StoryDetails = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const story = useSelector((state: RootState) => state.stories.story);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rebuildCover, setRebuildCover] = useState(false);

  const onDeleteSnippet = useCallback(() => {
    if (story) {
      dispatch(
        deleteStory(story?._id, () => {
          history.push('/stories');
        }),
      );
    }
  }, [dispatch, history, story]);

  const onDeleteModalToggle = useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const onPublishStory = () => {
    if (story?._id) {
      dispatch(publishStory(story?._id, rebuildCover, story?.status === 'draft'));
    }
  };

  const previewLink = useMemo(() => (story?._id ? buildPreviewLink(story?._id) : null), [story?._id]);

  useEffect(() => {
    const startPointOfStoryId = history.location.pathname.lastIndexOf('/') + 1;
    const storyId = history.location.pathname.substr(startPointOfStoryId);
    dispatch(getStory(storyId));

    return () => {
      dispatch(setStory(null));
    };
  }, [dispatch, history.location.pathname]);

  return (
    <GridContainer>
      <GridItem gridProps={{ xs: 12 }}>
        <DetailsNavWrapper>
          <QuaternaryLinkButton to={'/stories'} text={'See all'} />

          <MessageModal
            isOpen={isDeleteModalOpen}
            message={`Are you sure you want to delete ${story?.title}?`}
            onAccept={onDeleteSnippet}
            onCancel={onDeleteModalToggle}
          />
          <Box marginLeft="auto">
            {previewLink && (
              <Box marginRight="12px" display="inline-block">
                <QuaternaryLinkButton to={{ pathname: previewLink }} target="_blank" text="Open preview" />
              </Box>
            )}
            {story?.amp?.url && story?.status === 'published' && (
              <Box marginRight="12px" display="inline-block">
                <QuaternaryLinkButton to={{ pathname: story?.amp?.url }} target="_blank" text="Open story" />
              </Box>
            )}
            <Box marginRight="12px" display="inline-block">
              <Box display="flex" flexDirection="column">
                <PrimaryButton
                  text={story?.status === 'draft' ? 'Publish story' : 'Republish story'}
                  onClick={onPublishStory}
                />

                <FormControlLabel
                  control={
                    <CheckBox
                      isChecked={rebuildCover}
                      onChange={() => setRebuildCover((prev) => !prev)}
                      name="rebuildCover"
                    />
                  }
                  label={<InfoBoxValue component={'h2'} text={'Rebuild cover'} />}
                />
              </Box>
            </Box>
            <DangerButton text={'Delete story'} onClick={onDeleteModalToggle} />
          </Box>
        </DetailsNavWrapper>
      </GridItem>

      <GridItem gridProps={{ xs: 12 }}>
        <Box mb={4}>
          <StoryInfo story={story} />
        </Box>
      </GridItem>

      <GridItem
        gridProps={{ xs: 12, style: { display: 'flex', columnGap: '12px', flexWrap: 'wrap', margin: '0 0 16px' } }}
      >
        <Box marginBottom={'16px'}>
          <AnalyticsCard label="Total Artboards" value={story?.cuts?.length ?? 0} />
        </Box>
      </GridItem>

      <GridItem gridProps={{ xs: 12 }}>
        <Box marginBottom={'16px'}>
          <StorySlides storyId={story?._id} slides={story?.cuts || []} />
        </Box>
      </GridItem>
    </GridContainer>
  );
};

export default StoryDetails;
