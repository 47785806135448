import { ReactNode } from 'react';
import classNames from 'classnames';
/** UI */
import CardContainerUI from './card-container-ui';

type IProps = {
  children: ReactNode;
  className?: string;
  plain?: boolean;
  carousel?: boolean;
};

const CardContainer = ({ className, children, plain, carousel, ...rest }: IProps): JSX.Element => {
  const classes = CardContainerUI();
  const options = {
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardCarousel]: carousel,
  }

  if (className) {
    options[className] = !!className;
  }

  const cardClasses = classNames(options);
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
};

export default CardContainer;
