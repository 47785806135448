import { ReactNode } from 'react';
import classNames from 'classnames';
/** UI */
import CardFooterUI from './card-footer-ui';

type IProps = {
  children: ReactNode;
  className: string;
};

const CardFooter = ({ className, children, ...rest }: IProps): JSX.Element => {
  const classes = CardFooterUI();
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [className]: className !== undefined,
  });
  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
};

export default CardFooter;
