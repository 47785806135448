import React from 'react';
/** UI */
import TableStoryCoverUI from './table-story-cover-ui';

interface ITableStoryCover {
  src: string;
  alt?: string;
}

const TableStoryCover = ({ src, alt }: ITableStoryCover): JSX.Element => {
  const classes = TableStoryCoverUI();
  return <img className={classes.image} src={src} alt={alt} />;
};

export default TableStoryCover;
