import { makeStyles } from '@material-ui/core/styles';
import { container } from 'constants/material-ui';

export default makeStyles({
  section: {
    minHeight: '90vh',
    maxHeight: '1600px',
    overflow: 'hidden',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
  },
  errorBox: {
    marginBottom: '70px',
  },
  container,
});
