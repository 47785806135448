import { makeStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  maxWidth?: string;
  actionsAlignment?: string;
}

export default makeStyles<Theme, StyleProps>({
  selectableRow: {
    cursor: 'pointer',
  },
  container: {
    background: 'transparent',

    '&::-webkit-scrollbar': { height: '6px', borderRadius: '12px' },
    '&::-webkit-scrollbar-track': { background: 'transparent' },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--shade-300-85)',
      borderRadius: '20px',
      transition: 'background 0.12s ease',
    },
    maxWidth: ({ maxWidth }) => (maxWidth ? maxWidth : '100%'),
  },
  table: {
    minWidth: 500,
    backgroundColor: 'transparent',
    margin: '0',
    '& tr:last-of-type $tableCell': {
      borderColor: 'transparent',
    },
  },
  tableCell: {
    '&&&': {
      color: 'var(--white)',
    },
  },
  actionButtonsWrapper: {
    display: 'flex',
    justifyContent: ({ actionsAlignment }) => actionsAlignment,
    '& > *': {
      margin: '0 2px',
    },
  },
  pagination: {
    color: 'var(--white)',
    '& svg': {
      color: 'var(--white)',
    },
  },
  spinner: {
    color: 'var(--primary)',
  },
  selected: {
    backgroundColor: 'var(--primary)',
  },
});
