import React, { BaseSyntheticEvent } from 'react';
/** Components */
import { Box, Button, Fade, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
/** UI */
import UserMenuUI from './user-menu-ui';
import { useHistory } from 'react-router-dom';

interface IUserMenu {
  isBlocked: boolean;
  onBlock: () => void;
  onDelete: () => void;
}

const UserMenu = ({ isBlocked, onBlock, onDelete }: IUserMenu): JSX.Element => {
  const classes = UserMenuUI();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreate = () => {
    history.push('/users/create');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBlock = () => {
    onBlock();
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete();
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon className={classes.icon} />
      </Button>
      <Menu
        PaperProps={{
          className: classes.menuContainer,
        }}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleCreate}>Create</MenuItem>
        <MenuItem onClick={handleBlock}>{isBlocked ? 'Unblock' : 'Block'}</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
