/** COMPONENTS */
import { TableRow, TableCell } from '@material-ui/core';
/** INTERFACES */
import { ITableCell } from 'interfaces/table/table-row';
/** UI */
import TableUI from './../../table-ui';
import clsx from 'clsx';

interface IProps {
  cells: ITableCell[];
  isSelected?: boolean;
  onClick?: any;
}

const Row = ({ cells, isSelected, onClick }: IProps): JSX.Element => {
  const classes = TableUI({});
  if (onClick) {
    return (
      <TableRow onClick={onClick} className={clsx(classes.selectableRow, isSelected ? classes.selected : '')}>
        {cells.map((cell, index) => (
          <TableCell
            className={classes.tableCell}
            align={cell.align || 'center'}
            style={{ width: cell.width || 300 }}
            key={index}
          >
            {cell.children}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  return (
    <TableRow>
      {cells.map((cell, index) => (
        <TableCell
          className={classes.tableCell}
          align={cell.align || 'center'}
          style={{ width: cell.width || 300 }}
          key={index}
        >
          {cell.children}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default Row;
