/** REDUX */
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'redux/store';
import { batch } from 'react-redux';
import { loadingFinished, loadingStarted } from '../loader/loaderSlice';
/** CONSTANTS */
import { AUTH_URLS } from 'config/urls';
import { AUTH_REDUCER } from 'redux/constants';
/** UTILS */
import { AxiosResponse } from 'axios';
import api from 'utils/api';
/** INTERFACES */
import { IToken, IUser } from 'interfaces/user';

type ILoginPayload = {
  email: string;
  password: string;
};

type ILogoutPayload = {
  userId: string;
  token: string;
};

type ILoginResult = {
  user: IUser;
  accessToken: IToken;
  refreshToken: IToken;
};

interface IAuthState {
  user: IUser | null | false;
  authErrors: any;
}

const initialState: IAuthState = {
  user: null,
  authErrors: null,
};

const authSlice = createSlice({
  name: AUTH_REDUCER,
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    loginFailed: (state) => {
      state.user = false;
    },
    setError: (state, action) => {
      state.authErrors = action.payload;
    },
    clearErrors: (state) => {
      state.authErrors = null;
    },
    clearState: (state) => {
      state.user = false;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
  },
});

// Action
export const { loginSuccess, loginFailed, clearState, setError, clearErrors } = authSlice.actions;
export default authSlice.reducer;

export const isUserAuthenticated = createSelector(
  (state: RootState) => state.auth.user,
  (user) => user,
);

// Thunk
export const login =
  (loginPayload: ILoginPayload): AppThunk =>
  async (dispatch) => {
    batch(() => {
      dispatch(loadingStarted());
      dispatch(clearErrors());
    });

    try {
      const loginResult: AxiosResponse<ILoginResult> = await api.post(AUTH_URLS.LOGIN, loginPayload);
      dispatch(loginSuccess(loginResult?.data?.user));
      localStorage.setItem('accessToken', loginResult?.data?.accessToken?.token);
      localStorage.setItem('refreshToken', loginResult?.data?.refreshToken?.token);
    } catch (err) {
      batch(() => {
        dispatch(setError((err as any)?.response?.data));
        dispatch(loginFailed());
      });
    } finally {
      dispatch(loadingFinished());
    }
  };

export const logout =
  (logoutPayload: ILogoutPayload): AppThunk =>
  async (dispatch) => {
    try {
      await api.post(AUTH_URLS.LOGOUT, logoutPayload);
    } catch (err) {
      console.error(err);
    }

    dispatch(clearState());
  };

export const authUser = (): AppThunk => async (dispatch) => {
  const token = localStorage.getItem('accessToken');
  if (!token) {
    dispatch(loginFailed());
    return;
  }

  try {
    dispatch(loadingStarted());
    const authResponse: AxiosResponse<IUser> = await api.get(AUTH_URLS.AUTH);
    dispatch(loginSuccess(authResponse?.data));
  } catch (err) {
    dispatch(loginFailed());
  } finally {
    dispatch(loadingFinished());
  }
};
