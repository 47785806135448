import { useMemo, useCallback } from 'react';
/** COMPONENTS */
import { GridContainer, GridItem, InfoBox } from 'components/shared';
import { Table } from 'components/shared/table/';
import TableToolBar from '../shared/table/table-toolbar/table-toolbar';
import { Http } from '@material-ui/icons';
import PageTitle from '../shared/page-title/page-title';
/** REDUX */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  getDomainList,
  clearDomainListSortOption,
  setDomainListSearchOption,
  setSortOption,
  clearDomainListOptions,
} from 'redux/features/domains/domainsSlice';
/** INTERFACES */
import { IDomain } from 'interfaces/domain';
import { ITableHeaderColumn, ITableHeaderDomainColumnIds } from 'interfaces/table/table-column';
import { ITableRow, ITableCell, ITableActionCell } from 'interfaces/table/table-row';
/** Utils */
import formatDate from 'utils/formatDate';
import useList from 'hooks/useList';

const columns: ITableHeaderColumn[] = [
  { id: ITableHeaderDomainColumnIds.domainName, label: 'Domain', minWidth: 180, sortOption: true, align: 'left' },
  { id: ITableHeaderDomainColumnIds.createdAt, label: 'Created', minWidth: 50, sortOption: true, align: 'left' },
];

const searchByOptions = [{ name: 'Domain name', value: 'domainName', icon: <Http /> }];

const DomainList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { domains, limit, totalCount, options } = useSelector((state: RootState) => state.domains.domainList);
  const isDomainListLoading = useSelector((state: RootState) => state.domains.isDomainListLoading);

  const getList = useCallback(
    (paramLimit: number, page: number) => {
      dispatch(getDomainList(paramLimit, page === 1 ? 0 : (page - 1) * paramLimit, options));
    },
    [dispatch, options],
  );

  const searchParamChangeCallback = useCallback(
    (searchParam: string, searchValue: string | number) => {
      dispatch(setDomainListSearchOption({ [searchParam]: searchValue }));
    },
    [dispatch],
  );

  const onUnmount = useCallback(() => {
    dispatch(clearDomainListOptions());
  }, [dispatch]);

  const { searchValue, searchParam, handleSearchParamChange, handleSearchValueChange } = useList({
    getList,
    limit,
    defaultSearchParam: searchByOptions[0].value,
    debounceSearchCallback: searchParamChangeCallback,
    searchParamChangeCallback,
    onUnmount,
  });

  const clearOptions = useCallback(() => {
    dispatch(clearDomainListSortOption());
  }, [dispatch]);

  const handleSortOptionClick = useCallback(
    (column: ITableHeaderColumn) => {
      dispatch(setSortOption(column));
    },
    [dispatch],
  );

  const tableRowsData: ITableRow[] = useMemo(() => {
    return domains.map((domain: IDomain) => {
      const cells: ITableCell[] = [
        { children: domain.domainName, align: 'left' },
        { children: formatDate(domain.createdAt), align: 'left' },
      ];
      const actionCellProps: ITableActionCell = {
        detailsPageLink: `/domains/${domain._id}`,
        align: 'center',
      };

      const row: ITableRow = {
        cells,
        actionCellProps,
      };

      return row;
    });
  }, [domains]);

  return (
    <GridContainer>
      <GridItem gridProps={{ xs: 12 }}>
        <InfoBox flexFlow={'column wrap'}>
          <PageTitle text={'Domains'} />
          <TableToolBar
            searchValue={searchValue}
            searchByValue={searchParam}
            searchByOptions={searchByOptions}
            sortOptions={options.sort}
            clearOptions={clearOptions}
            handleSearchValueChange={handleSearchValueChange}
            handleSearchParamChange={handleSearchParamChange}
          />
          <Table
            isLoading={isDomainListLoading}
            data={domains}
            columns={columns}
            withActions={true}
            rowsPerPage={limit}
            pagesCount={totalCount}
            sortOptions={options.sort}
            tableRowsData={tableRowsData}
            handleSortOptionClick={handleSortOptionClick}
          />
        </InfoBox>
      </GridItem>
    </GridContainer>
  );
};

export default DomainList;
