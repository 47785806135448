import React from 'react';
/** Router */
import { Link } from 'react-router-dom';
/** UI */
import TableLinkUI from './table-link-ui';

interface ITableLink {
  to: string;
  text: string;
}

const TableLink = ({ to, text }: ITableLink): JSX.Element => {
  const classes = TableLinkUI();

  return (
    <Link className={classes.link} to={to}>
      {text}
    </Link>
  );
};

export default TableLink;
