import React, { ReactNode } from 'react';
import classNames from 'classnames';
/** COMPONENTS */
import { Input, FormControl, InputLabel, InputProps, FormControlProps, InputLabelProps } from '@material-ui/core';
/** UI */
import InputUI from './input-ui';

type IProps = {
  labelText: ReactNode;
  labelProps?: InputLabelProps;
  id: string;
  inputProps: InputProps;
  formControlProps: FormControlProps;
  inputRootCustomClasses?: string;
  error?: boolean;
  errorText?: string;
  success?: boolean;
  white?: boolean;
};

const CustomInput = ({
  formControlProps,
  labelText,
  id,
  labelProps,
  inputProps,
  error,
  errorText,
  white,
  inputRootCustomClasses,
  success,
}: IProps): JSX.Element => {
  const classes = InputUI({ isReadOnly: inputProps?.readOnly });

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });

  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined && (
        <InputLabel className={classes.labelRoot + ' ' + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      )}
      <Input
        classes={{
          input: inputClasses,
          root: inputRootCustomClasses
            ? classNames({
                [inputRootCustomClasses]: !!inputRootCustomClasses,
              })
            : undefined,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        {...inputProps}
      />
      {error && (
        <div className={classes.inputErrorContainer}>
          <span className={classes.inputErrorSpan}>{errorText}</span>
        </div>
      )}
    </FormControl>
  );
};

export default CustomInput;
