import React from 'react';
/** Router */
import { Link } from 'react-router-dom';
/** UI */
import InfoBoxLinkUI from './info-box-link-ui';

interface IInfoBoxLink {
  to: string;
  text: string;
}

const InfoBoxLink = ({ to, text }: IInfoBoxLink): JSX.Element => {
  const classes = InfoBoxLinkUI();
  return (
    <Link className={classes.link} to={to}>
      {text}
    </Link>
  );
};

export default InfoBoxLink;
