/** COMPONENTS */
import SidebarListItem from './sidebar-list-item/sidebar-list-item';
import { List } from '@material-ui/core';
/** INTERFACES */
import { ISidebarListItem } from 'interfaces/sidebar-list-item';
/** UI */
import SidebarListUI from './sidebar-list-ui';

type IProps = {
  sidebarListItems: ISidebarListItem[];
};

const SidebarList = ({ sidebarListItems }: IProps): JSX.Element => {
  const classes = SidebarListUI();

  return (
    <List className={classes.list}>
      {sidebarListItems.map((listItem: ISidebarListItem) => (
        <SidebarListItem key={listItem.title} title={listItem.title} link={listItem.link} Icon={listItem.Icon} />
      ))}
    </List>
  );
};

export default SidebarList;
