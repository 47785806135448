import { makeStyles } from '@material-ui/core/styles';
import { dangerColor, successColor, defaultFont } from 'constants/material-ui';

export default makeStyles(() => ({
  textArea: {
    background: 'transparent',
    color: 'var(--white)',
    border: 'none',
    borderBottom: '1px solid var(--white)',
    width: '100%',
    transition: '0.12s ease',
    padding: '6px 0 7px',
    height: 'unset',
    '&,&::placeholder': {
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: '1',
    },
    '&::placeholder': {
      color: 'var(--white-85)',
    },
    '&:focus': {
      outline: 'none',
      borderBottom: '2px solid var(--primary)',
    },
    '&$readOnly': {
      color: 'var(--white-85)',
      borderBottom: '1px solid var(--white-85)',
    },
  },
  readOnly: {
    cursor: 'default',
  },
  disabled: {
    '&:before': {
      borderColor: 'transparent !important',
    },
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      borderColor: '#D2D2D2 !important',
      borderWidth: '1px !important',
    },
    '&:after': {
      borderColor: 'var(--primary)',
    },
  },
  underlineError: {
    '&:after': {
      borderColor: dangerColor,
    },
  },
  underlineSuccess: {
    '&:after': {
      borderColor: successColor,
    },
  },
  labelRoot: {
    ...defaultFont,
    color: 'var(--white) !important',
    fontFamily: 'Heebo',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '1.42857',
    top: '10px',
    letterSpacing: 'unset',
    '& + $underline': {
      marginTop: '0px',
    },
  },
  labelRootError: {
    color: 'var(--danger)' + ' !important',
  },
  inputErrorContainer: {
    display: 'flex',
    marginTop: '3.7px',
    alignItems: 'center',
  },
  inputErrorSpan: {
    fontFamily: 'Helvetica',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.01em;',
    textAlign: 'left',
    color: 'var(--danger)',
    paddingLeft: '3px',
  },
  labelRootSuccess: {
    color: successColor + ' !important',
  },
  formControl: {
    margin: '0 0 17px 0',
    paddingTop: '27px',
    position: 'relative',
    '& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
      color: 'var(--white)',
    },
  },
  input: {
    color: 'var(--white)',
    height: 'unset',
    '&,&::placeholder': {
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: '1',
    },
    '&::placeholder': {
      color: 'var(--white-85)',
    },
  },
  whiteInput: {
    '&,&::placeholder': {
      color: 'var(--white)',
      opacity: '1',
    },
  },
}));
