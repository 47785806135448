import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'components/layout/dashboard/dashboard-layout';
/** GUARDS */
import PrivateRoute from './guards/PrivateRoute';
import PublicRoute from './guards/PublicRoute';
/** PAGES */
import {
  LoginPage,
  NotFoundPage,
  UsersPage,
  StoriesPage,
  SnippetsPage,
  DomainsPage,
  UserDetailsPage,
  UserRegistrationPage,
  SnippetDetailsPage,
  StoryDetailsPage,
  DomainDetailsPage,
  WorkspacesPage,
  WorkspaceDetailsPage,
} from 'pages';

const Routes = (): JSX.Element => (
  <Switch>
    {/* PUBLIC ROUTES */}
    <PublicRoute exact path="/" render={() => <Redirect to={'/login'} />} />
    <PublicRoute path="/login" component={LoginPage} />
    {/* PRIVATE ROUTES */}
    <DashboardLayout>
      <Switch>
        <PrivateRoute exact path="/workspaces" component={WorkspacesPage} />
        <PrivateRoute exact path="/workspaces/:id" component={WorkspaceDetailsPage} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/users" component={UsersPage} />
        <PrivateRoute exact path="/users/create" component={UserRegistrationPage} />
        <PrivateRoute exact path="/users/:id" component={UserDetailsPage} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/snippets" component={SnippetsPage} />
        <PrivateRoute exact path="/snippets/:id" component={SnippetDetailsPage} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/stories" component={StoriesPage} />
        <PrivateRoute exact path="/stories/:id" component={StoryDetailsPage} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/domains" component={DomainsPage} />
        <PrivateRoute exact path="/domains/:id" component={DomainDetailsPage} />
      </Switch>
    </DashboardLayout>
    {/* NOT FOUND PAGE*/}
    <Route path="*" component={NotFoundPage} />
  </Switch>
);

export default Routes;
