import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  backdrop: {
    backgroundColor: 'var(--shade-500-85)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'var(--shade-900)',
    boxShadow: '24px 32px 72px var(--black-18)',
    padding: '32px 24px',
    width: '1024px',
    minHeight: '768px',
    color: 'var(--white)',
    border: 'none',
    outline: 'none',
    borderRadius: '8px',
  },
  messageContainer: {
    margin: '0 0 21px',
  },
  message: {
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'var(--white)',
    letterSpacing: '0.01em',
    margin: '0',
  },
  description: {
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    color: 'var(--shade-100)',
    margin: '8px 0 0',
  },
  button: {
    width: '100%',
    '&:not(:last-of-type)': {
      margin: '0 0 12px',
    },
  },
  spinner: {
    color: 'var(--primary)',
  },
});
