import { memo, useState, useRef, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ExpandMore } from '@material-ui/icons';
import TableSearchUI from './table-search-ui';
import clsx from 'clsx';
import { ITableSearchSelect, ITableSearchSelectOption } from 'interfaces/table-search';

const TableSearchSelect = ({
  dropdownPosition = 'left',
  dropdownWidth,
  dropdownTriggerStyles,
  isDisabled,
  selectOption,
  options,
  onSelect,
  children,
}: ITableSearchSelect): JSX.Element => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const classes = TableSearchUI({
    isDisabled,
    isLarge: Boolean(children),
    isFocused: isDropdownOpen,
    styles: dropdownTriggerStyles,
    dropdownWidth,
    dropdownPosition,
  });

  const selectedOption = useMemo(() => {
    const option = options.find((option) => option.value === selectOption) as ITableSearchSelectOption;
    return option?.icon ? option.icon : option.name;
  }, [options, selectOption]);

  const onOpenDropdown = () => {
    if (isDisabled) {
      return;
    }
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div
      className={clsx(classes.selectWrapper, {
        [classes.isMono]: options.length < 2,
      })}
    >
      <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(false)}>
        <div
          className={clsx(classes.selectDropdownTrigger, {
            [classes.selectDropdownTriggerActive]: isDropdownOpen,
          })}
          {...(options.length > 1 && { onClick: onOpenDropdown })}
        >
          <div className={classes.selectDropdownTriggerValue}>{selectedOption}</div>
          {options.length > 1 && <ExpandMore className={classes.selectDropdownIcon} />}
        </div>

        {isDropdownOpen && (
          <div className={classes.selectDropdownWrapper} ref={dropdownRef}>
            {children}
            <div className={classes.selectDropdownContentWrapper}>
              {options.map((option) => (
                <div
                  className={clsx(classes.selectOption, {
                    [classes.selectOptionSelected]: selectOption === option.value,
                  })}
                  key={option.name}
                  onClick={() => {
                    onSelect(option.value);
                    setIsDropdownOpen(false);
                  }}
                >
                  {option.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default memo(TableSearchSelect);
