import React, { useEffect, SyntheticEvent } from 'react';
/** REDUX */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { login, clearErrors } from 'redux/features/auth/authSlice';
/** HOOKS */
import { useForm } from 'react-hook-form';
/** COMPONENTS */
import LoginForm from './login-form/login-form';
import LoginIcon from './login-icon/login-icon';
import Alert from '@material-ui/lab/Alert';
import { GridContainer, GridItem } from 'components/shared';
/** UI */
import LoginFormUI from './login-ui';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { authErrors } = useSelector((state: RootState) => state.auth);
  const classes = LoginFormUI();

  const {
    register,
    formState: { isValid, errors },
    getValues,
  } = useForm({ mode: 'all' });

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isValid) {
      return;
    }
    const { email, password } = getValues();
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    /** Clear errors on unMount */
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer gridProps={{ justify: 'center' }}>
          <LoginIcon />
          <GridItem gridProps={{ xs: 10, sm: 8, md: 6 }}>
            {!!authErrors && (
              <Alert onClick={() => dispatch(clearErrors())} className={classes.errorBox} severity='error'>
                We couldnt find an account matching your credentials. Please try again.
              </Alert>
            )}
            <LoginForm register={register} isFormValid={isValid} onSubmit={onSubmit} errors={errors} />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default Login;
