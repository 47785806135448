import React, { useMemo } from 'react';
/** Components */
import { AutosizeTextarea, FormWrapper, GridContainer, GridItem, Input, Select } from '../../shared';
import { Box, InputAdornment } from '@material-ui/core';
import { TextFields, Today } from '@material-ui/icons';
/** Interfaces */
import { IOption } from 'interfaces/select';
/** Utils */
import formatDate from 'utils/formatDate';
import { PrimaryButton } from '../../buttons';

interface ISnippetDetailsForm {
  formInfo: any;
  statuses: string[];
  onStatusSelect: any;
  onInputChange: any;
  onSubmit: any;
}

const SnippetDetailsForm = ({
  formInfo,
  statuses,
  onStatusSelect,
  onInputChange,
  onSubmit,
}: ISnippetDetailsForm): JSX.Element => {
  const statusOptions: IOption[] = useMemo(() => {
    return statuses.map((status) => ({ label: status, value: status }));
  }, [statuses]);

  return (
    <FormWrapper onSubmit={onSubmit}>
      <GridContainer>
        <Box width="100%">
          <GridItem gridProps={{ md: 6 }}>
            <Input
              labelText="Name"
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                autoComplete: 'off',
                defaultValue: formInfo?.name || '',
                name: 'name',
                type: 'text',
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFields />
                  </InputAdornment>
                ),
              }}
            />

            <Input
              labelText="Created"
              id="created"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                autoComplete: 'off',
                defaultValue: formatDate(formInfo?.createdAt, true),
                name: 'createdAt',
                type: 'text',
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Today />
                  </InputAdornment>
                ),
              }}
            />

            <Select
              value={formInfo?.status || ''}
              label={'Status'}
              name={'status'}
              options={statusOptions}
              onChange={onStatusSelect}
            />

            <AutosizeTextarea
              id={'rejectMessage'}
              labelText={'Reject message'}
              formControlProps={{
                fullWidth: true,
              }}
              value={formInfo?.rejectMessage}
              name={'rejectMessage'}
              rowsMin={1}
              onChange={onInputChange}
            />
          </GridItem>

          <GridItem gridProps={{ md: 12 }}>
            <AutosizeTextarea
              id={'headCode'}
              formControlProps={{
                fullWidth: true,
              }}
              labelText={'Head code'}
              value={formInfo?.headCode}
              name={'headCode'}
              rowsMin={1}
              placeholder="Head code"
              readOnly={true}
            />
          </GridItem>

          <GridItem gridProps={{ md: 12 }}>
            <AutosizeTextarea
              id={'bodyCode'}
              formControlProps={{
                fullWidth: true,
              }}
              labelText={'Body code'}
              value={formInfo?.bodyCode}
              name={'bodyCode'}
              rowsMin={1}
              placeholder="Body code"
              readOnly={true}
            />
          </GridItem>

          <GridItem gridProps={{ md: 12 }}>
            <Box mt={4}>
              <PrimaryButton type={'submit'} text={'Save changes'} />
            </Box>
          </GridItem>
        </Box>
      </GridContainer>
    </FormWrapper>
  );
};

export default SnippetDetailsForm;
