export enum ITableHeaderUserColumnIds {
  _id = '_id',
  email = 'email',
  username = 'username',
  stories = 'stories',
  createdAt = 'createdAt',
}

export enum ITableHeaderSnippetColumnIds {
  _id = '_id',
  name = 'name',
  email = 'email',
  role = 'role',
  username = 'username',
  status = 'status',
  createdAt = 'createdAt',
}

export enum ITableHeaderStoryColumnIds {
  cover = 'cover',
  title = 'title',
  fileSize = 'fileSize',
  author = 'author',
  cuts = 'cuts',
  status = 'status',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum ITableHeaderDomainColumnIds {
  domainName = 'domainName',
  createdAt = 'createdAt',
}

export enum ITableHeaderWorkspaceColumnIds {
  id = 'ID',
  title = 'Title',
  owner = 'Owner',
  members = '#Members',
  stories = '#Stories',
  createdAt = 'createdAt',
}

export interface ITableHeaderColumn {
  id: string;
  label: string;
  minWidth?: number;
  sortOption?: boolean;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  format?: (value: number) => string;
}
