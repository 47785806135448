import React, { useCallback, useMemo } from 'react';
/** Redux */
import {
  getStoryList,
  setStoryListSearchOption,
  clearStoryListSortOption,
  deleteStory,
  setSortOption,
  clearStoryListOptions,
} from 'redux/features/stories/storiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
/** Hooks */
import useList from 'hooks/useList';
/** Components */
import { Title } from '@material-ui/icons';
import { GridContainer, GridItem, InfoBox } from '../shared';
import PageTitle from '../shared/page-title/page-title';
import TableToolBar from '../shared/table/table-toolbar/table-toolbar';
import { Table } from '../shared/table';
import defaultCover from 'assets/images/default-cover.svg';
import TableStoryCover from '../shared/table/table-body/table-story-cover/table-story-cover';
import TableLink from '../shared/table/table-body/table-link/table-link';
/** Utils */
import formatDate from 'utils/formatDate';
/** Interfaces */
import { IStory } from 'interfaces/stories';
import { ITableHeaderColumn, ITableHeaderStoryColumnIds } from '../../interfaces/table/table-column';
import { ITableActionCell, ITableCell, ITableRow } from '../../interfaces/table/table-row';
import { bytesToMB } from '../../utils/common';
import { IUser } from '../../interfaces/user';

const columns: ITableHeaderColumn[] = [
  { id: ITableHeaderStoryColumnIds.cover, label: 'Cover', minWidth: 50, align: 'center' },
  { id: ITableHeaderStoryColumnIds.title, label: 'Title', minWidth: 180, sortOption: true, align: 'left' },
  { id: ITableHeaderStoryColumnIds.cuts, label: 'Cuts', minWidth: 50, align: 'left' },
  { id: ITableHeaderStoryColumnIds.fileSize, label: 'Size', minWidth: 80, align: 'center' },
  { id: ITableHeaderStoryColumnIds.cuts, label: 'Status', minWidth: 50, sortOption: true, align: 'left' },
  { id: ITableHeaderStoryColumnIds.author, label: 'Author', minWidth: 50, align: 'left' },
  { id: ITableHeaderStoryColumnIds.createdAt, label: 'Created', minWidth: 50, sortOption: true, align: 'left' },
  { id: ITableHeaderStoryColumnIds.updatedAt, label: 'Updated', minWidth: 50, align: 'left' },
];

const searchByOptions = [{ name: 'Title', value: 'title', icon: <Title /> }];

const StoryList = ({ workspaceId }: { workspaceId?: string }): JSX.Element => {
  const dispatch = useDispatch();
  const { stories, limit, totalCount, options } = useSelector((state: RootState) => state.stories.storyList);
  const isStoryListLoading = useSelector((state: RootState) => state.stories.isStoryListLoading);

  const getList = useCallback(
    (paramLimit: number, page: number) => {
      dispatch(
        getStoryList({
          limit: paramLimit,
          offset: page === 1 ? 0 : (page - 1) * paramLimit,
          options,
          workspaceId,
        }),
      );
    },
    [dispatch, options, workspaceId],
  );

  const searchParamChangeCallback = useCallback(
    (searchParam: string, searchValue: string | number) => {
      dispatch(setStoryListSearchOption({ [searchParam]: searchValue }));
    },
    [dispatch],
  );

  const onUnmount = useCallback(() => {
    dispatch(clearStoryListOptions());
  }, [dispatch]);

  const { searchValue, searchParam, handleSearchParamChange, handleSearchValueChange } = useList({
    getList,
    limit,
    defaultSearchParam: searchByOptions[0].value,
    debounceSearchCallback: searchParamChangeCallback,
    searchParamChangeCallback,
    onUnmount: onUnmount,
  });

  const handleDeleteSnippet = useCallback(
    (id: string) => {
      dispatch(deleteStory(id));
    },
    [dispatch],
  );

  const tableRowsData: ITableRow[] = useMemo(() => {
    return stories.map((story: IStory) => {
      const cells: ITableCell[] = [
        {
          children: (
            <TableStoryCover src={story?.posterPortrait3x4Url || story?.cover?.url || defaultCover} alt={story.title} />
          ),
          align: 'center',
        },
        { children: story.title, align: 'left' },
        { children: story.cuts.length, align: 'left' },
        { children: bytesToMB(story?.fileSize ?? 0), align: 'center' },
        { children: story.status, align: 'left' },
        {
          children: (
            <TableLink to={`/users/${(story?.author as IUser)?._id}`} text={(story?.author as IUser)?.username || ''} />
          ),
          align: 'left',
        },
        { children: formatDate(story.createdAt), align: 'left' },
        { children: formatDate(story.updatedAt), align: 'left' },
      ];
      const actionCellProps: ITableActionCell = {
        detailsPageLink: `/stories/${story._id}`,
        deleteEntityId: story._id,
      };

      const row: ITableRow = {
        cells,
        actionCellProps,
      };

      return row;
    });
  }, [stories]);

  const clearOptions = useCallback(() => {
    dispatch(clearStoryListSortOption());
  }, [dispatch]);

  const handleSortOptionClick = useCallback(
    (column: ITableHeaderColumn) => {
      dispatch(setSortOption(column));
    },
    [dispatch],
  );

  return (
    <GridContainer>
      <GridItem gridProps={{ xs: 12 }}>
        <InfoBox flexFlow={'column wrap'}>
          <PageTitle text={'Stories'} />
          <TableToolBar
            searchValue={searchValue}
            searchByValue={searchParam}
            searchByOptions={searchByOptions}
            sortOptions={options.sort}
            clearOptions={clearOptions}
            handleSearchValueChange={handleSearchValueChange}
            handleSearchParamChange={handleSearchParamChange}
          />
          <Table
            isLoading={isStoryListLoading}
            data={stories}
            columns={columns}
            withActions={true}
            rowsPerPage={limit}
            pagesCount={totalCount}
            sortOptions={options.sort}
            tableRowsData={tableRowsData}
            deleteMessageModal={'Are you sure you want to delete the story?'}
            handleDelete={handleDeleteSnippet}
            handleSortOptionClick={handleSortOptionClick}
          />
        </InfoBox>
      </GridItem>
    </GridContainer>
  );
};

export default StoryList;
