export const AUTH_URLS = {
  LOGIN: '/admin/login',
  REFRESH: '/user/token/refresh',
  LOGOUT: '/user/logout',
  AUTH: `/admin/auth`,
};

export const USER_URLS = {
  GET_USER: (id: string): string => `/admin/user/${id}`,
  GET_USER_STORIES: (id: string): string => `/admin/user/${id}/stories`,
  GET_USER_WORKSPACES: (id: string): string => `/admin/user/${id}/workspaces`,
  GET_USER_META: (id: string): string => `/admin/user/${id}/meta`,
  USER_LIST: (offset: number, limit: number, sortOptions: string): string =>
    `/admin/users?offset=${offset}&limit=${limit}&${sortOptions}`,
  USER_LIST_META: '/admin/users/meta',
  UPDATE_USER: (id: string): string => `/admin/user/${id}/update`,
  GET_USER_ROLES: `/auth/user/role/list`,
  DELETE_USER: (id: string): string => `/admin/user/${id}/delete`,
  REGISTER_USER: `/user/register`,
};

export const STORY_URLS = {
  DELETE_STORY: (id: string): string => `/admin/story/${id}`,
  STORY_LIST: (offset: number, limit: number, sortOptions: string): string =>
    `/admin/stories?offset=${offset}&limit=${limit}&${sortOptions}`,
  GET_STORY: (id: string): string => `/admin/story/${id}`,
  GET_STORY_LIST_META: `/admin/stories/meta`,
  PUBLISH_STORY: '/admin/story/publish',
};

export const SNIPPET_URLS = {
  SNIPPET_LIST: (offset: number, limit: number, sortOptions: string): string =>
    `/admin/snippets?offset=${offset}&limit=${limit}&${sortOptions}`,
  DELETE_SNIPPET: (id: string): string => `/admin/snippet/${id}`,
  UPDATE_SNIPPET: (id: string): string => `/admin/snippet/${id}/update`,
  GET_SNIPPET: (id: string): string => `/admin/snippet/${id}`,
};

export const DOMAIN_URLS = {
  DOMAIN_LIST: (offset: number, limit: number, sortOptions: string): string =>
    `/admin/domain/list?offset=${offset}&limit=${limit}&${sortOptions}`,
  GET_DOMAIN: (id: string): string => `/admin/domain/${id}`,
};

export const WORKSPACE_URLS = {
  WORKSPACE_LIST: (offset: number, limit: number, sortOptions: string): string =>
    `/admin/workspace/list?offset=${offset}&limit=${limit}&${sortOptions}`,
  WORKSPACE_LIST_META: '/admin/workspace/list/meta',
  GET_WORKSPACE: (id: string): string => `/admin/workspace/${id}`,
  GET_WORKSPACE_META: (id: string): string => `/admin/workspace/${id}/meta`,
  UPDATE_WORKSPACE: (id: string): string => `/admin/workspace/${id}/update`,
  ACCEPT_INVITATION: '/admin/workspace/accept/user',
  REMOVE_MEMBER: (id: string): string => `/admin/workspace/member/${id}`,
  ADD_MEMBERS: '/admin/workspace/members/add',
  INIT_SITEMAP: '/admin/workspace/sitemap/init',
  UPDATE_SITEMAP: '/admin/workspace/sitemap/update',
};
