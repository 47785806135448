import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  tab: {
    fontFamily: 'Heebo',
    color: 'var(--white)',
    fontWeight: 700,
  },
  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tabBorder: {
    backgroundColor: 'var(--primary)',
  },
});
