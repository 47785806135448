import { useState, ReactNode } from 'react';
/** REDUX */
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/features/auth/authSlice';
/** COMPONENTS */
import DashboardLayoutHeader from './dashboard-header/dashboard-header';
import Sidebar from './sidebar/sidebar';
import { CssBaseline } from '@material-ui/core';
import { AmpStories, Code, Group, Dns, ListAlt } from '@material-ui/icons';

/** UI */
import DashboardUI from './dashboard-layout-ui';
import clsx from 'clsx';
/** INTERFACES */
import { ISidebarListItem } from 'interfaces/sidebar-list-item';
import { RootState } from 'redux/store';
import { IUser } from 'interfaces/user';

type IProps = {
  children: ReactNode;
};

const sidebarListItems: ISidebarListItem[] = [
  {
    title: 'Workspaces',
    link: '/workspaces',
    Icon: ListAlt,
  },
  {
    title: 'Stories',
    link: '/stories',
    Icon: AmpStories,
  },
  {
    title: 'Users',
    link: '/users',
    Icon: Group,
  },
  {
    title: 'Snippets',
    link: '/snippets',
    Icon: Code,
  },
  {
    title: 'Domains',
    link: '/domains',
    Icon: Dns,
  },
];

const DashboardLayout = ({ children }: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => (state.auth.user as IUser)?._id);
  const refreshToken = localStorage.getItem('refreshToken') ?? '';
  const classes = DashboardUI();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const ToggleSideBar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleProfileMenuOpen = (event: any) => {
    setProfileMenuAnchor(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchor(null);
  };

  const onLogout = () => {
    dispatch(
      logout({
        userId,
        token: refreshToken,
      }),
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <DashboardLayoutHeader
        ToggleSideBar={ToggleSideBar}
        handleProfileMenuOpen={handleProfileMenuOpen}
        handleProfileMenuClose={handleProfileMenuClose}
        isProfileMenuOpen={!!profileMenuAnchor}
        isSidebarOpen={isSidebarOpen}
        anchorEl={profileMenuAnchor}
        onLogout={onLogout}
      />
      <Sidebar
        handleSidebarClose={handleSidebarClose}
        isSidebarOpen={isSidebarOpen}
        sidebarListItems={sidebarListItems}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: isSidebarOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};

export default DashboardLayout;
