import { useCallback, useEffect, useMemo, useState } from 'react';
/** Router */
import { useHistory } from 'react-router-dom';
/** Utils */
import debounce from 'lodash/debounce';

interface IuseList {
  getList: (paramLimit: number, page: number) => void;
  limit: number;
  defaultSearchParam?: string;
  debounceSearchCallback?: (searchParam: string, searchValue: string | number) => void;
  searchParamChangeCallback?: (searchParam: string, searchValue: string | number) => void;
  onUnmount?: () => void;
}

interface IuseListReturn {
  searchParam: string;
  searchValue: string | number;
  handleSearchParamChange?: (param: string) => void;
  handleSearchValueChange?: (event: any) => void;
}

const useList = ({
  getList,
  limit,
  defaultSearchParam,
  debounceSearchCallback,
  searchParamChangeCallback,
  onUnmount,
}: IuseList): IuseListReturn => {
  const history = useHistory();
  const currentPage = useMemo(() => {
    const params = new URLSearchParams(history.location.search);
    const page = params.get('page');
    return page ? +page : 1;
  }, [history.location.search]);

  const onResetPage = useCallback(() => {
    if (currentPage !== 1) {
      history.push({
        pathname: `${history.location.pathname}`,
        search: '',
      });
    }
  }, [history, currentPage]);

  const [searchParam, setSearchParam] = useState<string>(defaultSearchParam ?? '');
  const [searchValue, setSearchValue] = useState<string>('');

  const onDebounceSearch = useCallback(
    debounce((searchParam: string, searchValue: string | number) => {
      debounceSearchCallback && debounceSearchCallback(searchParam, searchValue);
    }, 500),
    [],
  );

  const handleSearchParamChange = useCallback(
    (param: string) => {
      if (searchParamChangeCallback) {
        onResetPage();
        setSearchParam(param ? param : searchParam);
        searchParamChangeCallback(param ?? searchParam, searchValue);
      }
    },
    [searchParamChangeCallback, searchValue, searchParam, onResetPage],
  );

  const handleSearchValueChange = useCallback(
    (event: any) => {
      if (searchParamChangeCallback || debounceSearchCallback || defaultSearchParam) {
        onResetPage();
        setSearchValue(event.target.value);
        onDebounceSearch(searchParam, event.target.value);
      }
    },
    [searchParamChangeCallback, debounceSearchCallback, defaultSearchParam, searchParam, onDebounceSearch, onResetPage],
  );

  useEffect(() => {
    if (currentPage) {
      getList(limit, currentPage);
    }
  }, [getList, limit, currentPage]);

  useEffect(() => {
    return () => {
      onUnmount && onUnmount();
    };
  }, [onUnmount]);

  return {
    searchParam,
    searchValue,
    handleSearchParamChange,
    handleSearchValueChange,
  };
};

export default useList;
