import { useMemo, useCallback } from 'react';
/** COMPONENTS */
import { GridContainer, GridItem, InfoBox } from 'components/shared';
import { Table } from 'components/shared/table/';
import TableToolBar from '../shared/table/table-toolbar/table-toolbar';
import { Code } from '@material-ui/icons';
import PageTitle from '../shared/page-title/page-title';
/** REDUX */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  deleteSnippet,
  getSnippetList,
  clearSnippetListSortOption,
  setSnippetListSearchOption,
  setSortOption,
  clearSnippetListOptions,
} from 'redux/features/snippets/snippetsSlice';
/** INTERFACES */
import { ISnippet } from 'interfaces/snippet';
import { ITableHeaderColumn, ITableHeaderSnippetColumnIds } from 'interfaces/table/table-column';
import { ITableRow, ITableCell, ITableActionCell } from 'interfaces/table/table-row';
/** Utils */
import formatDate from '../../utils/formatDate';
import useList from '../../hooks/useList';
import TableLink from '../shared/table/table-body/table-link/table-link';

const columns: ITableHeaderColumn[] = [
  { id: ITableHeaderSnippetColumnIds.name, label: 'Tracking name', minWidth: 180, sortOption: true, align: 'left' },
  { id: ITableHeaderSnippetColumnIds.email, label: 'Workspace', minWidth: 50, align: 'left' },
  { id: ITableHeaderSnippetColumnIds.username, label: 'Author', minWidth: 50, align: 'left' },
  { id: ITableHeaderSnippetColumnIds.status, label: 'Status', minWidth: 50, sortOption: true, align: 'left' },
  { id: ITableHeaderSnippetColumnIds.createdAt, label: 'Created', minWidth: 70, sortOption: true, align: 'left' },
];

const searchByOptions = [{ name: 'Tracking name', value: 'name', icon: <Code /> }];

const SnippetList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { snippets, limit, totalCount, options } = useSelector((state: RootState) => state.snippets.snippetList);
  const isSnippetListLoading = useSelector((state: RootState) => state.snippets.isSnippetListLoading);

  const getList = useCallback(
    (paramLimit: number, page: number) => {
      dispatch(getSnippetList(paramLimit, page === 1 ? 0 : (page - 1) * paramLimit, options));
    },
    [dispatch, options],
  );

  const searchParamChangeCallback = useCallback(
    (searchParam: string, searchValue: string | number) => {
      dispatch(setSnippetListSearchOption({ [searchParam]: searchValue }));
    },
    [dispatch],
  );

  const onUnmount = useCallback(() => {
    dispatch(clearSnippetListOptions());
  }, [dispatch]);

  const { searchValue, searchParam, handleSearchParamChange, handleSearchValueChange } = useList({
    getList,
    limit,
    defaultSearchParam: searchByOptions[0].value,
    debounceSearchCallback: searchParamChangeCallback,
    searchParamChangeCallback,
    onUnmount,
  });

  const handleDeleteSnippet = useCallback(
    (id: string) => {
      dispatch(deleteSnippet(id));
    },
    [dispatch],
  );

  const clearOptions = useCallback(() => {
    dispatch(clearSnippetListSortOption());
  }, [dispatch]);

  const handleSortOptionClick = useCallback(
    (column: ITableHeaderColumn) => {
      dispatch(setSortOption(column));
    },
    [dispatch],
  );

  const tableRowsData: ITableRow[] = useMemo(() => {
    return snippets.map((snippet: ISnippet) => {
      const author = snippet.workspace?.users?.find((u) => u._id === snippet.author);
      const cells: ITableCell[] = [
        { children: snippet.name, align: 'left' },
        {
          children: <TableLink to={`/workspaces/${snippet?.workspace?._id}`} text={snippet?.workspace?.title || ''} />,
          align: 'left',
        },
        {
          children: <TableLink to={`/users/${author?._id}`} text={author?.email || ''} />,
          align: 'left',
        },
        { children: snippet.status, align: 'left' },
        { children: formatDate(snippet.createdAt), align: 'left' },
      ];
      const actionCellProps: ITableActionCell = {
        detailsPageLink: `/snippets/${snippet._id}`,
        deleteEntityId: snippet._id,
      };

      const row: ITableRow = {
        cells,
        actionCellProps,
      };

      return row;
    });
  }, [snippets]);

  return (
    <GridContainer>
      <GridItem gridProps={{ xs: 12 }}>
        <InfoBox flexFlow={'column wrap'}>
          <PageTitle text={'Snippets'} />
          <TableToolBar
            searchValue={searchValue}
            searchByValue={searchParam}
            searchByOptions={searchByOptions}
            sortOptions={options.sort}
            clearOptions={clearOptions}
            handleSearchValueChange={handleSearchValueChange}
            handleSearchParamChange={handleSearchParamChange}
          />
          <Table
            isLoading={isSnippetListLoading}
            data={snippets}
            columns={columns}
            withActions={true}
            rowsPerPage={limit}
            pagesCount={totalCount}
            sortOptions={options.sort}
            tableRowsData={tableRowsData}
            deleteMessageModal={'Are you sure you want to delete the snippet?'}
            handleDelete={handleDeleteSnippet}
            handleSortOptionClick={handleSortOptionClick}
          />
        </InfoBox>
      </GridItem>
    </GridContainer>
  );
};

export default SnippetList;
