import React, { ReactNode, PropsWithChildren } from 'react';
import classNames from 'classnames';
/** COMPONENTS */
import { Button } from '@material-ui/core';
/** UI */
import ButtonUI from './button-ui';

enum ButtonColors {
  primary = 'primary',
  info = 'info',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  rose = 'rose',
  white = 'white',
  transparent = 'transparent',
}

enum ButtonSizes {
  small = 'sm',
  large = 'lg',
}

type IProps = {
  children: ReactNode;
  className?: string;
  color: ButtonColors;
  plain?: boolean;
  size: ButtonSizes;
  simple?: boolean;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
};

const CustomButton = React.forwardRef<HTMLButtonElement, PropsWithChildren<IProps>>(
  (
    { color, round, children, fullWidth, disabled, simple, size, block, link, justIcon, className, ...rest }: IProps,
    ref,
  ) => {
    const classes = ButtonUI();

    const options = {
      [classes.button]: true,
      [classes[size]]: size,
      [classes[color]]: color,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
    };

    if (className) {
      options[className] = !!className;
    }

    const btnClasses = classNames(options);
    return (
      <Button type='submit' {...rest} ref={ref} className={btnClasses}>
        {children}
      </Button>
    );
  },
);

CustomButton.defaultProps = {
  color: ButtonColors.primary,
};

export { ButtonColors, ButtonSizes };
export default CustomButton;
