import { useMemo, useCallback } from 'react';
/** COMPONENTS */
import { GridContainer, GridItem, InfoBox } from 'components/shared';
import { Table } from 'components/shared/table/';
import TableToolBar from '../shared/table/table-toolbar/table-toolbar';
import { Title } from '@material-ui/icons';
import PageTitle from '../shared/page-title/page-title';
/** REDUX */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
/** INTERFACES */
import { ITableHeaderColumn, ITableHeaderWorkspaceColumnIds } from 'interfaces/table/table-column';
import { ITableRow, ITableCell, ITableActionCell } from 'interfaces/table/table-row';
/** Utils */
import formatDate from 'utils/formatDate';
import useList from 'hooks/useList';
import {
  clearWorkspaceListOptions,
  clearWorkspaceListSortOption,
  getWorkspaceList,
  setSortOption,
  setWorkspaceListSearchOption,
} from 'redux/features/workspaces/workspacesSlice';
import { IWorkspace } from 'interfaces/workspaces';

const columns: ITableHeaderColumn[] = [
  { id: ITableHeaderWorkspaceColumnIds.id, label: 'ID', minWidth: 180, sortOption: false, align: 'left' },
  { id: ITableHeaderWorkspaceColumnIds.title, label: 'Title', minWidth: 180, sortOption: true, align: 'left' },
  { id: ITableHeaderWorkspaceColumnIds.owner, label: 'Owner', minWidth: 50, sortOption: false, align: 'left' },
  { id: ITableHeaderWorkspaceColumnIds.members, label: '#Members', minWidth: 50, sortOption: false, align: 'center' },
  { id: ITableHeaderWorkspaceColumnIds.stories, label: '#Stories', minWidth: 50, sortOption: false, align: 'center' },
  { id: ITableHeaderWorkspaceColumnIds.createdAt, label: 'Created', minWidth: 50, sortOption: true, align: 'right' },
];

const searchByOptions = [{ name: 'Title', value: 'title', icon: <Title /> }];

const WorkspaceList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { workspaces, limit, totalCount, options } = useSelector((state: RootState) => state.workspaces.workspaceList);
  const isWorkspaceListLoading = useSelector((state: RootState) => state.workspaces.isWorkspaceListLoading);

  const getList = useCallback(
    (paramLimit: number, page: number) => {
      dispatch(getWorkspaceList(paramLimit, page === 1 ? 0 : (page - 1) * paramLimit, options));
    },
    [dispatch, options],
  );

  const searchParamChangeCallback = useCallback(
    (searchParam: string, searchValue: string | number) => {
      dispatch(setWorkspaceListSearchOption({ [searchParam]: searchValue }));
    },
    [dispatch],
  );

  const onUnmount = useCallback(() => {
    dispatch(clearWorkspaceListOptions());
  }, [dispatch]);

  const { searchValue, searchParam, handleSearchParamChange, handleSearchValueChange } = useList({
    getList,
    limit,
    defaultSearchParam: searchByOptions[0].value,
    debounceSearchCallback: searchParamChangeCallback,
    searchParamChangeCallback,
    onUnmount,
  });

  const clearOptions = useCallback(() => {
    dispatch(clearWorkspaceListSortOption());
  }, [dispatch]);

  const handleSortOptionClick = useCallback(
    (column: ITableHeaderColumn) => {
      dispatch(setSortOption(column));
    },
    [dispatch],
  );

  const tableRowsData: ITableRow[] = useMemo(() => {
    return workspaces.map((workspace: IWorkspace) => {
      const owner = workspace?.users?.find((u) => u?._id === workspace?.owner);
      const cells: ITableCell[] = [
        { children: workspace?._id, align: 'left' },
        { children: workspace?.title, align: 'left' },
        { children: owner?.email, align: 'left' },
        { children: workspace?.users?.length, align: 'center' },
        { children: workspace?.numberOfStories ?? 0, align: 'center' },
        { children: formatDate(workspace?.createdAt), align: 'right' },
      ];
      const actionCellProps: ITableActionCell = {
        detailsPageLink: `/workspaces/${workspace._id}`,
        align: 'center',
      };

      const row: ITableRow = {
        cells,
        actionCellProps,
      };

      return row;
    });
  }, [workspaces]);

  return (
    <GridContainer>
      <GridItem gridProps={{ xs: 12 }}>
        <InfoBox flexFlow={'column wrap'}>
          <PageTitle text={'Workspaces'} />
          <TableToolBar
            searchValue={searchValue}
            searchByValue={searchParam}
            searchByOptions={searchByOptions}
            sortOptions={options.sort}
            clearOptions={clearOptions}
            handleSearchValueChange={handleSearchValueChange}
            handleSearchParamChange={handleSearchParamChange}
          />
          <Table
            isLoading={isWorkspaceListLoading}
            data={workspaces}
            columns={columns}
            withActions={true}
            rowsPerPage={limit}
            pagesCount={totalCount}
            sortOptions={options.sort}
            tableRowsData={tableRowsData}
            handleSortOptionClick={handleSortOptionClick}
          />
        </InfoBox>
      </GridItem>
    </GridContainer>
  );
};

export default WorkspaceList;
