import { environment } from 'config/environment';

export const bytesToMB = (sizeInBytes: number): string => `${Number((sizeInBytes / (1024 * 1024)).toFixed(2))} MB`;

export const getAverageCuts = (cutsCount: number | undefined, storiesCount: number | undefined): number => {
  let result = 0;
  if (typeof cutsCount === 'number' && typeof storiesCount === 'number') {
    result = Number((cutsCount / storiesCount)?.toFixed(2));
  }

  if (isNaN(result)) {
    return 0;
  }

  return result;
};

export function buildPreviewLink(storyId: string): string {
  const hostString = environment?.host;

  if (hostString && storyId) {
    const uri = new URL(hostString);

    let { host } = uri;
    const { protocol } = uri;

    // TODO fix 01.03.2022
    // Temporary workarround

    if (host === 'beta.zazuapp.co') {
      host = 'zazuapp.co';
    }

    return `${protocol}//preview.${host}/${storyId}`;
  }

  return '';
}

export function parseUsageType(type: string): string {
  switch (type) {
    case 'WEB_STORIES':
      return 'Web Stories';

    case 'AUTOMATION':
      return 'Automation';

    case 'OTHER':
      return 'Other';

    case 'SOCIAL_MEDIA':
      return 'Social Media';

    case 'CURIOSITY':
      return 'Curiosity';

    default:
      return '';
  }
}

export function parseCompanyType(type: string): string {
  switch (type) {
    case 'SMALL':
      return '0-10';

    case 'MEDIUM':
      return '11-50';

    case 'LARGE':
      return '51-100';

    case 'EXTRA_LARGE':
      return '+100';

    default:
      return '';
  }
}
