import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  fieldPadding?: string;
}

export default makeStyles<Theme, StyleProps>(() =>
  createStyles({
    formControl: {
      width: '100%',
      padding: ({ fieldPadding }) => fieldPadding ?? '27px 0 0',
      margin: '0 0 17px',
      '& .MuiInput-underline:before': {
        borderColor: 'var(--white)',
      },
      '& .MuiInput-underline:after': {
        borderColor: 'var(--primary)',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderWidth: '1px',
        borderColor: 'var(--white)',
      },
    },
    menu: {
      background: 'var(--shade-500)',
      color: 'var(--white)',
    },
    label: {
      color: 'var(--white)',
      fontFamily: 'Heebo',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '1.42857',
      top: '10px',
      letterSpacing: 'unset',
      '&.Mui-focused': {
        color: 'var(--white)',
      },
      '& + .MuiInput-formControl': {
        marginTop: '0px',
      },
    },
    root: {
      fontSize: '14px',
      color: 'var(--white)',
    },
    icon: {
      color: 'var(--white)',
    },
    select: {
      color: 'var(--white)',
    },
    selectErrorContainer: {
      display: 'flex',
      marginTop: '3.7px',
      alignItems: 'center',
    },
    selectErrorSpan: {
      fontFamily: 'Helvetica',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.01em;',
      textAlign: 'left',
      color: 'var(--danger)',
      paddingLeft: '3px',
    },
    error: {
      '& .MuiInput-underline:before': {
        borderColor: 'var(--danger)',
      },
      '& .MuiInput-underline:after': {
        borderColor: 'var(--danger)',
      },
      '& $label': {
        color: 'var(--danger)',
      },
    },
  }),
);
