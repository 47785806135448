import React, { useEffect } from 'react';
/** Router */
import { useHistory } from 'react-router-dom';
/** Redux */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getDomain, setDomain } from 'redux/features/domains/domainsSlice';
/** Components */
import { DetailsNavWrapper, GridContainer, GridItem } from '../shared';
import { Box } from '@material-ui/core';
import { QuaternaryLinkButton } from '../buttons';
import DomainInfo from './domain-info/domain-info';

const DomainDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const domain = useSelector((state: RootState) => state.domains.domain);

  useEffect(() => {
    const startPointOfDomainId = history.location.pathname.lastIndexOf('/') + 1;
    const storyId = history.location.pathname.substr(startPointOfDomainId);
    dispatch(getDomain(storyId));

    return () => {
      dispatch(setDomain(null));
    };
  }, [dispatch, history.location.pathname]);

  return (
    <GridContainer>
      <GridItem gridProps={{ xs: 12 }}>
        <DetailsNavWrapper>
          <QuaternaryLinkButton to={'/domains'} text={'See all'} />
        </DetailsNavWrapper>
      </GridItem>

      <GridItem gridProps={{ xs: 12 }}>
        <Box mb={4}>
          <DomainInfo domain={domain} />
        </Box>
      </GridItem>
    </GridContainer>
  );
};

export default DomainDetails;
