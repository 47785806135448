import React from 'react';
/** Interfaces */
import { IUser } from 'interfaces/user';
import { IStory } from 'interfaces/stories';
/** Components */
import defaultAvatar from 'assets/images/default-avatar.svg';
import { Avatar, Box } from '@material-ui/core';
import { InfoBoxLabel, InfoBox, InfoBoxValue } from 'components/shared';
/** Utils */
import formatDate from 'utils/formatDate';
/** UI */
import UserInfoUI from './user-info-ui';
import { parseCompanyType, parseUsageType } from 'utils/common';

interface IUserInfo {
  user: IUser | null;
}

const UserInfo = ({ user }: IUserInfo): JSX.Element => {
  const classes = UserInfoUI();

  return (
    <InfoBox className={classes.infoBox}>
      <Box display={'flex'} flexWrap={'wrap'} mr={12}>
        <Box pr={3} mb={2}>
          <Avatar className={classes.avatar} alt="Avatar" src={defaultAvatar} />
        </Box>

        <Box pr={3} mb={2}>
          <Box mb={2}>
            <InfoBoxLabel text="User" />

            <InfoBoxValue component={'h1'} text={`@${user?.username}`} />

            <InfoBoxValue component={'h2'} text={user?.email} />
          </Box>
        </Box>
      </Box>

      <Box display={'flex'} flexWrap={'wrap'}>
        <Box pr={3} mb={2} mr={3}>
          <InfoBoxLabel text="ID" />

          <InfoBoxValue text={user?._id} />
        </Box>

        <Box pr={3} mb={2}>
          <InfoBoxLabel text="Registered" />

          <InfoBoxValue text={formatDate(user?.createdAt || '')} />
        </Box>

        <Box pr={3} mb={2}>
          <InfoBoxLabel text="Usage type" />

          <InfoBoxValue text={user?.usageType?.map((t) => parseUsageType(t ?? ''))?.join(', ')} />
        </Box>

        <Box pr={3} mb={2}>
          <InfoBoxLabel text="Company size" />

          <InfoBoxValue text={parseCompanyType(user?.companySize ?? '')} />
        </Box>
      </Box>
    </InfoBox>
  );
};

export default UserInfo;
