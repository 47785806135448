import React from 'react';
/** Interfaces */
import { IStory } from 'interfaces/stories';
/** Components */
import defaultCover from 'assets/images/default-cover.svg';
import { Box } from '@material-ui/core';
import { InfoBoxLabel, InfoBox, InfoBoxLink, InfoBoxValue } from 'components/shared';
/** Utils */
import formatDate from 'utils/formatDate';
/** UI */
import StoryInfoUI from './story-info-ui';
import { IUser } from '../../../interfaces/user';

interface IStoryInfo {
  story: IStory | null;
}

const StoryInfo = ({ story }: IStoryInfo): JSX.Element => {
  const classes = StoryInfoUI();

  return (
    <InfoBox className={classes.infoBox}>
      <Box display={'flex'} flexWrap={'wrap'}>
        <Box pr={3} mb={2} mr={3}>
          <img
            className={classes.cover}
            alt="Cover"
            src={story?.posterPortrait3x4Url || story?.cover?.url || defaultCover}
          />
        </Box>

        <Box pr={3} mb={2} mr={3}>
          <Box mb={2}>
            <InfoBoxLabel text="Title" />

            <InfoBoxValue component={'h1'} text={story?.title} />
          </Box>

          <Box>
            <InfoBoxLabel text="Author" />

            <InfoBoxLink
              to={`/users/${(story?.author as IUser)?._id}`}
              text={`@${(story?.author as IUser)?.username}`}
            />
          </Box>
        </Box>
      </Box>

      <Box display={'flex'} flexWrap={'wrap'}>
        <Box pr={3} mb={2} mr={3}>
          <InfoBoxLabel text="Status" />

          <InfoBoxValue text={story?.status} />
        </Box>

        <Box pr={3} mb={2} mr={3}>
          <InfoBoxLabel text="Created" />

          <InfoBoxValue text={formatDate(story?.createdAt || '')} />
        </Box>

        <Box pr={3} mb={2}>
          <InfoBoxLabel text="Updated" />

          <InfoBoxValue text={formatDate(story?.storyUpdatedAt || '')} />
        </Box>
      </Box>
    </InfoBox>
  );
};

export default StoryInfo;
