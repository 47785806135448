import { useState, useEffect, useCallback } from 'react';
/** Router */
import { useHistory } from 'react-router-dom';
/** Redux */
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
/** Components */
import WorkspaceInfo from './workspace-info/workspace-info';
import WorkspaceUsers from './workspace-users/workspace-users';
import { DetailsNavWrapper, GridContainer, GridItem } from 'components/shared';
import { Box, Tab } from '@material-ui/core';
import { QuaternaryLinkButton } from '../buttons';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
/** Constants */
import { WORKSPACE_DETAILS_TABS, WORKSPACE_SETTINGS, WORKSPACE_STORIES, WORKSPACE_USERS } from './constants/tabs';
/** UI */
import UserDetailsUI from './workspace-details-ui';
import {
  getWorkspace,
  getWorkspaceMeta,
  setWorkspace,
  setWorkspaceMeta,
} from 'redux/features/workspaces/workspacesSlice';
import WorkspaceSettings from './workspace-settings/workspace-settings';
import StoryList from '../story-list/story-list';
import AnalyticsCard from '../shared/analytics-card/analytics-card';
import { getAverageCuts } from '../../utils/common';

const WorkspaceDetails = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const workspace = useSelector((state: RootState) => state.workspaces.workspace);
  const { numberOfStories, numberOfPublishedStories, cutsCount } = useSelector(
    (state: RootState) => state.workspaces.workspaceMeta,
  );

  const [tabValue, setTabValue] = useState(WORKSPACE_USERS);
  const classes = UserDetailsUI();

  const handleTabChange = useCallback((event: any, newValue: string) => {
    setTabValue(newValue);
  }, []);

  useEffect(() => {
    const startPointOfWorkspaceId = history.location.pathname.lastIndexOf('/') + 1;
    const workspaceId = history.location.pathname.substr(startPointOfWorkspaceId);
    batch(() => {
      dispatch(getWorkspace(workspaceId));
      dispatch(getWorkspaceMeta(workspaceId));
    });

    return () => {
      dispatch(setWorkspace(null));
      dispatch(setWorkspaceMeta(null));
    };
  }, [dispatch, history]);

  return (
    <GridContainer>
      <GridItem gridProps={{ xs: 12 }}>
        <DetailsNavWrapper>
          <QuaternaryLinkButton to={'/workspaces'} text={'See all'} />
        </DetailsNavWrapper>
      </GridItem>

      <GridItem gridProps={{ xs: 12 }}>
        <Box mb={4}>
          <WorkspaceInfo workspace={workspace} />
        </Box>
      </GridItem>

      <GridItem
        gridProps={{ xs: 12, style: { display: 'flex', columnGap: '12px', flexWrap: 'wrap', margin: '0 0 16px' } }}
      >
        <Box marginBottom={'16px'}>
          <AnalyticsCard label="Total Stories" value={numberOfStories ?? 0} />
        </Box>

        <Box marginBottom={'16px'}>
          <AnalyticsCard label="Total published Stories" value={numberOfPublishedStories ?? 0} />
        </Box>

        <Box marginBottom={'16px'}>
          <AnalyticsCard label="Artboards per Story" value={getAverageCuts(cutsCount, numberOfStories)} />
        </Box>
      </GridItem>

      <GridItem gridProps={{ xs: 12 }}>
        <TabContext value={tabValue}>
          <TabList TabIndicatorProps={{ className: classes.tabBorder }} onChange={handleTabChange}>
            {WORKSPACE_DETAILS_TABS.map((tab: string) => (
              <Tab key={tab} className={classes.tab} label={tab} value={tab} />
            ))}
          </TabList>
          <TabPanel className={classes.tabPanel} value={WORKSPACE_USERS}>
            <WorkspaceUsers workspaceUsers={workspace?.users ?? []} />
          </TabPanel>

          <TabPanel className={classes.tabPanel} value={WORKSPACE_STORIES}>
            <StoryList workspaceId={workspace?._id} />
          </TabPanel>

          <TabPanel className={classes.tabPanel} value={WORKSPACE_SETTINGS}>
            <WorkspaceSettings />
          </TabPanel>
        </TabContext>
      </GridItem>
    </GridContainer>
  );
};

export default WorkspaceDetails;
