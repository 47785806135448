import React from 'react';
/** Components */
import { Delete } from '@material-ui/icons';
/** UI */
import DeleteButtonUI from './delete-button-ui';
import clsx from 'clsx';

interface IDeleteButton {
  className?: string;
  type?: 'submit' | 'button';
  onClick: (params?: any) => void;
}

const DeleteButton = ({ className, type = 'button', onClick }: IDeleteButton): JSX.Element => {
  const classes = DeleteButtonUI();
  return (
    <button
      className={clsx(classes.button, {
        [className as string]: className,
      })}
      type={type}
      onClick={onClick}
    >
      <Delete className={classes.icon} />
    </button>
  );
};

export default DeleteButton;
