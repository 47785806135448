import React, { ChangeEvent, FormEvent, useMemo } from 'react';
/** Components */
import { CheckBox, Input, FormControlLabel, Select, GridItem, GridContainer, FormWrapper } from 'components/shared';
import { IRole, IUser } from 'interfaces/user';
import { Box, InputAdornment } from '@material-ui/core';
import { Email, AccountCircle, TextFields } from '@material-ui/icons';
import { PrimaryButton } from '../../buttons';
/** Interfaces */
import { IUserFormErrors } from 'interfaces/userForm';

interface IUserDetailsForm {
  formInfo: IUser | null;
  errors: IUserFormErrors;
  userRoles: IRole[] | [];
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCheckBoxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onRoleSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent) => void;
}

const UserDetailsForm = ({
  formInfo,
  errors,
  userRoles,
  onInputChange,
  onCheckBoxChange,
  onRoleSelect,
  onSubmit,
}: IUserDetailsForm): JSX.Element => {
  const roleSelectOptions = useMemo(() => {
    return userRoles.map((role) => ({
      value: role.name,
      label: role.name,
    }));
  }, [userRoles]);

  const isSubmitDisabled = useMemo(() => {
    return errors.email.length > 0 || errors.username.length > 0;
  }, [errors]);

  return (
    <FormWrapper onSubmit={onSubmit}>
      <GridContainer>
        <GridItem gridProps={{ xs: 12, md: 6 }}>
          <Input
            labelText="Username"
            id="username"
            formControlProps={{
              fullWidth: true,
            }}
            error={errors.username.length > 0}
            errorText={errors.username}
            inputProps={{
              autoComplete: 'off',
              value: formInfo?.username || '',
              name: 'username',
              type: 'text',
              onChange: onInputChange,
              endAdornment: (
                <InputAdornment position="end">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>

        <GridItem gridProps={{ xs: 12, md: 6 }}>
          <Input
            labelText="Email"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            error={errors.email.length > 0}
            errorText={errors.email}
            inputProps={{
              autoComplete: 'off',
              value: formInfo?.email || '',
              name: 'email',
              type: 'email',
              onChange: onInputChange,
              endAdornment: (
                <InputAdornment position="end">
                  <Email />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>

        {/* <GridItem gridProps={{ xs: 12, md: 6 }}>
          <Input
            labelText="Name"
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            error={errors.firstName.length > 0}
            errorText={errors.firstName}
            inputProps={{
              autoComplete: 'off',
              value: formInfo?.firstName || '',
              name: 'firstName',
              type: 'text',
              onChange: onInputChange,
              endAdornment: (
                <InputAdornment position="end">
                  <TextFields />
                </InputAdornment>
              ),
            }}
          />
        </GridItem> */}

        <GridItem gridProps={{ xs: 12, md: 6 }}>
          <Select
            value={formInfo?.role.name || ''}
            label={'Role'}
            name={'role'}
            options={roleSelectOptions}
            onChange={onRoleSelect}
          />
        </GridItem>
      </GridContainer>

      <Box display={'flex'} justifyContent={'flex-start'} flexWrap={'wrap'} mb={2}>
        <Box mr={3}>
          <FormControlLabel
            control={<CheckBox isChecked={formInfo?.paid || false} onChange={onCheckBoxChange} name="paid" />}
            label="Paid"
          />
        </Box>

        <Box mr={3}>
          <FormControlLabel
            control={
              <CheckBox isChecked={formInfo?.privacyPolicy || false} onChange={onCheckBoxChange} name="privacyPolicy" />
            }
            label="Privacy Policy"
          />
        </Box>

        <Box mr={3}>
          <FormControlLabel
            control={
              <CheckBox
                isChecked={formInfo?.instagramExportAllowed || false}
                onChange={onCheckBoxChange}
                name="instagramExportAllowed"
              />
            }
            label="Instagram Export"
          />
        </Box>
      </Box>

      <Box mt={4}>
        <PrimaryButton isDisabled={isSubmitDisabled} type={'submit'} text={'Save changes'} />
      </Box>
    </FormWrapper>
  );
};

export default UserDetailsForm;
