import WorkspaceList from '../components/workspace-list/workspace-list';
import WorkspaceListMeta from '../components/workspace-list/workspace-list-meta';

const WorkspacesPage = (): JSX.Element => {
  return (
    <>
      <WorkspaceListMeta />
      <WorkspaceList />
    </>
  );
};
export default WorkspacesPage;
