import { ReactNode } from 'react';
import classNames from 'classnames';
/** UI */
import CardHeaderUI from './card-header-ui';

enum HeaderColors {
  warning = 'warningCardHeader',
  success = 'successCardHeader',
  danger = 'dangerCardHeader',
  info = 'infoCardHeader',
  primary = 'primaryCardHeader',
}

type IProps = {
  children: ReactNode;
  className: string;
  color: HeaderColors;
  plain?: boolean;
};

const CardHeader = ({ className, children, color, plain, ...rest }: IProps): JSX.Element => {
  const classes = CardHeaderUI();

  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color]]: color,
    [classes.cardHeaderPlain]: plain,
    [className]: className !== undefined,
  });
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
};

CardHeader.defaultProps = {
  color: HeaderColors.primary,
};

export { HeaderColors };
export default CardHeader;
